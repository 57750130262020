import { sortBy } from 'lodash';
import {
  FormsNotificationsStatuses,
  FORMS_COMPLETE_NOTIFICATION_TEXT,
  FORMS_PENDING_NOTIFICATION_TEXT,
  Notification,
} from 'src/store/notifications/types';
import { UnreadNotificationStatusPrefix } from 'src/constants';
import { InboxNotificationStatus } from 'src/components/Inbox';
import { InboxNotificationDetailsResponse } from 'src/entities/Notifications';

/**
 * This method will check if the notification status is unread
 * @param notification
 */
export const isUnreadNotification = (notification: Notification) =>
  notification.ownerId.split('/')[1].includes(UnreadNotificationStatusPrefix);

export const sortNotificationsByDate = (notifications: Notification[]) =>
  sortBy(notifications, 'createdDate').reverse();

/**
 * The notification entity keeps the entity corresponding to notification as the last part of
 * data property. Eg.
 * 656581fc-ac1f-4e66-a834-f91a43d06124/1_PENDING/b28bffe5-2534-477a-bcf1-598572da75f3
 * breaks down as
 * 656581fc-ac1f-4e66-a834-f91a43d06124 -> user for notification
 * 1_PENDING -> notification status
 * b28bffe5-2534-477a-bcf1-598572da75f3 -> related entityId for notification
 * @param notificationDataProperty ownerId/data property of notification entity
 * @returns related entityId for notification
 */
export const getEntityIDFromNotificationDataProperty = (
  notificationDataProperty: string,
) => {
  const dataPropertyParts = notificationDataProperty.split('/');
  return dataPropertyParts.length > 2 ? dataPropertyParts.at(2) : '';
};

/**
 * This method is used to convert forms notifications status
 * to action summary text.
 * @param status: form notification status
 */
export const getFormNotificationSummaryTitleByStatus = (
  status: FormsNotificationsStatuses,
) => {
  switch (status) {
    case FormsNotificationsStatuses.COMPLETED:
      return FORMS_COMPLETE_NOTIFICATION_TEXT;
    case FormsNotificationsStatuses.PENDING:
      return FORMS_PENDING_NOTIFICATION_TEXT;

    default:
      return '';
  }
};

/**
 * This method is used to get the notification read status
 * @param notification
 * @returns InboxNotificationStatus
 */

export const getNotificationReadStatus = (
  notification: InboxNotificationDetailsResponse,
): InboxNotificationStatus => {
  // notification.data is a string that looks like this `${Some_id}/${Notification_status}/${fileId}`
  // we can use this string to get notification status info
  // by spliting the string using "/"
  // and then access the "1" index of the array
  const notificationStatusData = notification.data.split('/');

  let notificationStatus = InboxNotificationStatus.Read;
  if (notificationStatusData.length) {
    // checking for unread notifications
    if (
      notificationStatusData.at(1)?.startsWith(UnreadNotificationStatusPrefix)
    )
      notificationStatus = InboxNotificationStatus.Sent;
  }

  return notificationStatus;
};

/**
 * This method return the count of unread inbox notifications
 * @param inboxNotifications array of all inbox notifications
 * @returns unread notification count
 */
export const getUnreadInboxNotificationCount = (
  inboxNotifications: InboxNotificationDetailsResponse[] | undefined,
) => {
  if (!inboxNotifications || inboxNotifications.length === 0) return 0;

  return inboxNotifications.reduce((count, notification) => {
    const notificationStatus = getNotificationReadStatus(notification);
    if (notificationStatus === InboxNotificationStatus.Sent) {
      return count + 1;
    }
    return count;
  }, 0);
};

/**
 * this method will format the notification count
 * @param count: notification count
 * @param maxDisplayCount: max count to display
 * @returns formatted notification count
 */
export const formatNotificationCount = (
  count: number,
  maxDisplayCount?: number,
) => {
  const maxDisplayCountValue = maxDisplayCount || 99;
  if (count > maxDisplayCountValue) {
    return `${maxDisplayCountValue}+`;
  }
  return count;
};
