import { Events } from 'src/constants/webSocketConsts/events';
import {
  handleInsertedCompanies,
  handleModifiedCompanies,
  handleDeletedCompanies,
} from 'src/store/clients/actions';
import { ensureUnreachable } from 'src/utils/CommonUtils';

const CompanyHandler = (event: Events, items: Array<any>) => {
  switch (event) {
    case Events.Insert:
      return handleInsertedCompanies(items);
    case Events.Modify:
      return handleModifiedCompanies(items);
    case Events.Remove:
      return handleDeletedCompanies(items);
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default CompanyHandler;
