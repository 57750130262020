import React from 'react';
import { FeaturesConfigType } from 'src/utils/PortalUtils/portalConfigUtils';
import { AppViewMode } from 'src/constants/uiConsts';

interface PortalAssets {
  logo: {
    logoImageUrl: string;
    iconImageUrl: string;
    faviconUrl: string;
  };
  authImage: {
    signInUrl: string;
  };
  socialShareImage: {
    imageUrl: string;
  };
}

export interface AmplifyApiSettings {
  name: string;
  endpoint: string;
  region: string;
  wssEndpoint?: string;
}

export interface PortalConfigMetadata {
  title: string;
  description: string;
  linkedInUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  gaMeasurementId?: string;
  titleOverride?: string;
  faviconOverride?: string;
}

export interface OAuthConfig {
  domain: string;
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string;
}

export interface PortalConfig {
  id?: string;
  AWS: {
    Auth: {
      userPoolId: string;
      identityPoolId: string;
      region: string;
      userPoolWebClientId: string;
      oauth?: OAuthConfig;
    };
    API: {
      endpoints: AmplifyApiSettings[];
    };
    Cloudformation: {
      subdomain: string;
    };
  };
  features: FeaturesConfigType;
  MarketingSite: {
    Enabled: boolean;
    disableBadge: boolean;
    allowCallScheduling: boolean;
    callSchedulingLink: string;
  };
  name: string;
  portalHeader: string;
  subdomain: string;
  viewMode: AppViewMode;
  metadata: PortalConfigMetadata;
  SignoutRedirectURL: string;
  assets: PortalAssets;
  theme: DefaultTheme;
  brand: {
    allowClientBranding: boolean;
    clientAccentColor: string;
    clientSidebarBackgroundColor: string;
    clientSidebarTextColor: string;
    clientFontFamily: string;
  };
  clientExperienceEnabled: boolean;
  sendEmailsToUninvitedClients: boolean;
  updatePortalConfig?: (config: {
    id: string;
    sendEmailsToUninvitedClients: boolean;
    clientExperienceEnabled: boolean;
    clientInvitesDisabled: boolean;
    disableCompanies: boolean;
    disableGoogleSignIn: boolean;
    gaMeasurementId: string;
    signupDisabled: boolean;
  }) => void; // optional because it only gets set in App.tsx
}

export interface DefaultTheme {
  palette: {
    primary: {
      main: string;
      contrastText: string;
    };
    secondary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
    error: {
      contrastText: string;
      dark: string;
      main: string;
      light: string;
    };
    text: {
      primary: string;
      secondary: string;
      link: string;
    };
    link: string;
    icon: string;
    background: {
      default: string;
      paper: string;
    };
    divider: string;
  };
}

export const defaultTheme: DefaultTheme = {
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#ccc',
      contrastText: '#000',
    },
    error: {
      contrastText: '#fff',
      dark: '#b71c1c',
      main: '#e53935',
      light: '#ef5350',
    },
    text: {
      primary: '#263238',
      secondary: '#546e7a',
      link: '#1e88e5',
    },
    link: '#1565c0',
    icon: '#546e7a',
    background: {
      default: '#F4F6F8',
      paper: '#fff',
    },
    divider: '#eee',
  },
};

export const faviconUrl = '/images/branding/favicon.ico';

export const initialConfig: PortalConfig = {
  AWS: {
    Auth: {
      identityPoolId: 'us-east-1:57697fef-cff2-4891-b23b-f5b5d123063f',
      region: 'us-east-1',
      userPoolId: 'us-east-1_68WePm9cv',
      userPoolWebClientId: '75hbpr88qif85pqqdqmrkl185n',
    },
    API: {
      endpoints: [],
    },
    Cloudformation: {
      subdomain: '',
    },
  },
  features: {},
  name: '',
  portalHeader: '',
  subdomain: '',
  viewMode: AppViewMode.INTERNAL,
  metadata: {
    title: '',
    description: '',
    gaMeasurementId: '',
    facebookUrl: '',
    instagramUrl: '',
    linkedInUrl: '',
    twitterUrl: '',
  },
  MarketingSite: {
    Enabled: false,
    disableBadge: false,
    allowCallScheduling: false,
    callSchedulingLink: '',
  },
  SignoutRedirectURL: '',
  assets: {
    logo: {
      logoImageUrl: '',
      iconImageUrl: '',
      faviconUrl,
    },
    authImage: {
      signInUrl: '',
    },
    socialShareImage: {
      imageUrl: '',
    },
  },
  theme: defaultTheme,
  brand: {
    allowClientBranding: false,
    clientAccentColor: '',
    clientSidebarTextColor: '',
    clientSidebarBackgroundColor: '',
    clientFontFamily: '',
  },
  clientExperienceEnabled: false,
  sendEmailsToUninvitedClients: false,
};

export const PortalConfigContext = React.createContext(initialConfig);
