import * as React from 'react';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import {
  Box,
  Button,
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popper,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormBodyAPI } from 'src/constants/fieldsTypes';
import BaseTypography from 'src/components/Text/BaseTypography';
import { white } from 'src/theme/colors';
import { RegularDialogShadow } from 'src/theme/shadows';

interface PopoverFormProps {
  title: string;
  actionLabel: string;
  onClose: () => void;
  initialFormValue: any;
  handleSave: (values: any) => void;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
}

export interface PopoverFromActionProps {
  useFormHook: () => {
    validationScheme: Yup.ObjectSchema;
    getSubmitValues: (values?: any) => Promise<any>; // this method can be specified in the form to get any extra values that might be needed during submit api call
    FormRenderer: React.FC<FormBodyAPI & FormikHelpers<any>>;
  };
}

interface TitleProps {
  id: string;
  children: React.ReactNode;
  onCloseDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px 0`,
      zIndex: theme.zIndex.drawer + 3, // Higher than the form page header
    },
    root: {
      backgroundColor: white,
      borderRadius: 10,
      width: 473,
      boxShadow: RegularDialogShadow,
      marginTop: -theme.spacing(2),
    },
    confirmButton: {
      marginLeft: theme.spacing(2),
    },
    dialogTitle: {
      margin: 0,
      position: 'relative',
    },
    dialogActions: {
      padding: theme.spacing(3),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: theme.palette.grey[500],
    },
  }),
);

const Title = (props: TitleProps) => {
  const { children, onCloseDialog, ...rest } = props;
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={classes.dialogTitle} {...rest}>
      <BaseTypography fontType="15Medium">{children}</BaseTypography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onCloseDialog}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

export const SignaturePopoverForm: React.FC<
  Partial<PopoverFormProps> & PopoverFromActionProps
> = React.memo(
  ({
    title,
    actionLabel,
    initialFormValue,
    handleSave,
    useFormHook,
    onClose,
    open,
    anchorEl,
  }) => {
    const classes = useStyles();

    const { validationScheme, getSubmitValues, FormRenderer } = useFormHook();
    const [popoverState, setPopoverStateState] = React.useState({
      formValue: initialFormValue,
      open: false,
    });

    const resetModal = () => {
      if (onClose) {
        onClose();
      }
      setPopoverStateState({
        formValue: initialFormValue,
        open: false,
      });
    };

    const closeModal = () => {
      resetModal();
    };

    /**
     * Send all submit values to the saving method passes in props. This can either be an
     * redux action which will be dispatched or a callback function
     * that is not dispatched. This method also looks for extra
     *  submit values that might not be in the form and needed to be formatted differently.
     * @param values the input values of the form that will be submitted
     * @returns void
     */
    const onFormSubmit = async (values: any, { setSubmitting }) => {
      const extraValues = await getSubmitValues(values);
      const { isValid = true } = extraValues;

      if (isValid) {
        delete extraValues.isValid;
        const params = {
          ...values,
          fields: { ...values.fields, ...extraValues },
        };
        if (handleSave) {
          handleSave(params);
        }
      } else {
        setSubmitting(false);
      }
    };

    React.useMemo(() => {
      setPopoverStateState({
        ...popoverState,
        formValue: initialFormValue,
      });
    }, [initialFormValue]);

    return (
      <Popper
        open={!!(popoverState.open || open)}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
      >
        {() => (
          <Box className={classes.root}>
            <Title id="dialog-title" onCloseDialog={closeModal}>
              {title}
            </Title>
            <Formik
              enableReinitialize
              initialValues={{ ...popoverState.formValue }}
              validationSchema={validationScheme}
              onSubmit={onFormSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
                initialValues,
              }) => (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    {FormRenderer({
                      touched,
                      errors,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      values,
                      initialValues,
                    })}
                  </DialogContent>
                  <DialogActions className={classes.dialogActions}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                      color="primary"
                      id="company-action"
                      className={classes.confirmButton}
                    >
                      {actionLabel}
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </Box>
        )}
      </Popper>
    );
  },
);
