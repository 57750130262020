/* eslint-disable no-param-reassign */
import * as _ from 'lodash';
import { ChangeModel } from 'src/store/ui/types';
import { landingPageNameListModel } from 'src/utils/PortalUtils/portalModels';
import { getConfigKey } from 'src/utils/PortalUtils/portalConfigUtils';
import { LandingPagesModel } from 'src/store/landing/types';
import { LANDING_PAGE_STATUS } from 'src/constants';

export const MapLandingProperty = (changes: ChangeModel[], data: any) => {
  const landingData = _.cloneDeep(data);
  if (changes && changes.length) {
    changes.forEach((change) => {
      const configKey = getConfigKey(change.name, landingPageNameListModel);
      if (configKey) {
        _.set(
          landingData,
          configKey.path,
          configKey.invertBooleanValue ? !change.value : change.value,
        );
      }
    });
  }
  return landingData;
};

export const pageUnloadHandler = (
  e: BeforeUnloadEvent,
  pageChanges: ChangeModel[],
) => {
  if (pageChanges && pageChanges.length > 0) {
    e.preventDefault();
    e.returnValue =
      'You have unsaved changes. Are you sure you want leave this page?';
  }
};

export const IsPageActive = (
  pageEntityType: string,
  marketingPages: LandingPagesModel,
) => {
  const currentPage = Object.values(marketingPages).find(
    (landingPageData) =>
      landingPageData.fields.pageEntityType === pageEntityType,
  );
  if (currentPage && currentPage.entityStatus === LANDING_PAGE_STATUS.ACTIVE) {
    return true;
  }
  return false;
};
