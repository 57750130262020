import React, { ReactNode } from 'react';
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import BaseTypography from 'src/components/Text/BaseTypography';
import { CreateButton } from 'src/components/UI';
import { Searchbox } from 'src/components/SearchInput/Searchbox';
import { NonHoverBorder, white } from 'src/theme/colors';
import MenuLogo, { MENU_LOGO_WIDTH } from '../Navbar/MenuLogo';
import { CreateButtonProps } from '../UI/Buttons/CreateButton';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '60px',
      padding: theme.spacing(0, 2.5),
      boxSizing: 'border-box',
      borderBottom: `1px solid ${NonHoverBorder}`,
      width: '-webkit-fill-available',
    },
    titleSlot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: theme.spacing(1.5),
    },
    titleWrapper: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: white,
    },
    actionsWrapper: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      // to make the actions container div
      // do not take more space than needed
      // we need to set its position to absolute
      // and set a fixed right space.
      position: 'absolute',
      right: 20,
    },
    actions: {
      marginLeft: theme.spacing(1.5),
    },
  }),
);

const LEFT_PADDING = 35;

type ChannelsListToolbarProps = {
  pageTitle: ReactNode;
  createButtonProps?: CreateButtonProps | undefined;
  actionsSlot?: ReactNode; // this prop is used to render custom additional actions e.g. in inbox sidebar it renders the view menu button
  allowKeyboardShortcuts: boolean;
};
export const ChannelsListToolbar: React.FC<ChannelsListToolbarProps> = ({
  pageTitle,
  createButtonProps,
  actionsSlot,
  allowKeyboardShortcuts,
}) => {
  const classes = useStyles();
  const actionWrapperRef = React.useRef<HTMLDivElement>(null);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  // width of search input when expanded
  const [fullSearchWidth, setFullSearchWidth] = React.useState(0);

  const getSearchExpandWidth = () => {
    const rootWidth = rootRef.current?.clientWidth || 0;
    const actionWrapperWidth = actionWrapperRef.current?.clientWidth || 0;

    // space between search icon and left toolbar start
    const widthValue =
      rootWidth -
      actionWrapperWidth -
      (LEFT_PADDING + (isSmDown ? MENU_LOGO_WIDTH : 0));
    setFullSearchWidth(widthValue);
  };

  React.useLayoutEffect(() => {
    getSearchExpandWidth();
    window.addEventListener('resize', getSearchExpandWidth);
    return () => window.removeEventListener('resize', getSearchExpandWidth);
  }, [rootRef.current, actionWrapperRef.current, isSmDown]);

  return (
    <div className={classes.root} ref={rootRef}>
      <div className={classes.titleSlot}>
        <MenuLogo />
        <BaseTypography className={classes.titleWrapper} fontType="13Medium">
          {pageTitle}
        </BaseTypography>
      </div>

      <div className={classes.actionsWrapper} ref={actionWrapperRef}>
        <Searchbox
          fullWidthOnFocus
          showIconOnly
          // expand width is the available space minus the left padding
          // this is needed because the search is wrapper inside an absolute positioned div.
          expandWidth={fullSearchWidth}
          allowKeyboardShortcuts={allowKeyboardShortcuts}
        />
        <div
          className={clsx({
            [classes.actions]: Boolean(createButtonProps || actionsSlot),
          })}
        >
          {createButtonProps && (
            <CreateButton
              iconOnly
              // the create button is wrapped by a button group. We need to pass the correct color and variant also to
              // the button group to keep the button group style consistent with the create button.
              buttonGroupProps={{
                color: 'secondary',
                variant: 'contained',
              }}
              color="secondary"
              variant="contained"
              {...createButtonProps}
            />
          )}
          {actionsSlot && actionsSlot}
        </div>
      </div>
    </div>
  );
};
