import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ChevronLeft } from '@material-ui/icons';
import { togglePrimarySidebarMobile } from 'src/store/ui/actions';
import MenuLogo from 'src/components/Navbar/MenuLogo';
import { RouteContext } from 'src/context';
import {
  ADD_APP_PAGE,
  CLIENT_DETAILS_PAGE,
  CONTRACTS_PAGE,
  CONTRACT_DETAILS_PAGE,
  FORMS_DETAIL_PAGE_V2,
  FORMS_PAGE,
} from 'src/constants';
import { HamburgerIcon } from 'src/components/Icons';
import history from 'src/history';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    menuIconWrapper: {
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1.5),
      },
    },
  }),
);

export const TableToolbar: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname } = React.useContext(RouteContext);
  const classes = useStyles();

  const pathsToShowBackButton = [
    FORMS_DETAIL_PAGE_V2.path,
    `${FORMS_PAGE.path}/response`,
    `${FORMS_PAGE.path}/submit`,
    CONTRACT_DETAILS_PAGE.path,
    CLIENT_DETAILS_PAGE.path,
    ADD_APP_PAGE.path,
    `${CONTRACTS_PAGE.path}/submission`,
  ];

  const showBackNavigationButton = pathsToShowBackButton.includes(pathname);

  const handleMenuButtonClick = React.useCallback(() => {
    // navigate back to last page
    // when back button is visible
    if (showBackNavigationButton) {
      history.goBack();
      return;
    }

    // by default on menu click open the primary sidebar
    dispatch(togglePrimarySidebarMobile({ isOpen: true }));
  }, [showBackNavigationButton]);

  return (
    <div className={classes.root}>
      <div className={classes.menuIconWrapper}>
        <MenuLogo
          menuClickCallback={handleMenuButtonClick}
          Icon={showBackNavigationButton ? ChevronLeft : HamburgerIcon}
        />
      </div>
      {children}
    </div>
  );
};
