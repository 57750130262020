import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { BlackHeadings, GraySmall } from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';

interface HomePageSectionProps {
  title: string;
  subTitle: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionHeaderWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1.5, 0),
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.5),
    },
  }),
);

export const HomePageSection: React.FC<HomePageSectionProps> = ({
  children,
  title,
  subTitle,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.sectionHeaderWrapper}>
        <BaseTypography style={{ color: BlackHeadings }} fontType="18Medium">
          {title}
        </BaseTypography>
        <BaseTypography
          data-testid="section-subtitle"
          style={{ color: GraySmall }}
          fontType="13Regular"
        >
          {subTitle}
        </BaseTypography>
      </div>

      <div className={classes.sectionContent}>{children}</div>
    </div>
  );
};
