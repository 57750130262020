import { BaseEntity } from 'src/constants';
import { CheckboxCheckedIcon } from 'src/components/Icons';
import {
  LongAnswerIcon,
  ShortAnswerIcon,
  RadioIcon,
  UploadIcon,
  TitleIcon,
} from 'src/components/Icons/QuestionTypes';

export enum FormVisibility {
  AllClients = 'allClients',
  RequestedClients = 'requestedClients',
}

export enum FormResponseStatus {
  Pending = 'pending',
  Open = 'open',
  Completed = 'completed',
}

export interface QuestionAnswer extends QuestionType {
  responseValue: string | string[] | number;
  questionId: string;
  isQuestionEdited?: boolean;
  answer: string | string[];
}

export type QuestionAnswerWithIdentityId = QuestionAnswer & {
  identityId: string;
};

export interface FormResponse extends BaseEntity {
  id: string;
  object: string;
  ref?: string;
  data: string;
  creatorId?: string;
  createdAt: string;
  updatedAt?: string;
  formId: string;
  clientId: string;
  fields: FormResponseFields;
  filteredListIndexPkey?: string;
  identityId: string;
  submissionDate?: string;
}

export interface FormResponseFields {
  formName: string;
  formDescription: string;
  formFieldIds: string[];
  formFields: { [key: string]: FormResponseQuestion };
  status: string;
  allowMultipleSubmissions: boolean;
  visibility: FormVisibility;
  submissionDate?: string;
}

export interface FormResponseQuestion {
  title: string;
  description?: string;
  type: InputsTypes;
  multipleChoiceOptions?: string[];
  isRequired: boolean;
  answer?: string | string[];
}

export interface FormFields {
  formFieldIds: string[];
  name: string;
  description: string;
  allowMultipleSubmissions: boolean;
  visibility: string; // requestedClients | allClients
  formFields: QuestionType[];
}

export interface FormTemplate extends BaseEntity {
  id: string;
  object: string;
  ref?: string;
  data: string;
  creatorId?: string;
  createdAt: Date;
  updatedAt?: Date;
  additionalFields: {
    formResponseRequests: number;
    formResponseSubmissions: number;
    latestSubmissionDate?: string;
  };
  fields: FormFields;
  identityId: string;
}

export enum InputsTypes {
  ShortAnswer = 'shortAnswer',
  LongAnswer = 'longAnswer',
  MultiSelect = 'multiSelect',
  FileUpload = 'fileUpload',
  SingleSelect = 'singleSelect',
  Title = 'title', // this is not an question input type, it's a card type used to display a questions section title
}

export enum FormBuilderFormikErrors {
  title = 'Form title is required',
  description = 'Form description is required',
  formVisibility = 'Form visibility is required',
  questions = 'At least add one question',
  questionType = 'Question type is required',
  questionTitle = 'Question title is required',
  titleCard = 'Title is required',
  questionChoice = 'Choice field is required',
  addNonTitleQuestions = 'Add one or more questions to your form before trying to save',
}

export type QuestionType = {
  formFieldId?: string;
  type: InputsTypes;
  title: string;
  description: string;
  multipleChoiceOptions?: string[];
  isRequired: boolean;
};

export type FormConfig = {
  title: string;
  description: string;
  allowMultipleSubmissions: boolean;
  visibility: FormVisibility;
  questions: QuestionType[];
  id?: string;
  name: string;
};

export const QuestionsOptions = [
  {
    title: 'Short answer',
    type: InputsTypes.ShortAnswer,
    Icon: ShortAnswerIcon,
  },
  {
    title: 'Long answer',
    type: InputsTypes.LongAnswer,
    Icon: LongAnswerIcon,
  },
  {
    title: 'Multi select',
    type: InputsTypes.MultiSelect,
    Icon: CheckboxCheckedIcon,
  },
  {
    title: 'Single select',
    type: InputsTypes.SingleSelect,
    Icon: RadioIcon,
  },
  {
    title: 'File upload',
    type: InputsTypes.FileUpload,
    Icon: UploadIcon,
  },
  {
    title: 'Title card',
    type: InputsTypes.Title,
    Icon: TitleIcon,
  },
] as const;

export type FormResponseValues = { questionAnswers: QuestionAnswer[] };

export interface FormNotificationResponse {
  succeededIds: Array<string> | null;
  failedIds: Array<string> | null;
  unauthorizedIds: Array<string> | null;
}
