import React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import * as Colors from 'src/theme/colors';
import * as Constants from 'src/constants';
import BaseTypography from 'src/components/Text/BaseTypography';

const useStyles = makeStyles(() =>
  createStyles({
    tooltipRoot: {
      width: 400,
      padding: 8,
      '& > *': {
        color: Colors.GraySmall,
      },
    },
    nonHoverColor: {
      color: Colors.NonHoverBorder,
    },
  }),
);

export const TooltipContentForStatus: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.tooltipRoot}>
      <BaseTypography
        fontType="12Medium"
        className={classes.nonHoverColor}
        gutterBottom
      >
        User Statuses
      </BaseTypography>
      <BaseTypography
        fontType="12Medium"
        className={classes.nonHoverColor}
        variant="body2"
      >
        Not Invited
      </BaseTypography>
      <BaseTypography fontType="12Medium" gutterBottom>
        The user has not received an email invite yet.
      </BaseTypography>
      <BaseTypography
        fontType="12Medium"
        className={classes.nonHoverColor}
        variant="body2"
      >
        Invited
      </BaseTypography>
      <BaseTypography fontType="12Medium" gutterBottom>
        The user has received an email invite but has not signed in and changed
        their password.
      </BaseTypography>
      <BaseTypography
        fontType="12Medium"
        className={classes.nonHoverColor}
        variant="body2"
      >
        Pending Verification
      </BaseTypography>
      <BaseTypography fontType="12Medium" gutterBottom>
        The user has been created but has not yet passed email verification.
      </BaseTypography>
      <BaseTypography
        fontType="12Medium"
        className={classes.nonHoverColor}
        variant="body2"
      >
        Active
      </BaseTypography>
      <BaseTypography fontType="12Medium">
        The user has fully set up their account.
      </BaseTypography>
    </Box>
  );
};

export const TooltipContentForAccessLevel: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.tooltipRoot}>
      <BaseTypography
        fontType="12Medium"
        className={classes.nonHoverColor}
        variant="body2"
      >
        Admin
      </BaseTypography>
      <BaseTypography fontType="12Medium" gutterBottom>
        Admin users can access all settings, invite other team members, and can
        update the lead and assignees of clients. Admins can always see all
        clients because they are always set as assignees for all clients.
      </BaseTypography>
      <BaseTypography
        fontType="12Medium"
        className={classes.nonHoverColor}
        variant="body2"
      >
        Staff
      </BaseTypography>
      <BaseTypography fontType="12Medium" gutterBottom>
        Staff users cannot access most settings and cannot invite other team
        members. Staff users by default cannot see any clients unless an admin
        explicitly sets them as a lead or assignee.
      </BaseTypography>
    </Box>
  );
};

export const TooltipContentForLead: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.tooltipRoot}>
      <BaseTypography
        fontType="12Medium"
        className={classes.nonHoverColor}
        gutterBottom
      >
        {Constants.LEAD_TOOLTIP_DESCRIPTION}
      </BaseTypography>
    </Box>
  );
};

export const TooltipContentForVisibility: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.tooltipRoot}>
      <BaseTypography
        fontType="12Medium"
        className={classes.nonHoverColor}
        gutterBottom
      >
        {Constants.VISIBILITY_TOOLTIP_DESCRIPTION}
      </BaseTypography>
    </Box>
  );
};
