import { initialState as defaultUserState } from 'src/store/user/reducers';
import { initialState as defaultClientsState } from 'src/store/clients/reducers';

const APP_STATE_KEY = 'h38v29jajj9dje2bx';

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }

  return null;
};

export const setCookie = (
  name: string,
  value: string,
  days?: number,
  options?: string[],
) => {
  if (typeof document === 'undefined') {
    return;
  }
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `;expires=${date.toUTCString()}`;
  }
  let cookieOptions = '';
  if (options) {
    cookieOptions = `;${options.join(';')}`;
  }
  document.cookie = `${name}=${value || ''}${expires}${cookieOptions};path=/`;
};

export const loadState = (storage: { [k: string]: string }) => {
  try {
    const serializedState = storage
      ? storage[APP_STATE_KEY]
      : getCookie(APP_STATE_KEY);

    if (!serializedState) {
      return undefined;
    }

    const prevState = JSON.parse(serializedState);

    if (typeof window !== 'undefined') {
      const localState = window.localStorage.getItem(APP_STATE_KEY);
      if (localState) {
        const serializedLocalState = JSON.parse(localState);
        Object.assign(prevState, {
          clients: { ...defaultClientsState, ...serializedLocalState.clients },
        });
      }
    }

    const prevUser = prevState.user;
    if (prevUser) {
      prevUser.loaded = false;
      prevUser.loading = false;
      Object.keys(defaultUserState).forEach((userPropKey) => {
        if (!(userPropKey in prevUser)) {
          prevUser[userPropKey] =
            defaultUserState[userPropKey as keyof typeof defaultUserState];
        }
      });
    }

    return prevState;
  } catch (err) {
    return undefined;
  }
};

const skipUserStateKeys = ['instance', 'loadingError'];

const getSavableState = (state: { [k: string]: any }) => {
  const savableState: { [k: string]: any } = {};
  Object.keys(state).forEach((stateKey) => {
    // assign each property in state to savable state dict
    let stateVal = state[stateKey];
    if (stateKey === 'user') {
      // for user prop, create new user obj to add to state
      // where certain keys are modified
      const user: { [k: string]: any } = {};
      const stateKeys = Object.keys(stateVal);
      stateKeys
        .filter((k) => !skipUserStateKeys.includes(k))
        .forEach((userKey) => {
          user[userKey] = stateVal[userKey];
        });
      stateVal = user;
    } else if (stateKey === 'clients') {
      const { crmTableSettings } = stateVal;
      stateVal = { crmTableSettings };
    }

    savableState[stateKey] = stateVal;
  });
  return savableState;
};

export const saveState = (state: { [k: string]: string }) => {
  try {
    const serializedState = JSON.stringify(getSavableState(state));
    setCookie(APP_STATE_KEY, serializedState);
  } catch (err) {
    // ignore error in saving state
    console.error('State not saved', err);
  }
};

export const saveLocalState = (localState: WindowLocalStorage) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    const serializedState = JSON.stringify(getSavableState(localState));
    window.localStorage.setItem(APP_STATE_KEY, serializedState);
  } catch (err) {
    // ignore error in saving state
    console.error('Local state not saved', err);
  }
};

/**
 * Set an object in session storage
 * @param sessionState a property of the local session object
 */
export const saveSessionState = (
  key: string,
  sessionState: Record<string, string>,
) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    const serializedState = JSON.stringify(getSavableState(sessionState));
    window.sessionStorage.setItem(key, serializedState);
  } catch (err) {
    // ignore error in saving state
    console.error('Session state not saved', err);
  }
};
