import { Events } from 'src/constants/webSocketConsts/events';
import {
  UpdateExtensionItemsAction,
  DeleteExtensionItemsAction,
} from 'src/store/dashboard/actions';
import { ensureUnreachable } from 'src/utils/CommonUtils';

const ExtensionItemHandler = (event: Events, items: Array<any>) => {
  switch (event) {
    case Events.Insert:
    case Events.Modify:
      return UpdateExtensionItemsAction(items);
    case Events.Remove: {
      const deletedItemIds = items.map((chan) => chan.id);
      return DeleteExtensionItemsAction(deletedItemIds, false);
    }
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default ExtensionItemHandler;
