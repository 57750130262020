import {
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
} from '@ag-grid-community/core';
import {
  FormResponse,
  FormResponseStatus,
  InputsTypes,
} from 'src/components/FormsV2/formsTypes';
import CSVUtils from 'src/utils/CSVUtils';
import { Client } from 'src/store/clients/types';
import {
  LocaleDateFormatter,
  LocaleTimeFormatter,
} from 'src/components/Formatters';
import { getFileNameFromFileKey } from 'src/components/Files/helpers';

export const SUBMISSION_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss a';

/**
 * This function returns the file names of the files uploaded for a file upload question answer.
 * @param filesKeys: the files keys to get the file names from
 * @returns file names separated by comma
 */
export const getFileUploadQuestionAnswerFileNames = (
  filesKeys: string | string[],
) => {
  let files = filesKeys;
  if (typeof files == 'string') {
    files = [files];
  }

  return files
    .map((key: string) =>
      getFileNameFromFileKey(key, {
        withExtension: true,
      }),
    )
    .join(', ');
};
/**
 * This function handles exporting the forms response to csv.
 * @param formsResponses the forms responses to export
 * @param fileNameParts the file name parts to be used in the exported file name
 * @param clients client redux state used to lookup the client who submitted the response
 */
export const exportFormResponses = (
  formsResponses: FormResponse[] | undefined,
  fileNameParts: string,
  clients: Client[],
) => {
  // the responses headers stores the questions titles
  // initially add the responder name header which represents the form response owner
  const csvHeaders: string[] = ['Responder', 'Email', 'Submitted on'];
  // the csvRows stores the questions responses
  const csvRows: string[][] = [];

  (formsResponses || []).forEach((response) => {
    const row: string[] = [];

    // the responder is the client who submitted the response
    // its id is stored in the response object as id field.
    const responder = clients.find((client) => client.id === response.clientId);
    if (!responder) return;
    const { givenName, familyName, email } = responder.fields;
    const responderFullName = `${givenName || ''} ${familyName || ''}`;

    // push the responder name and email to the row
    row.push(responderFullName);
    row.push(email);
    const responseSubmittedTime = response.fields.submissionDate
      ? `${LocaleDateFormatter({
          value: response.fields.submissionDate,
        })} at ${LocaleTimeFormatter({
          value: response.fields.submissionDate,
        })}`
      : '';

    const isFormResponseCompleted =
      response.fields.status === FormResponseStatus.Completed;
    // add the response submitted time to the row only if the response is completed
    row.push(isFormResponseCompleted ? responseSubmittedTime : '');
    // loop through the questions and add the response value to the row
    response.fields.formFieldIds.forEach((qid) => {
      const questionAnswerData = response.fields.formFields[qid];
      const questionTitle = questionAnswerData.title;

      if (questionAnswerData.type === InputsTypes.Title) {
        return;
      }

      // add the question title to the headers if it doesn't exist
      if (csvHeaders.indexOf(questionTitle) === -1) {
        csvHeaders.push(questionTitle);
      }

      // when the input type is multi choice, the response value is an array of selected options
      if (
        questionAnswerData.type === InputsTypes.MultiSelect ||
        questionAnswerData.type === InputsTypes.SingleSelect
      ) {
        // when select question response is undefined, push empty string to the row
        if (!questionAnswerData.answer) {
          row.push('');
          return;
        }
        const selectedOptions = questionAnswerData.answer as Array<string>;

        row.push(selectedOptions.join(', '));
        return;
      }
      // if question type is file, lookup the file name
      if (questionAnswerData.type === InputsTypes.FileUpload) {
        const fileNames = getFileUploadQuestionAnswerFileNames(
          questionAnswerData?.answer || [],
        );
        row.push(fileNames);
        return;
      }

      // other questions types have a single response value
      row.push(questionAnswerData.answer as string);
    });

    // push the row to the csvRows
    csvRows.push(row);
  });

  const currentDate: string = new Date(Date.now()).toLocaleDateString();

  const csvFileName = `form_${fileNameParts}_${currentDate}.csv`;
  CSVUtils.exportDataToCsv(csvFileName, [csvHeaders].concat(csvRows));
};

export const processFormTemplatesTableHeader = (
  params: ProcessHeaderForExportParams,
) => {
  let headerVal: string;
  switch (params.column.getColId()) {
    case 'notificationCount':
      headerVal = '';
      break;
    case 'creator':
      // for client column we want to create three using hardcoded " and , to display full info
      headerVal = 'Creator first name","Creator last name","Creator email';
      break;
    case 'responseCount':
      headerVal = 'Responses';
      break;
    default:
      headerVal = params.column.getColDef().headerName || '';
  }
  return `"${headerVal}"`;
};

/**
 * Format the row data for CSV export - some row data may be formatted in a specific way
 * @param params object containing column ID, and node data containing other information for the row
 * */
export const processFormTemplateCell = (params: ProcessCellForExportParams) => {
  let cellVal = '';
  const formRowData = params.node?.data;
  switch (params.column.getColId()) {
    case 'notificationCount':
      cellVal = '';
      break;
    case 'creator': {
      cellVal = `${formRowData.creator.metadata.givenName || ''}","${
        formRowData.creator.metadata.familyName || ''
      }","${formRowData.creator.metadata.email || ''}`;
      break;
    }
    case 'responseCount': {
      cellVal = `${formRowData.responseCount.responded}`;
      break;
    }

    case 'waitForResponse': {
      cellVal = `${formRowData.waitForResponse}`;
      break;
    }
    case 'createdAt': {
      cellVal = `${new Date(
        Date.parse(formRowData.createdAt),
      ).toLocaleDateString()}`;
      break;
    }

    case 'latestResponse': {
      cellVal = formRowData.latestResponse
        ? `${new Date(
            Date.parse(formRowData.latestResponse),
          ).toLocaleDateString()}`
        : '';
      break;
    }

    default:
      cellVal = params.value || '';
  }

  return `"${cellVal}"`;
};

/**
 * Format the column for CSV export - if not an exception just return the column header name
 * @param params object containing columnID and other information required to know which header issued this callback
 * */
export const processFormResponseHeader = (
  params: ProcessHeaderForExportParams,
) => {
  const columnDef = params.column.getColDef();
  if (columnDef.field === 'responder') return 'Responder, Email';
  // remove commas from the header title
  const sanitizedHeaderTitle = columnDef.headerComponentParams.title.replace(
    ',', // if the question title has a comma, replace it with a space so that it does't consider it as a new csv column
    '',
  );
  return sanitizedHeaderTitle;
};

/**
 * Format the row data for CSV export - some row data may be formatted in a specific way
 * @param params object containing column ID, and node data containing other information for the row
 * */
export const processFormResponseCell = (params: ProcessCellForExportParams) => {
  const nodeData = params.node?.data;
  const columnId = params.column.getColId();
  const questionType = params.column.getColDef().headerComponentParams?.type;

  if (columnId === 'responder')
    return `${nodeData.responder.givenName} ${nodeData.responder.familyName}, ${nodeData.responder.email}`;

  if (columnId === 'submissionDate') {
    const submissionTime = new Date(nodeData[columnId]);
    return `${submissionTime.toLocaleDateString()} at ${submissionTime.toLocaleTimeString()}`;
  }

  // when the input type is multi choice OR single choice OR file -  the response value is an array of selected options
  if (
    (questionType === InputsTypes.MultiSelect ||
      questionType === InputsTypes.SingleSelect) &&
    nodeData[columnId]
  ) {
    return `"${nodeData[columnId].join(', ') || ''}"`;
  }

  return `"${nodeData[columnId] || ''}"`;
};
