import React, { useContext, useMemo, useState } from 'react';
import { Button, Theme, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ColDef, GridApi, ICellRendererParams } from '@ag-grid-community/core';
import copy from 'clipboard-copy';
import { LoadingWrapper } from 'src/components/Loading';
import StandardPage from 'src/components/UI/StandardPage';
import {
  CopyIcon,
  Download,
  TrashIcon,
  SignatureDateIcon,
  SignatureIcon,
  SignatureTextIcon,
  SignatureInitialIcon,
} from 'src/components/Icons';
import AgGrid, {
  AgGridContext,
  ChipCellRenderer,
  TextCellRenderer,
} from 'src/components/AgGrid';
import { RootState } from 'src/store';
import {
  ContractStatus,
  ContractTemplate,
  FieldType,
  InputType,
  useCancelContractMutation,
  useGetContractsQuery,
} from 'src/services/api';
import { RouteContext } from 'src/context';
import { ContractDetailsPageQueryParams } from './ContractDetailsPage';
import { UserAvatar } from '../User';
import { ensureUnreachable } from 'src/utils/CommonUtils';
import { Action, BaseActionsMenu } from '../Dropdowns';
import { Contract } from 'src/entities/Contract';
import { ShareContractModal } from './ShareContractModal';
import { LocaleDateFormatter } from '../Formatters';
import MemoBaseTypography from '../Text/BaseTypography';
import { getContractStatusInfo } from './ClientContractsTable';
import { FileUtils, S3Utils, UrlUtils } from 'src/utils';
import { getFileNameFromFileKey } from 'src/components/Files/helpers';
import history from 'src/history';
import { CONTRACTS_PAGE } from 'src/constants';
import { useCustomDomains } from 'src/hooks/useCustomDomains';
import { useModuleIcon } from 'src/hooks/useModuleIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 4),
    marginTop: theme.spacing(3),
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginTop: 0,
    },
  },
  columnHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    '& svg': {
      height: 16,
      width: 16,
    },
  },
}));

/**
 * Determines the appropriate signature icon based on the contract field type
 */
const getSignIconBasedOnFieldType = (type: FieldType) => {
  switch (type) {
    case FieldType.Date:
      return <SignatureDateIcon />;

    case FieldType.Initials:
      return <SignatureInitialIcon />;
    case FieldType.Signature:
      return <SignatureIcon />;
    case FieldType.Text:
      return <SignatureTextIcon />;
    default:
      return ensureUnreachable(type);
  }
};

/**
 * Component responsible for rendering actions for submission and requests table rows.
 */
export const SubmissionTableActionMenu = ({ data }: { data: Contract }) => {
  const [cancelContract] = useCancelContractMutation();
  const { basePortalDomain } = useCustomDomains();

  const handleDownload = async () => {
    const { signedFileUrl, fileUrl } = data;

    const stagedFileKey =
      data.status === ContractStatus.Signed ? signedFileUrl : fileUrl;

    const s3Url = await S3Utils.getFile(stagedFileKey, {
      identityId: data.identityId,
      level: 'protected',
    });

    await FileUtils.downloadFileFromUrl(
      s3Url,
      stagedFileKey,
      getFileNameFromFileKey(stagedFileKey),
    );
  };

  const handleCopyShareLink = () => {
    const shareContractLink = UrlUtils.GetFullUrl(
      `${basePortalDomain}/sl/contract/${data.ref}`,
    );
    copy(shareContractLink);
  };

  const copyContractAction = {
    name: 'Copy contract link',
    icon: <CopyIcon />,
    onClick: handleCopyShareLink,
  };

  const cancelContractAction = {
    name: 'Cancel request',
    icon: <TrashIcon />,
    isDelete: true,
    onClick: () => cancelContract({ id: data.id }),
  };

  const downloadContractAction = {
    name: 'Download',
    icon: <Download />,
    onClick: handleDownload,
  };

  /**
   *  Determines the available actions based on the status of the contract.
   * - Pending contracts: options include copy link, download, and cancel request.
   * - Signed contracts: only download option is available.
   */
  const getActionsBasedOnStatus = (): Action[] => {
    switch (data.status) {
      case ContractStatus.Pending:
        return [
          copyContractAction,
          downloadContractAction,
          cancelContractAction,
        ];
      case ContractStatus.Signed:
        return [downloadContractAction];
      default:
        return ensureUnreachable(data.status);
    }
  };

  return <BaseActionsMenu actions={getActionsBasedOnStatus()} />;
};

export const SubmissionAndRequestTable = ({
  selectedTemplate,
}: {
  selectedTemplate: ContractTemplate | undefined;
}) => {
  const { query } = useContext(RouteContext);
  const { templateId } = query as ContractDetailsPageQueryParams;

  const [gridApi, setGridApi] = React.useState<GridApi>();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const clients = useSelector((state: RootState) => state.clients.clients);

  const { data: submissionsAndRequests, isLoading } = useGetContractsQuery(
    {
      contractTemplateId: templateId,
    },
    {
      skip: !templateId, // we only want to fetch data when templateId is defined
    },
  );
  const classes = useStyles();
  const searchKey = useSelector((state: RootState) => state.ui.searchValue);
  const Icon = useModuleIcon('contracts');

  const columns: ColDef[] = useMemo(() => {
    // Generates columns to display submitted data from the client based on the selected contract template fields
    const clientSubmissionCols: ColDef[] = (selectedTemplate?.fields || [])
      .filter(
        (f) =>
          (f.inputType === InputType.Client && f.type === FieldType.Text) ||
          f.inputType === InputType.Variable,
      )
      .map((field) => ({
        headerName: (
          <div className={classes.columnHeader}>
            {getSignIconBasedOnFieldType(field.type)} <div>{field.label}</div>
          </div>
        ),
        field: field.id,
        sortable: true,
        minWidth: 252,
      }));

    return [
      {
        headerName: 'Recipient',
        field: 'recipient',
        minWidth: 250,
        getQuickFilterText(filterParams) {
          const { givenName, familyName, email } = filterParams.data.recipient;
          return `${givenName} ${familyName} ${email}`;
        },
        cellRendererFramework({ value }: ICellRendererParams) {
          return (
            <UserAvatar
              avatarUrl={value.avatarImageUrl}
              name={`${value.givenName} ${value.familyName}`}
              description={value.email}
              fallbackColor={value.fallbackColor}
              primaryTextVariant="tableMain"
              shape="circle"
            />
          );
        },
      },
      {
        headerName: 'Sent on',
        field: 'shareDate',
        minWidth: 200,
        cellRendererFramework({ valueFormatted }: ICellRendererParams) {
          return <MemoBaseTypography>{valueFormatted}</MemoBaseTypography>;
        },
        getQuickFilterText(filterParams) {
          return new Date(filterParams.data.sentOn).toLocaleDateString();
        },
        valueFormatter: LocaleDateFormatter,
      },
      {
        headerName: 'Submitted on',
        field: 'submissionDate',
        minWidth: 200,
        cellRendererFramework({ valueFormatted }: ICellRendererParams) {
          return <MemoBaseTypography>{valueFormatted}</MemoBaseTypography>;
        },
        getQuickFilterText(filterParams) {
          return new Date(filterParams.data.sentOn).toLocaleDateString();
        },
        valueFormatter: LocaleDateFormatter,
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 150,
        sortable: true,
        cellRenderer: 'chipCellRenderer',
        cellRendererParams: {
          mapStatusToSeverity: (status: ContractStatus) =>
            getContractStatusInfo(status).severity,
        },
      },
      ...(clientSubmissionCols || []),
      {
        colId: 'actions',
        headerName: 'Actions',
        width: 160,
        pinned: 'right',
        suppressSizeToFit: true,
        cellRenderer: 'actionCellRenderer',
      },
    ];
  }, [selectedTemplate]);
  const rowData = useMemo(() => {
    if (!submissionsAndRequests) return [];

    return submissionsAndRequests.map((item) => {
      // Extract client-submitted values from the fields array and convert them into an object
      // with the shape: { [fieldId]: fieldValue }
      const clientSubmittedValues = item?.fields
        ?.filter(
          (f) =>
            f.inputType === InputType.Client ||
            f.inputType === InputType.Variable,
        )
        .reduce((acc, { id, value }) => {
          acc[id] = value;
          return acc;
        }, {} as Record<string, string | undefined>);

      return {
        ...item,
        ...clientSubmittedValues,
        recipient: clients.find((c) => c.id === item.recipientId)?.fields || {},
      };
    });
  }, [submissionsAndRequests, clients]);

  const shouldShowStandardPage = !rowData.length;

  const griOptions = React.useMemo(() => ({ gridApi, setGridApi }), [gridApi]);

  return (
    <LoadingWrapper isLoading={isLoading} hideContentWhileLoading>
      {shouldShowStandardPage ? (
        <StandardPage
          topIcon={<Icon style={{ fontSize: 24 }} />}
          title="This contract has not been shared yet"
          desc="Contract submissions and requests will show here."
          actionButton={
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsShareModalOpen(true)}
            >
              Share contract
            </Button>
          }
        />
      ) : (
        <div className={classes.root}>
          <AgGridContext.Provider value={griOptions}>
            <AgGrid
              searchKey={searchKey}
              className="agGridRowHover"
              columnDefs={columns}
              rowData={rowData || []}
              frameworkComponents={{
                textCellRenderer: TextCellRenderer,
                chipCellRenderer: ChipCellRenderer,
                actionCellRenderer: SubmissionTableActionMenu,
              }}
              onRowClicked={(value: Contract) => {
                history.push(
                  `${CONTRACTS_PAGE.path}/submission?contractId=${value.id}`,
                );
              }}
            />
          </AgGridContext.Provider>
        </div>
      )}
      <ShareContractModal
        open={isShareModalOpen}
        selectedTemplate={selectedTemplate}
        onClose={() => setIsShareModalOpen(false)}
      />
    </LoadingWrapper>
  );
};
