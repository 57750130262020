import IFlagsmith from 'flagsmith';
import { Flags } from 'flagsmith-nodejs';
import React from 'react';

// sort flags alphabetically to match flag-smith order
export const DefaultFlagsContext = {
  EnableAlphaApps: false,
  EnableBetaApps: false,
  EnableClientMarketplaceApps: false,
  EnableCustomAppVisibility: false,
  EnableCustomApps: false,
  EnableEmailNotificationsDigest: false,
  EnableFilesNotificationCenter: false,
  EnableFormsNotificationCenter: false,
  EnableInvoicesNotificationCenter: false,
  EnableMarketplaceSubmissions: false,
  EnableNotificationCenter: false,
  EnableNotificationCount: false,
  EnableNotificationFilePreview: false,
  EnableNotificationSidebarItemRedesign: false,
  EnableNotificationWebhook: false,
  EnableOneOffContract: false,
  EnableSendEmailsToUninvitedClients: false,
  GoogleLoginForClients: false,
  GoogleLoginForInternalUser: false,
  OnboardingDisabledReason: '',
  EnableBillingImprovements: false,
};

export type FlagContextType = typeof DefaultFlagsContext;

export const getServerFlagsContextValue = (flags: Flags) => ({
  EnableCustomAppVisibility: flags.isFeatureEnabled('custom_visibility'),
  EnableCustomApps: flags.isFeatureEnabled('enable_custom_app_platform'),
  EnableNotificationCenter: flags.isFeatureEnabled('notification-center'),
  EnableFilesNotificationCenter: flags.isFeatureEnabled(
    'enable_files_notification_center',
  ),
  EnableFormsNotificationCenter: flags.isFeatureEnabled(
    'forms_notification_center',
  ),
  EnableSendEmailsToUninvitedClients: flags.isFeatureEnabled(
    'send_emails_to_uninvited_clients',
  ),
  EnableNotificationCount: flags.isFeatureEnabled('notification_count_title'),
  EnableNotificationSidebarItemRedesign: flags.isFeatureEnabled(
    'notification_sidebar_item_redesign',
  ),
  EnableInvoicesNotificationCenter: flags.isFeatureEnabled(
    'invoices_notification_center',
  ),
  EnableNotificationFilePreview: flags.isFeatureEnabled(
    'notification_file_preview',
  ),
  GoogleLoginForInternalUser: flags.isFeatureEnabled(
    'google_login_for_internal_user',
  ),
  GoogleLoginForClients: flags.isFeatureEnabled('google_login_for_clients'),
  EnableMarketplaceSubmissions: flags.isFeatureEnabled(
    'enable_marketplace_submissions',
  ),
  EnableAlphaApps: flags.isFeatureEnabled('enable_alpha_apps'),
  EnableBetaApps: flags.isFeatureEnabled('enable_beta_apps'),
  EnableClientMarketplaceApps: flags.isFeatureEnabled(
    'enable_client_marketplace_apps',
  ),
  EnableBillingImprovements: flags.isFeatureEnabled('billing_improvement_m1'),
  EnableNotificationWebhook: flags.isFeatureEnabled('notification_webhook'),
  EnableOneOffContract: flags.isFeatureEnabled('one-off-contract'),
  OnboardingDisabledReason: flags.getFeatureValue('onboarding_disabled'),
  EnableCustomAppPlatform: flags.getFeatureValue('enable_custom_app_platform'),
});

export const getClientFlagsContextValue = (flags: typeof IFlagsmith) => ({
  EnableCustomAppVisibility: flags.hasFeature('custom_visibility'),
  EnableCustomApps: flags.hasFeature('enable_custom_app_platform'),
  EnableNotificationCenter: flags.hasFeature('notification-center'),
  EnableFormsNotificationCenter: flags.hasFeature('forms_notification_center'),
  EnableFilesNotificationCenter: flags.hasFeature(
    'enable_files_notification_center',
  ),
  EnableNotificationCount: flags.hasFeature('notification_count_title'),
  EnableNotificationSidebarItemRedesign: flags.hasFeature(
    'notification_sidebar_item_redesign',
  ),
  EnableSendEmailsToUninvitedClients: flags.hasFeature(
    'send_emails_to_uninvited_clients',
  ),
  EnableInvoicesNotificationCenter: flags.hasFeature(
    'invoices_notification_center',
  ),
  EnableNotificationFilePreview: flags.hasFeature('notification_file_preview'),
  GoogleLoginForInternalUser: flags.hasFeature(
    'google_login_for_internal_user',
  ),
  GoogleLoginForClients: flags.hasFeature('google_login_for_clients'),
  EnableMarketplaceSubmissions: flags.hasFeature(
    'enable_marketplace_submissions',
  ),
  EnableAlphaApps: flags.hasFeature('enable_alpha_apps'),
  EnableBetaApps: flags.hasFeature('enable_beta_apps'),
  EnableClientMarketplaceApps: flags.hasFeature(
    'enable_client_marketplace_apps',
  ),
  EnableBillingImprovements: flags.hasFeature('billing_improvement_m1'),
  EnableNotificationWebhook: flags.hasFeature('notification_webhook'),
  EnableOneOffContract: flags.hasFeature('one-off-contract'),
  OnboardingDisabledReason: flags.getValue('onboarding_disabled'),
});

export const FlagsContext = React.createContext(DefaultFlagsContext);
