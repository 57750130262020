import mediaQuery from 'css-mediaquery';
import { createTheme } from '@material-ui/core/styles';
import { generateTypography } from 'src/theme/typography';
import { zIndex } from 'src/theme/zindex';
import { shape } from 'src/theme/shape';
import mixins from 'src/theme/mixins';
import overrides, {
  AdditionalOverrides,
  ComponentsOptions,
} from 'src/theme/overrides';
import * as Colors from 'src/theme/colors';

export const GetCustomTheme = (isMobile: boolean, portalConfig: any) => {
  const { clientAccentColor } = portalConfig.brand;

  const internalUserPrimaryMainColor = Colors.InternalAccent;

  // For new created or old portal we will not have clientAccentColor set
  // so in those cases we will fallback to theme primary main color
  const clientUserPrimaryMainColor =
    clientAccentColor || portalConfig.theme.palette.primary.main;

  // portal primary main color based on user type ( internal or client )
  const primaryMainColor =
    portalConfig.viewMode === 'client'
      ? clientUserPrimaryMainColor
      : internalUserPrimaryMainColor;

  const palette = {
    ...portalConfig.theme.palette,
    primary: {
      ...portalConfig.theme.palette.primary,
      main: primaryMainColor,
    },
  };

  const baseTheme = createTheme({
    palette: {
      ...palette,
      action: {
        active: Colors.GraySmall,
        hover: Colors.BlackHeadings,
      },
      tonalOffset: {
        light: 0.2,
        dark: 0.16,
      },
    },
    mixins,
    typography: generateTypography(portalConfig),
    zIndex,
    overrides,
    shape,
    props: {
      ...ComponentsOptions,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1560,
      },
    },
  });

  AdditionalOverrides.forEach((overrideFunc) => overrideFunc(baseTheme));

  const widthOverride = { width: '0px' };
  const ssrMatchMedia = (query) => ({
    matches: mediaQuery.match(query, isMobile ? widthOverride : {}),
  });

  return {
    ...baseTheme,
    props: {
      ...baseTheme.props,
      MuiUseMediaQuery: { ssrMatchMedia },
    },
  };
};
