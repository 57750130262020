import { ApiMethods, ApiTags, appAPI } from '.';
import { notify } from 'src/clients/ApiService';

export enum ProductStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum PriceType {
  OneTime = 'oneTime',
  Recurring = 'recurring',
}

type Price = {
  id: string;
  amount: number;
  currency: string;
  type: PriceType;
  createdAt: string;
};
type CreatePriceInput = {
  productId: string;
  amount: number;
  currency: string;
  type: PriceType;
};

export type CreateProductInput = {
  name: string;
  description?: string;
  imageUrls: string[];
  prices: Omit<Price, 'id' | 'createdAt'>[];
};

export type Product = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  description?: string;
  imageUrls: string[];
  status: ProductStatus;
  taxPercentage: number;
  priceIds: string[];
  price?: Price;
};

type GetProductsResponse = {
  data: Product[];
};

export const productsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    addProduct: build.mutation<Product, CreateProductInput>({
      query: (product) => ({
        path: '/products',
        method: ApiMethods.post,
        options: {
          body: {
            ...product,
            prices: product.prices.map((price) => ({
              ...price,
              amount: Number(price.amount) * 100, // amount in cents
            })),
          },
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Product has been added',
            dispatch,
          });
        } catch (error) {
          notify({
            status: 'error',
            errorMessage: 'This product could not be added.',
            error,
            dispatch,
          });
        }
      },
    }),

    getProductById: build.query<Product, string>({
      query: (id) => ({
        path: `/products/${id}`,
        method: ApiMethods.get,
        options: {},
      }),
    }),
    getPricesByProductId: build.query<Price[], string>({
      query: (productId) => ({
        path: `/prices?productId=${productId}`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.prices],
    }),
    getProducts: build.query<Product[], void>({
      query: () => ({
        path: '/products',
        method: ApiMethods.get,
        options: {},
      }),
      transformResponse: (response: GetProductsResponse) => response.data,
    }),
    addPriceByProductId: build.mutation<Price, CreatePriceInput>({
      query: (price) => ({
        path: `/prices`,
        method: ApiMethods.post,
        options: {
          body: {
            ...price,
            amount: Number(price.amount) * 100, // in cents
          },
        },
      }),
      invalidatesTags: [ApiTags.prices],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Price has been added',
            dispatch,
          });
        } catch (error) {
          notify({
            status: 'error',
            errorMessage: 'This price could not be added.',
            error,
            dispatch,
          });
        }
      },
    }),
  }),
});

export const {
  useAddProductMutation,
  useGetProductByIdQuery,
  useGetPricesByProductIdQuery,
  useGetProductsQuery,
  useAddPriceByProductIdMutation,
} = productsApi;
