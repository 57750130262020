import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { RootState } from 'src/store';
import {
  getFormNotificationSummaryTitleByStatus,
  isUnreadNotification,
  sortNotificationsByDate,
} from 'src/utils/NotificationsUtils';
import { useCountUnreadNotifications } from 'src/components/Files/useCountUnreadNotifications';
import {
  EsigRequestCompleteNotification,
  EsigRequestNotification,
  ESIG_COMPLETE_NOTIFICATION_TEXT,
  ESIG_REQUEST_NOTIFICATION_TEXT,
  FileNotification,
  FormCompleteNotification,
  NotificationGroups,
  NotificationSummaryData,
  FormsNotificationsStatuses,
} from 'src/store/notifications/types';
import { ChannelOptionData } from 'src/components/Channels/ChannelSidebarItem';
import { ChannelType } from 'src/components/Channels/useChannelForm';
import { FlagsContext } from 'src/context';

/**
 * if a channel receives a notification, this method will retrieve its notification summary data
 * (time, subtitle, notification counter), otherwise it will returns its last notification summary data.
 * @param channel
 */
export const useChannelPreviewData = (
  channelData: ChannelOptionData,
  channelType: ChannelType,
) => {
  const { fileChannel, id } = channelData;
  const { EnableFilesNotificationCenter } = useContext(FlagsContext);
  const isClient = useSelector((state: RootState) => state.user.isClient);
  let filesUploadNotificationCounter = 0;
  let esigRequestNotificationCounter = 0;
  let esigRequestCompleteNotificationCounter = 0;
  let formsCompleteNotificationCounter = 0;

  const fileNotifications = useSelector(
    (state: RootState) => state.notifications.fileNotifications,
  );

  const esigRequestNotifications = useSelector(
    (state: RootState) => state.notifications.esigRequestNotifications,
  );

  const esigRequestCompleteNotifications = useSelector(
    (state: RootState) => state.notifications.esigRequestCompleteNotifications,
  );

  const formCompleteNotifications = useSelector(
    (state: RootState) => state.notifications.formCompletedNotifications,
  );

  const filesUnreadNotifications = fileNotifications.filter(
    (notification: FileNotification) =>
      notification.fields.channelId === fileChannel?.id &&
      isUnreadNotification(notification),
  );

  const esigRequestUnreadNotifications = esigRequestNotifications.filter(
    (notification: EsigRequestNotification) =>
      notification.fields?.channelId === fileChannel?.id &&
      isUnreadNotification(notification),
  );

  const esigRequestCompleteUnreadNotifications =
    esigRequestCompleteNotifications.filter(
      (notification: EsigRequestCompleteNotification) =>
        notification.fields.channelId === fileChannel?.id &&
        isUnreadNotification(notification),
    );

  const formCompleteUnreadNotifications = formCompleteNotifications.filter(
    (notification: FormCompleteNotification) =>
      notification.ownerId.split('/').pop() === id &&
      isUnreadNotification(notification),
  );

  const sortedFormCompleteUnreadNotifications = sortNotificationsByDate(
    formCompleteUnreadNotifications,
  );

  const sortedFilesUnreadNotifications = sortNotificationsByDate(
    filesUnreadNotifications,
  );

  const sortedEsigRequestUnreadNotifications = sortNotificationsByDate(
    esigRequestUnreadNotifications,
  );

  const sortedEsigRequestCompleteUnreadNotifications = sortNotificationsByDate(
    esigRequestCompleteUnreadNotifications,
  );

  // channel last notification is the recent notification linked to the channel entity
  // Note: only files notifications can be stored in file channel entity fields. E-sign requests are only
  // shown they are unread. Once they are read they are not returned from the api and the channel will fallback
  // to showing the last file/form notification
  const channelLastNotification =
    ['fileChannel', 'files'].includes(channelType) && fileChannel
      ? fileChannel.fields.lastNotification
      : null;

  /**
   * We implement this method to setup recent notification.
   * Note: e-sign notifications have the highest priority to be shown
   * in the file channel summary. That means when client/
   * user receives an esig notificatioon and then he
   * receives a file upload notification, we should show
   * the esig notifications in high level. Otherwise, we show
   * file upload notifications if they exist.
   */
  const setupRecentNotification = () => {
    switch (channelType) {
      case 'files':
        if (
          sortedEsigRequestUnreadNotifications?.length > 0 ||
          sortedEsigRequestCompleteUnreadNotifications?.length > 0
        ) {
          return [
            ...sortedEsigRequestUnreadNotifications,
            ...sortedEsigRequestCompleteUnreadNotifications,
          ][0];
        }
        return sortedFilesUnreadNotifications.at(0);
      case 'forms':
        if (sortedFormCompleteUnreadNotifications.length > 0) {
          return sortedFormCompleteUnreadNotifications.at(0);
        }
        return null;
      default:
        return null;
    }
  };
  /**
   * This method allow to retrieve recent notification time and summary text.
   * if there is a new notification, it retreive the summary props from it.
   * otherwise, it get them from the file channel last notification field.
   */
  const getChannelSummary = () => {
    let channelSummary: NotificationSummaryData = {
      time: undefined,
      subTitle: '',
    };

    const recentNotification = setupRecentNotification();

    switch (channelType) {
      case 'fileChannel':
      case 'files':
        if (recentNotification) {
          const recentNotificationGroup =
            recentNotification.fields?.notificationGroup;

          switch (recentNotificationGroup) {
            case NotificationGroups.EsigRequestNotifications:
              channelSummary = {
                time: new Date(recentNotification.createdDate),
                subTitle: ESIG_REQUEST_NOTIFICATION_TEXT,
              };
              break;
            case NotificationGroups.EsigRequestCompleteNotifications:
              channelSummary = {
                time: new Date(recentNotification.createdDate),
                subTitle: ESIG_COMPLETE_NOTIFICATION_TEXT,
              };
              break;
            case NotificationGroups.FileNotifications:
              channelSummary = {
                time:
                  sortedFilesUnreadNotifications.length > 0
                    ? new Date(recentNotification.createdDate)
                    : new Date(channelLastNotification?.createdDate || ''),
                subTitle: `${recentNotification.owner?.fields.givenName} shared files`,
              };
              break;
            default:
              break;
          }
        } else if (fileChannel?.owner) {
          channelSummary = {
            time: new Date(channelLastNotification?.createdDate || ''),
            subTitle: `${fileChannel?.owner?.fields.givenName} shared files`,
          };
        } else {
          channelSummary = {
            time: new Date(channelData.fileChannel?.createdDate || ''),
            subTitle: `File channel created`,
          };
        }
        break;

      case 'forms':
        channelSummary = {
          time: recentNotification
            ? new Date(recentNotification.createdDate)
            : new Date(channelData?.updatedDate || ''),
          subTitle: getFormNotificationSummaryTitleByStatus(
            channelData.status as FormsNotificationsStatuses,
          ),
        };
        break;

      case 'extension':
        channelSummary = {
          time: undefined,
          subTitle:
            channelData.status === 'connected' ? 'Connected' : ' Not Connected',
        };
        break;
      default:
        break;
    }

    return channelSummary;
  };

  /**
   * A) 1 file notification and 1 e-sign notification, total = 2
   * B) 2 files notifications and 1 e-sign notification, total = 2
   * C) 1 file notification and 2 e-sign notifications, total = 3
   */
  const getTotalUnreadNotificationsCount = () => {
    let esigFilesNotificationsCounter = 0;
    switch (channelType) {
      case 'fileChannel':
      case 'files':
        // We don't want to show notification count on files sidebar when
        // files notification feature flag is on ( only for intenral user )
        // when notification count is 0 we don't show notification badge
        if (EnableFilesNotificationCenter && !isClient) return 0;

        filesUploadNotificationCounter = useCountUnreadNotifications(
          sortedFilesUnreadNotifications,
        );

        esigRequestNotificationCounter = useCountUnreadNotifications(
          sortedEsigRequestUnreadNotifications,
        );

        esigRequestCompleteNotificationCounter = useCountUnreadNotifications(
          sortedEsigRequestCompleteUnreadNotifications,
        );
        esigFilesNotificationsCounter = isClient
          ? esigRequestNotificationCounter
          : esigRequestCompleteNotificationCounter;

        if (filesUploadNotificationCounter > 0) {
          return esigFilesNotificationsCounter + 1;
        }
        return esigFilesNotificationsCounter;

      case 'forms':
        formsCompleteNotificationCounter = useCountUnreadNotifications(
          sortedFormCompleteUnreadNotifications,
        );
        return formsCompleteNotificationCounter;

      default:
        return null;
    }
  };

  return {
    getChannelSummary,
    getTotalUnreadNotificationsCount,
  };
};
