import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useContext } from 'react';
import { InboxContent } from 'src/components/Inbox/InboxContent';
import { InboxSidebar } from 'src/components/Inbox/InboxSidebar';
import { FlagsContext } from 'src/context';
import { InboxPageContext } from 'src/context/inboxPageContext';
import { LegacyInboxSidebar } from './LegacyInboxSidebar';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      overflow: 'hidden',
    },
  }),
);

export const InboxPage = () => {
  const classes = useStyles();

  const [selectedNotification, setSelectedNotification] = React.useState(null);

  const inboxPageContextValue = React.useMemo(
    () => ({
      selectedNotification,
      setSelectedNotification,
    }),
    [selectedNotification, setSelectedNotification],
  );

  const { EnableNotificationSidebarItemRedesign } = useContext(FlagsContext);
  const InboxSidebarComponent = EnableNotificationSidebarItemRedesign
    ? InboxSidebar
    : LegacyInboxSidebar;
  return (
    <InboxPageContext.Provider value={inboxPageContextValue}>
      <div className={classes.container}>
        <InboxSidebarComponent />
        <InboxContent />
      </div>
    </InboxPageContext.Provider>
  );
};
