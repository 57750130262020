import React from 'react';
import {
  Box,
  Dialog,
  makeStyles,
  createStyles,
  Theme,
  DialogProps,
  ModalProps,
  ButtonProps,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import classNames from 'classnames';
import Button from 'src/components/Button';
import BaseTypography from 'src/components/Text/BaseTypography';

import { ModalShadow } from 'src/theme/shadows';
import { GrayThinBorder } from 'src/theme/borders';
import * as Colors from 'src/theme/colors';

import ColorUtils from 'src/utils/ColorUtils';

export interface ModalWrapperProps extends DialogProps {
  title?: string;
  description?: string;
  closeButton?: boolean;
  successButtonLabel?: string;
  cancelButtonLabel?: string;
  successButtonEndIcon?: JSX.Element;
  positiveAction?: boolean;
  onSuccess?: () => void;
  onClose?: ModalProps['onClose'];
  isLoading?: boolean;
  hideActions?: boolean;
  denseContent?: boolean;
  hasBackdrop?: boolean;
  disableGutters?: boolean;
  hideDividers?: boolean;
  modalType?: 'warning' | 'table';
  alertIcon?: JSX.Element;
  noCancelButton?: boolean;
  width?: number | string;
  height?: number | string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  noBorder?: boolean;
  isDeleteModal?: boolean;
  onFooterLeftButtonClick?: () => void;
  footerLeftButtonLabel?: string;
  leftButtonProps?: ButtonProps;
}
const DESKTOP_MODAL_WIDTH = 470;

interface ModalStyleProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
  width?: number | string;
  height?: number | string;
  disableGutters?: boolean;
  noBorder?: boolean;
  footerHasLeftButton?: boolean; // used to determine if the footer has a left button in order to position the right buttons accordingly
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmButtonWrapper: {
      marginLeft: theme.spacing(2.5),
    },
    deleteButton: {
      backgroundColor: Colors.red,
      borderColor: ColorUtils.GetColorDarknessShades(Colors.red).dark,
      '&:hover': {
        backgroundColor: ColorUtils.GetColorDarknessShades(Colors.red).dark,
      },
    },
    dialogBackdrop: {
      backgroundColor: Colors.ModalUnderlay,
    },
    dialogPaper: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
        width: (props: ModalStyleProps) =>
          props.fullWidth === true
            ? 'auto'
            : props.width || DESKTOP_MODAL_WIDTH,
        height: (props: ModalStyleProps) =>
          props.fullHeight === true ? '100%' : props.height,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#fff',
      border: (props: ModalStyleProps) =>
        props.noBorder ? 'none' : GrayThinBorder,
      boxShadow: ModalShadow,
    },
    muiDialogTitle: {
      padding: theme.spacing(2, 3.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5, 2),
      },
      margin: 0,
    },
    muiWarningDialogTitle: {
      paddingTop: theme.spacing(3),
    },
    muiTableModalTitle: {
      padding: theme.spacing(5, 6, 3),
    },
    muiDialogContent: {
      padding: (props: ModalStyleProps) =>
        props.disableGutters ? '0px !important' : theme.spacing(2, 3.5),
      [theme.breakpoints.down('xs')]: {
        padding: (props: ModalStyleProps) =>
          props.disableGutters ? '0px !important' : theme.spacing(1.5, 2),
      },
    },
    muiDialogActions: {
      padding: theme.spacing(2, 3.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5, 2),
      },
      justifyContent: (props) =>
        props.footerHasLeftButton ? 'space-between' : 'flex-end',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(0.5),
      [theme.breakpoints.down('xs')]: {
        top: theme.spacing(0),
      },
      color: theme.palette.grey[500],
    },
    muiDialogContentDense: {
      padding: theme.spacing(0),
    },
    muiTableModalContent: {
      padding: theme.spacing(0, 6),
    },
    warningIconContainer: {
      backgroundColor: Colors.HoverNonBorderBackground,
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(2),
    },
  }),
);

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
  children,
  title,
  description,
  closeButton = false,
  successButtonLabel = 'Save',
  cancelButtonLabel = 'Cancel',
  successButtonEndIcon,
  positiveAction = true,
  onSuccess,
  onClose,
  isLoading = false,
  hideActions = false,
  denseContent = false,
  hasBackdrop = false,
  hideDividers = false,
  modalType,
  noCancelButton = false,
  fullWidth,
  noBorder = false,
  width,
  fullHeight,
  height,
  alertIcon,
  disableGutters = false,
  isDeleteModal = false,
  onFooterLeftButtonClick,
  footerLeftButtonLabel,
  leftButtonProps,
  ...rest
}) => {
  const classes = useStyles({
    fullWidth,
    disableGutters,
    width,
    fullHeight,
    height,
    noBorder,
    footerHasLeftButton: !!footerLeftButtonLabel,
  });

  const handleClose: ModalProps['onClose'] = React.useCallback(
    (event, reason) => {
      if (reason === 'backdropClick' && isLoading) {
        return;
      }
      if (onClose) {
        onClose(event, reason);
      }
    },
    [isLoading, onClose],
  );

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: hasBackdrop ? '' : classes.dialogBackdrop,
        },
      }}
      PaperProps={{
        classes: {
          root: classes.dialogPaper,
        },
      }}
      onClose={handleClose}
      transitionDuration={0}
      disableEscapeKeyDown={isLoading}
      maxWidth={fullWidth ? false : 'sm'}
      {...rest}
    >
      {title && (
        <MuiDialogTitle
          id="dialog-title"
          className={classNames(classes.muiDialogTitle, {
            [classes.muiWarningDialogTitle]: modalType === 'warning',
            [classes.muiTableModalTitle]: modalType === 'table',
          })}
        >
          {modalType === 'warning' && alertIcon && (
            <Box
              display="flex"
              height={40}
              width={40}
              className={classes.warningIconContainer}
            >
              {alertIcon}
            </Box>
          )}
          {modalType === 'warning' && (
            <BaseTypography
              fontType="24Medium"
              style={{ color: Colors.BlackHeadings }}
            >
              {title}
            </BaseTypography>
          )}
          {modalType === 'table' && (
            <BaseTypography fontType="18Medium">{title}</BaseTypography>
          )}
          {!modalType && (
            <BaseTypography fontType="15Medium">{title}</BaseTypography>
          )}
          {description && (
            <BaseTypography
              style={{ color: Colors.GraySmall, marginTop: '4px' }}
            >
              {description}
            </BaseTypography>
          )}
          {closeButton ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      )}
      <MuiDialogContent
        dividers={!hideDividers}
        className={classNames(
          !denseContent
            ? classes.muiDialogContent
            : classes.muiDialogContentDense,
          {
            [classes.muiTableModalContent]: modalType === 'table',
          },
        )}
      >
        {children}
      </MuiDialogContent>
      {!hideActions && (
        <MuiDialogActions disableSpacing className={classes.muiDialogActions}>
          {/* Modal secondary action */}
          {footerLeftButtonLabel && (
            <Button
              htmlId="modal-footer-text-button"
              data-testid="modal-footer-text-button"
              variant="text"
              onClick={onFooterLeftButtonClick}
              color="primary"
              {...leftButtonProps}
            >
              {footerLeftButtonLabel}
            </Button>
          )}

          {/* Modal control actions */}

          <div style={{ display: 'flex' }}>
            {!noCancelButton && (
              <Button
                htmlId="cancel-button"
                data-testid="cancel-button"
                variant="contained"
                onClick={onClose}
                color="secondary"
                disabled={isLoading}
              >
                {cancelButtonLabel}
              </Button>
            )}
            {positiveAction && onSuccess && (
              <Box className={classes.confirmButtonWrapper}>
                <Button
                  className={isDeleteModal ? classes.deleteButton : ''}
                  variant="contained"
                  type="submit"
                  color="primary"
                  htmlId="success-action"
                  onClick={onSuccess}
                  isLoading={isLoading}
                  endIcon={successButtonEndIcon}
                >
                  {successButtonLabel}
                </Button>
              </Box>
            )}
          </div>
        </MuiDialogActions>
      )}
    </Dialog>
  );
};
