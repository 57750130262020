import React from 'react';
import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Theme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CopyText } from 'src/components/CopyToClipboard';
import { BaseTextField } from 'src/components/TextField';
import { typography13RegularStyle } from 'src/components/Text/BaseTypography';
import { BlackHeadings, red } from 'src/theme/colors';
import { SVGIcon } from 'src/components/Icons';

interface QuickTextEditorStyleProps {
  width?: number;
  hasStartIcon: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: ({ hasStartIcon }: QuickTextEditorStyleProps) =>
        hasStartIcon ? theme.spacing(1.25) : 'unset',
    },
    helperText: {
      marginLeft: 0,
      textAlign: 'left',
      position: 'absolute',
      top: '30px',
      color: red,
      width: 'max-content',
      ...typography13RegularStyle,
    },
    actionIcon: {
      fontSize: 12,
      color: BlackHeadings,
      '& path': {
        strokeWidth: 1.75,
      },
    },
  }),
);

interface QuickTextInputAction {
  key: string;
  icon: typeof SVGIcon;
  onClick: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void;
  isCopy?: boolean;
}
interface QuickTextEditorProps {
  value: string;
  onUpdate: (newValue: string) => void;
  onCancel?: () => void;
  // determines whether the editor can be edited or not.
  // this prop can be used to restrict edition when
  // user has no permission to perform edition.
  editable?: boolean;
  width?: number;
  validator?: (value: string) => string;
  startIcon?: React.ReactNode;
  onFocus?: (ev?: React.FocusEvent) => void;
  // sometimes we need to explicitly edit the quick editor from outside
  // e.g. On client sidebar menu, when clicking on edit name option,
  // the editor should show the edit input. For that we can use
  // canEdit = true when certain event happens.
  canEdit?: boolean;
  showCopyIcon?: boolean;
  InputComponent?: React.FC;
  inputWrapperStyleProps?: React.CSSProperties;
  skipErrorOnUnfocused?: boolean;
  inputActions?: Array<QuickTextInputAction>;
  disabled?: boolean;
  canSubmitEmptyString?: boolean;
}

export const QuickTextEditor: React.FC<QuickTextEditorProps> = ({
  value,
  onUpdate,
  children,
  editable = true,
  width,
  validator,
  startIcon,
  onFocus,
  canEdit,
  showCopyIcon = false,
  InputComponent = BaseTextField,
  inputWrapperStyleProps,
  skipErrorOnUnfocused = false,
  inputActions,
  disabled = false,
  onCancel,
  canSubmitEmptyString = false,
}) => {
  const classes = useStyles({ width, hasStartIcon: Boolean(startIcon) });
  const [textValue, setTextValue] = React.useState(value);
  const [errMessage, setErrMessage] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const [showEditInput, setShowEditInput] = React.useState(false);

  const handleTextClicked = () => {
    if (editable) {
      setShowEditInput(true);
    }
  };

  // when text value input changes, update the state
  // this fix the scenario where in client details page
  // switching from client to another, the client name
  // does not update in the quick editor.
  React.useEffect(() => {
    setTextValue(value);
  }, [value]);

  /**
   * Check if the new value is valid based on validator function prop
   * set state accordingly for error message and updated text value
   * @param event input change event
   */
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> & string,
  ) => {
    const newValue = event?.target ? event?.target.value : event;
    if (validator) {
      const validationErrorMessage = validator(newValue);
      if (validationErrorMessage) {
        setErrMessage(validationErrorMessage);
      } else {
        setErrMessage('');
      }
    }
    setTextValue(newValue);
  };

  const closeSelf = () => {
    if (onFocus) {
      onFocus();
    }
    setShowEditInput(false);
  };

  /**
   * This function handle closing the quick text editor
   * when it checks if the closing function is provided
   * as prop then use it, otherwise call
   * closeSelf which handle closing
   * the editor by itself.
   */
  const closeEditor = () => {
    if (onCancel) {
      onCancel();
      return;
    }
    closeSelf();
  };
  /**
   * Call onUpdate prop when input entered is valid
   * and cancel the editable state on the text editor
   */
  const handleUpdate = () => {
    // avoid to update custom field when it is not in
    // edit mode. e.g. enter shortcut should
    // not update the custom field value.
    if (!canEdit && !showEditInput) {
      setShowEditInput(false);
      return;
    }
    // e.g. custom fields text should be able to
    // update empty string values.
    if (!textValue && canSubmitEmptyString) {
      onUpdate(textValue);
      closeEditor();
      return;
    }

    // when there is error value should not be saved
    if (errMessage) {
      // skip error validation when input get unfocused
      if (skipErrorOnUnfocused) {
        if (onCancel) onCancel();
      }
      return;
    }

    if (textValue && textValue.length > 0) {
      onUpdate(textValue);
    }
    closeEditor();
  };

  const handleKeydown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    const { keyCode } = ev;
    // handle escape key pressed
    if (keyCode === 27) {
      closeEditor();
    }

    // handle enter key pressed
    if (keyCode === 13) {
      handleUpdate();
    }
  };

  /**
   * after blur event check that the state has been updated to represent that item is
   * no focused. If that is not true then this means the target was blurred prematurely
   * by a different event.
   * https://stackoverflow.com/questions/16038912/how-to-prevent-an-element-from-losing-focus
   */
  const verifyBlurEvent = (target: HTMLElement) => {
    setTimeout(() => {
      if (canEdit || showEditInput) {
        target.focus();
      }
    }, 300);
  };

  return (
    <>
      {startIcon}
      {!canEdit && !showEditInput ? (
        <Box onClick={handleTextClicked} ml={startIcon ? 3 : 0} width={1}>
          {children}
        </Box>
      ) : (
        <ClickAwayListener onClickAway={handleUpdate}>
          <Box
            ml={startIcon ? 1.5 : 0}
            mb={errMessage && showError ? 2 : 0}
            style={inputWrapperStyleProps}
          >
            <InputComponent
              onKeyDown={handleKeydown}
              fullWidth
              autoFocus
              disabled={disabled}
              onFocus={onFocus}
              className={classes.root}
              variant="outlined"
              value={textValue}
              onChange={handleChange}
              // To prevent click on the control resulting in row click, event bubbling needs to be stopped.
              onClick={(event) => event.stopPropagation()}
              error={Boolean(errMessage) && showError && !skipErrorOnUnfocused}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                verifyBlurEvent(event.target);
                if (!skipErrorOnUnfocused) setShowError(true);
              }}
              helperText={showError && errMessage}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              InputProps={{
                endAdornment: (
                  /* eslint-disable-next-line react/jsx-no-useless-fragment */
                  <>
                    {
                      // show input actions only when text value is valid
                      validator &&
                        !validator(textValue) &&
                        Boolean(textValue) && (
                          <InputAdornment position="end">
                            <>
                              {showCopyIcon && <CopyText value={textValue} />}
                              {(inputActions || []).map((action) => {
                                const Icon = action.icon;
                                return (
                                  <IconButton
                                    key={action.key}
                                    onClick={(
                                      ev: React.MouseEvent<HTMLButtonElement>,
                                    ) => action.onClick(ev, textValue)}
                                    size="small"
                                  >
                                    <Icon className={classes.actionIcon} />
                                  </IconButton>
                                );
                              })}
                            </>
                          </InputAdornment>
                        )
                    }
                  </>
                ),
              }}
            />
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};
