export const CLIENT_EMPTY_STATE_LEARN_MORE =
  'https://www.copilot.com/guide/create-clients-one-at-a-time';

export const MESSAGE_CHANNEL_EMPTY_STATE_LEARN_MORE =
  'https://www.copilot.com/guide/copilot-messages-app';

export const FILE_CHANNEL_EMPTY_STATE_LEARN_MORE =
  'https://www.copilot.com/guide/copilot-files-app';

export const FORMS_EMPTY_STATE_LEARN_MORE =
  'https://www.copilot.com/guide/copilot-forms-app';

export const INVOICES_EMPTY_STATE_LEARN_MORE =
  'https://www.copilot.com/guide/copilot-billing-app#invoicing';

export const SUBSCRIPTION_EMPTY_STATE_LEARN_MORE =
  'https://www.copilot.com/guide/copilot-billing-app#subscriptions';

export const KNOWLEDGE_BASE_EMPTY_STATE_LEARN_MORE =
  'https://www.copilot.com/guide/copilot-helpdesk-app';

export const INBOX_PAGE_EMPTY_STATE_LEARN_MORE =
  'https://support.copilot.com/hc/en-us/articles/14220500495003-How-does-the-notification-center-work-';

export const NOTIFICATIONS_PAGE_EMPTY_STATE_LEARN_MORE =
  'https://www.copilot.com/guide/internal-user-notifications';

export const APPS_PAGE_EMPTY_STATE_LEARN_MORE =
  'https://www.copilot.com/guide/intro-to-apps';
