import Stripe from 'stripe';
import {
  BillingSettings,
  StripeInvoice,
  MessageSettings,
  CrmSettings,
  CrmTableSettings,
  PortalSettings,
  ItemsCount,
  QuickbooksSettings,
  ExtensionsSettings,
  ModuleSettingsItem,
  FileSettings,
  WebhookConfigSet,
  ContractSettings,
} from 'src/constants/dataTypes';

export const CLEAR_SETTINGS = 'CLEAR_SETTINGS';
export const LOAD_SETTINGS_START = 'LOAD_SETTINGS_START';
export const LOAD_SETTINGS_ERROR = 'LOAD_SETTINGS_ERROR';
export const LOAD_SETTINGS_SUCCESS = 'LOAD_SETTINGS_SUCCESS';
export const CLEAR_SETTINGS_CHANGES = 'CLEAR_SETTINGS_CHANGES';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_DONE';
export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';
export const UPDATE_CRM_SETTINGS_SUCCESS = 'UPDATE_CRM_SETTINGS_SUCCESS';
export const UPDATE_CRM_SETTINGS_FAILURE = 'UPDATE_CRM_SETTINGS_FAILURE';
export const UPDATE_CRM_TABLE_SETTINGS_SUCCESS =
  'UPDATE_CRM_TABLE_SETTINGS_SUCCESS';
export const UPDATE_CRM_TABLE_SETTINGS_FAILURE =
  'UPDATE_CRM_TABLE_SETTINGS_FAILURE';
export const UPDATE_BILLING_SETTINGS_SUCCESS =
  'UPDATE_BILLING_SETTINGS_SUCCESS';
export const UPDATE_BILLING_SETTINGS_FAILURE =
  'UPDATE_BILLING_SETTINGS_FAILURE';
export const UPDATE_MODULE_SETTINGS_SUCCESS = 'UPDATE_MODULE_SETTINGS_SUCCESS';
export const UPDATE_MODULE_SETTINGS_FAILURE = 'UPDATE_MODULE_SETTINGS_FAILURE';
export const UPDATE_FILE_SETTINGS_SUCCESS = 'UPDATE_FILE_SETTINGS_SUCCESS';
export const UPDATE_FILE_SETTINGS_FAILURE = 'UPDATE_FILE_SETTINGS_FAILURE';
export const LOAD_PORTAL_SETTINGS = 'LOAD_PORTAL_SETTINGS';
export const REMOVE_PORTAL_SETTINGS = 'REMOVE_PORTAL_SETTINGS';
export const LOAD_MESSAGE_SETTINGS = 'LOAD_MESSAGE_SETTINGS';
export const REMOVE_MESSAGE_SETTINGS = 'REMOVE_MESSAGE_SETTINGS';
export const ADD_WEBHOOK_CONFIG_SET_REQUEST = 'ADD_WEBHOOK_CONFIG_SET_REQUEST';
export const ADD_WEBHOOK_CONFIG_SET_SUCCESS = 'ADD_WEBHOOK_CONFIG_SET_SUCCESS';
export const ADD_WEBHOOK_CONFIG_SET_FAILURE = 'ADD_WEBHOOK_CONFIG_SET_FAILURE';
export const GET_WEBHOOK_CONFIG_SETS_SUCCESS =
  'GET_WEBHOOK_CONFIG_SETS_SUCCESS';
export const DELETE_WEBHOOK_CONFIG_SET_SUCCESS =
  'DELETE_WEBHOOK_CONFIG_SET_SUCCESS';
export const UPDATE_WEBHOOK_CONFIG_SET_SUCCESS =
  'UPDATE_WEBHOOK_CONFIG_SET_SUCCESS';
export const UPDATE_CONTRACT_SETTINGS_SUCCESS =
  'UPDATE_CONTRACT_SETTINGS_SUCCESS';
export const UPDATE_CONTRACT_SETTINGS_FAILURE =
  'UPDATE_CONTRACT_SETTINGS_FAILURE';

export interface NewSettingsResponse {
  invoices?: StripeInvoice[];
  prices: Stripe.Price[];
  messageSettings?: MessageSettings;
  crmSettings?: CrmSettings;
  crmTableSettings?: CrmTableSettings;
  billingSettings?: BillingSettings;
  fileSettings?: FileSettings;
  itemsCount?: ItemsCount;
  quickbooksSettings?: QuickbooksSettings;
  moduleSettings: ModuleSettingsItem[];
  minimumSeats?: number;
}

export interface ModuleV2State {
  enabled: boolean;
  order: number;
}

export interface MainSettingsChangeForm {
  allowSignup: boolean | ModuleV2State;
  messages: boolean | ModuleV2State;
  dashboard: boolean | ModuleV2State;
  files: boolean | ModuleV2State;
  payments: boolean | ModuleV2State;
  knowledgeBase: boolean | ModuleV2State;
  landings: boolean;
  signOutUrl: string;
  alwaysOnVisibility: boolean;
}

export interface LandingPageSettingsChangeForm {
  showBadge: boolean;
  allowCallScheduling?: boolean;
  callSchedulingLink: string;
  linkedInUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
}

export interface SettingsState {
  invoices: StripeInvoice[];
  prices: Stripe.Price[];
  loadError: string;
  loading: boolean;
  loaded: boolean;
  isUpdating: boolean;
  isUpdated: boolean;
  messageSettings?: MessageSettings;
  crmSettings?: CrmSettings;
  crmTableSettings?: CrmTableSettings;
  billingSettings?: BillingSettings;
  fileSettings?: FileSettings;
  moduleSettings: ModuleSettingsItem[] | undefined;
  itemsCount?: ItemsCount;
  quickbooksSettings?: QuickbooksSettings;
  extensionsSettings?: Prettify<ExtensionsSettings>;
  minimumSeats?: number;
  webhookConfigSets: WebhookConfigSet[];
  contractSettings?: ContractSettings;
}

export interface LoadSettingsStartAction {
  type: typeof LOAD_SETTINGS_START;
}

export interface LoadSettingsErrorAction {
  type: typeof LOAD_SETTINGS_ERROR;
  error: string;
}

export interface LoadSettingsSuccessAction {
  type: typeof LOAD_SETTINGS_SUCCESS;
  data: NewSettingsResponse;
}

export interface ClearSettingsAction {
  type: typeof CLEAR_SETTINGS;
}

export interface ClearSettingsChangesAction {
  type: typeof CLEAR_SETTINGS_CHANGES;
}

export interface UpdateSettingsSuccess {
  type: typeof UPDATE_SETTINGS_SUCCESS;
}

export interface UpdateSettingsFail {
  type: typeof UPDATE_SETTINGS_FAILURE;
  error: string;
}

export interface UpdateSettingsStart {
  type: typeof UPDATE_SETTINGS_REQUEST;
}

export interface UpdateCrmSettingsSuccessAction {
  type: typeof UPDATE_CRM_SETTINGS_SUCCESS;
  payload: CrmSettings;
}

export interface UpdateCrmSettingsFailAction {
  type: typeof UPDATE_CRM_SETTINGS_FAILURE;
  error: string;
}

export interface UpdateCrmTableSettingsSuccessAction {
  type: typeof UPDATE_CRM_TABLE_SETTINGS_SUCCESS;
  payload: any;
}

export interface UpdateCrmTableSettingsFailAction {
  type: typeof UPDATE_CRM_TABLE_SETTINGS_FAILURE;
  error: string;
}

export interface UpdateBillingSettingsSuccessAction {
  type: typeof UPDATE_BILLING_SETTINGS_SUCCESS;
  payload: BillingSettings;
}

export interface UpdateBillingSettingsFailAction {
  type: typeof UPDATE_BILLING_SETTINGS_FAILURE;
  error: string;
}

export interface UpdateModuleSettingsSuccessAction {
  type: typeof UPDATE_MODULE_SETTINGS_SUCCESS;
  payload: ModuleSettingsItem[];
}

export interface UpdateModuleSettingsFailAction {
  type: typeof UPDATE_MODULE_SETTINGS_FAILURE;
  error: string;
  payload: ModuleSettingsItem[];
}

export interface UpdateFileSettingsSuccessAction {
  type: typeof UPDATE_FILE_SETTINGS_SUCCESS;
  payload: FileSettings;
}

export interface UpdateFileSettingsFailAction {
  type: typeof UPDATE_FILE_SETTINGS_FAILURE;
  error: string;
}

export interface LoadPortalSettingsAction {
  type: typeof LOAD_PORTAL_SETTINGS;
  data: PortalSettings[];
}

export interface RemovePortalSettingsAction {
  type: typeof REMOVE_PORTAL_SETTINGS;
  data: PortalSettings[];
}

export interface LoadMessageSettingsAction {
  type: typeof LOAD_MESSAGE_SETTINGS;
  data: MessageSettings[];
}

export interface RemoveMessageSettingsAction {
  type: typeof REMOVE_MESSAGE_SETTINGS;
  data: MessageSettings[];
}

export interface AddWebhookConfigSetSuccessAction {
  type: typeof ADD_WEBHOOK_CONFIG_SET_SUCCESS;
  payload: WebhookConfigSet;
}

export interface GetWebhookConfigSetsSuccessAction {
  type: typeof GET_WEBHOOK_CONFIG_SETS_SUCCESS;
  payload: WebhookConfigSet[];
}

export interface DeleteWebhookConfigSetSuccessAction {
  type: typeof DELETE_WEBHOOK_CONFIG_SET_SUCCESS;
  payload: string;
}

export interface UpdateWebhookConfigSetSuccessAction {
  type: typeof UPDATE_WEBHOOK_CONFIG_SET_SUCCESS;
  payload: WebhookConfigSet;
}

export interface UpdateContractSettingsSuccessAction {
  type: typeof UPDATE_CONTRACT_SETTINGS_SUCCESS;
  payload: FileSettings;
}

export interface UpdateContractSettingsFailAction {
  type: typeof UPDATE_CONTRACT_SETTINGS_FAILURE;
  error: string;
}

export type SettingsActions =
  | LoadSettingsStartAction
  | LoadSettingsErrorAction
  | LoadSettingsSuccessAction
  | ClearSettingsAction
  | ClearSettingsChangesAction
  | UpdateSettingsSuccess
  | UpdateSettingsFail
  | UpdateSettingsStart
  | UpdateCrmSettingsSuccessAction
  | UpdateCrmSettingsFailAction
  | UpdateCrmTableSettingsSuccessAction
  | UpdateCrmTableSettingsFailAction
  | UpdateBillingSettingsSuccessAction
  | UpdateBillingSettingsFailAction
  | UpdateModuleSettingsSuccessAction
  | UpdateModuleSettingsFailAction
  | UpdateFileSettingsSuccessAction
  | UpdateFileSettingsFailAction
  | LoadPortalSettingsAction
  | RemovePortalSettingsAction
  | LoadMessageSettingsAction
  | RemoveMessageSettingsAction
  | AddWebhookConfigSetSuccessAction
  | GetWebhookConfigSetsSuccessAction
  | DeleteWebhookConfigSetSuccessAction
  | UpdateWebhookConfigSetSuccessAction
  | UpdateContractSettingsFailAction
  | UpdateContractSettingsSuccessAction;
