import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Event, ExtendableGenerics } from 'stream-chat';
import { RootState } from 'src/store';
import { setTotalUnreadMessages } from 'src/store/messages/actions';
import { NotificationBadge } from 'src/components/UI/Badges';
import { useChatClient } from 'src/components/Chat/useChatClient';
import { useGetNotificationsSettingsQuery } from 'src/services/api/notificationsSettingsApi';

export const MessagesNotificationBadge = () => {
  const dispatch = useDispatch();
  const chatClient = useChatClient();
  const { data: notificationSettings } = useGetNotificationsSettingsQuery();
  const disabledProductNotification = notificationSettings?.disableInProduct;
  const disableMessagesNotifications =
    notificationSettings?.notifyAbout.newMessages.disableInProduct;

  const totalUnreadMessages = useSelector(
    (state: RootState) => state.messages.totalUnreadMessages,
  );

  const clientEventHandler = (event: Event<ExtendableGenerics>) => {
    if (event.total_unread_count !== undefined) {
      dispatch(setTotalUnreadMessages(event.total_unread_count));
    }
  };

  useEffect(() => {
    if (chatClient && chatClient.user) {
      // subscribe to chat client events to update the unread messages count
      if (!disabledProductNotification || !disableMessagesNotifications) {
        chatClient.on(clientEventHandler);
      }

      // initializing the unread message redux state on sidebar mount from client user data
      if (
        !totalUnreadMessages &&
        totalUnreadMessages !== 0 &&
        (!disabledProductNotification || !disableMessagesNotifications)
      ) {
        dispatch(
          setTotalUnreadMessages(chatClient.user.total_unread_count as number),
        );
      }
    }
  }, [chatClient, disabledProductNotification, disableMessagesNotifications]);

  if (disabledProductNotification || disableMessagesNotifications) return null;

  return (
    <NotificationBadge
      htmlId="messages-notification-badge"
      isSidebar
      showZero={false}
      content={totalUnreadMessages || 0}
    />
  );
};
