import { last, initial } from 'lodash';
import { RowNode } from '@ag-grid-community/core';
import { AppThunkAction } from 'src/store/reduxTypes';
import {
  FilesActionTypes,
  AddFileChannelsActionType,
  RemoveFileChannelsActionType,
  FileResponse,
  FilesTableFormData,
  FileSaveModel,
  DownloadFileModel,
  FileFieldsUpdateSuccessPayload,
  FileChannel,
  UpdateFileChannelsActionType,
  AddFileChannelsActionOptions,
  UnselectFileChannelOptions,
  DeleteFileChannelActionType,
} from 'src/store/files/types';
import {
  clearAlertMessage,
  alertSnackbar,
  toggleSecondarySidebar,
} from 'src/store/ui/actions';
import { mapUserInstanceIntoUserEntityFields } from 'src/utils/UserUtils';
import ApiUtils from 'src/utils/ApiUtils';
import FileUtils, { pathMatch } from 'src/utils/FileUtils';
import { FILE_TYPE, FOLDER_FILE_KEY } from 'src/constants/fileConsts';
import FilesClient, {
  CreateChannelData,
  FileBundleData,
} from 'src/clients/FilesClient';
import { CallApiWithNotification } from 'src/clients/ApiService';
import { Client } from 'src/store/clients/types';
import { ensureError } from 'src/utils/Errors';

export const getFilesForChannel =
  (fileChannelID: string): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: FilesActionTypes.GET_FILES };
    }

    function success(res: FileResponse[]) {
      return { type: FilesActionTypes.GET_FILES_SUCCESS, payload: res };
    }

    function error(err: string) {
      return { type: FilesActionTypes.GET_FILES_ERROR, payload: err };
    }

    dispatch(request());

    try {
      const res = await FilesClient.GetFilesForChannelId(fileChannelID);
      dispatch(success(res));
    } catch (err) {
      const { message } = ensureError(err);
      dispatch(error(message));
    }
  };

export const saveUploadedFiles =
  (files: Array<FileSaveModel>, fileChannelID: string): AppThunkAction =>
  async (dispatch, getState) => {
    if (files.length === 0) {
      dispatch(
        alertSnackbar({
          errorMessage: 'Upload was not complete. Please refresh and try again',
        }),
      );
      return;
    }
    function request() {
      return { type: FilesActionTypes.SAVE_UPLOADED_FILES };
    }

    function success(res: FileResponse[]) {
      return {
        type: FilesActionTypes.SAVE_UPLOADED_FILES_SUCCESS,
        payload: res,
      };
    }

    function error(err: string) {
      return { type: FilesActionTypes.SAVE_UPLOADED_FILES_ERROR, payload: err };
    }

    dispatch(request());

    try {
      const res = await FilesClient.CreateFilesInChannel(fileChannelID, files);

      // res contains the result of a batch operation
      // so we check if the batch creation was a success and throw a error if not
      if (ApiUtils.IsBatchCreateResultSuccessful(res)) {
        const userInstance = getState().user.instance;
        const ownerStaged = mapUserInstanceIntoUserEntityFields(userInstance);
        const mapResponse = res.createdItems.map((file) => ({
          ...file,
          owner: {
            ...file.owner,
            fields: {
              ...ownerStaged,
            },
          },
        }));
        dispatch(success(mapResponse));
      } else {
        throw new Error('Failed to create file');
      }
    } catch (ex) {
      const err = ensureError(ex);
      dispatch(error(err.message));
      dispatch(
        alertSnackbar({
          errorMessage: err.message,
        }),
      );
    }
  };

export const filesDeletedAction = (fileIds: string[]) => ({
  type: FilesActionTypes.DELETE_FILES,
  payload: fileIds,
});

export const deleteFiles =
  (
    sourcePath: string,
    fileType: string,
    fileKey: string,
    entityName: string,
  ): AppThunkAction =>
  async (dispatch, getState) => {
    const { files } = getState();
    const { items } = files;

    const targetFiles = FileUtils.getAllFilesFromPath({
      files: items,
      sourcePath,
      fileType,
      fileKey,
    });

    const result = await CallApiWithNotification({
      executeFunction: FilesClient.deleteFiles,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: targetFiles,
      successMessage: `The ${entityName} has been deleted.`,
      errorMessage: `The ${entityName} could not be deleted.`,
      dispatch,
    });

    if (result.status) {
      dispatch(filesDeletedAction(result.data.succeededIds));
    }
  };

export const clearFiles = (): AppThunkAction => async (dispatch) => {
  dispatch({
    type: FilesActionTypes.CLEAR_FILES,
  });
};

/**
 * Revert the selection of a file channel and remove its files from state
 * @param options a collection of configurations to customize the behaviors desired by this action
 */
export const unselectFileChannel =
  (options?: UnselectFileChannelOptions): AppThunkAction =>
  async (dispatch) => {
    dispatch({
      type: FilesActionTypes.UNSELECT_CHANNEL,
    });
    if (options) {
      if (options.openSecondarySidebar) {
        dispatch(
          toggleSecondarySidebar({ isOpen: true, info: '', hidden: false }),
        );
      }
      if (options.navigationPath) {
        options.navigate(options.navigationPath);
      }
    }
  };

export const selectFileChannel = (channel?: FileChannel) => ({
  type: FilesActionTypes.SELECT_FILES_CHANNEL,
  payload: channel,
});

export const addFileChannelsAction = (
  channels: FileChannel[],
  addFileChannelOptions?: AddFileChannelsActionOptions,
): AddFileChannelsActionType => ({
  type: FilesActionTypes.ADD_FILE_CHANNELS,
  channels,
  addFileChannelOptions,
});

export const deleteFileChannelAction = (
  channelId: string,
): DeleteFileChannelActionType => ({
  type: FilesActionTypes.DELETE_FILE_CHANNEL,
  channelId,
});

export const updateFileChannelsAction = (
  updatedChannels: FileChannel[],
): UpdateFileChannelsActionType => ({
  type: FilesActionTypes.UPDATE_FILE_CHANNELS,
  updatedChannels,
});

export const removeFileChannelsAction = (
  channels: FileChannel[],
): RemoveFileChannelsActionType => ({
  type: FilesActionTypes.REMOVE_FILE_CHANNELS,
  channels,
});

export interface CreateFileChannelInput {
  companyID?: string;
  clientUserIDs?: string[];
  clients?: Client[];
}

/**
 * Create a file channel with given client users. If there is
 * a channel with matching members then it should be selected
 * otherwise new channel should be created
 * @param clientUserIds which client users are part of channel
 */
export const createFileChannel =
  (
    input: CreateFileChannelInput,
    setActiveChannel: (channelId: string) => void,
  ): AppThunkAction =>
  async (dispatch, getState) => {
    const { files } = getState();
    const { companyID, clientUserIDs } = input;

    let existingChannel: FileChannel | undefined;
    // params to be passed to the API request if existing channel is not found
    const params: CreateChannelData = {
      clientUserIDs: clientUserIDs || [],
      companyID: companyID || '',
    };
    if (companyID) {
      // if a companyID is passed into the create channel function check for a company channel matching this company
      // if the existing company check fails the API will not be able to create the channel and user will get an error
      existingChannel = files.channels.find((chan) =>
        chan.id.endsWith(companyID),
      );
    } else if (clientUserIDs) {
      const clientsList = (input.clients || []).map((c) => c.id);
      // if every given client userId is included in a non-company channel then this channel already exists
      existingChannel = files.channels.find(
        (chan) =>
          !chan.fields.companyID &&
          clientUserIDs.every(
            (clientUserId) => chan.additionalFields?.memberIDs[clientUserId],
          ) &&
          Object.keys(chan.additionalFields?.memberIDs || {})
            .filter((memberID) => clientsList.includes(memberID))
            .every((clientMemberID) => clientUserIDs.includes(clientMemberID)),
      );
      if (clientUserIDs.length === 1) {
        params.singleClientId = clientUserIDs.at(0);
      }
    }

    if (!existingChannel) {
      const result = await CallApiWithNotification({
        executeFunction: FilesClient.createChannel,
        checkFunction: ApiUtils.IsBatchCreateResultSuccessful,
        params,
        successMessage: `Channel created.`,
        errorMessage: `There was an problem creating this channel.`,
        dispatch,
      });

      if (result.status) {
        dispatch(
          addFileChannelsAction(result.data.createdItems, {
            selectFirstChannel: true,
          }),
        );
        setActiveChannel(result.data.createdItems[0].id);
      } else {
        console.error('Unsuccessful file channel create result', result);
      }
    } else {
      // select the channel that was found
      dispatch(selectFileChannel(existingChannel));
    }
  };

/**
 * Deletes file channel
 * @param channelId channel id which needs to be deleted
 */
export const deleteFileChannel =
  (channelId: string): AppThunkAction =>
  async (dispatch, getState) => {
    const { channels } = getState().files;
    console.info('Deleting file channel with id', channelId);

    const result = await CallApiWithNotification({
      executeFunction: FilesClient.deleteFileChannel,
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: channelId,
      successMessage: `Channel deleted`,
      errorMessage: `There was a problem deleting this channel.`,
      dispatch,
    });

    if (result.status && result.data.succeededIds.length > 0) {
      dispatch(deleteFileChannelAction(result.data.succeededIds[0]));
      // after deleting a channel, select the next channel in the list
      const nextChannel = channels
        .filter((channel) => channel.id !== channelId)
        .at(0);
      dispatch(selectFileChannel(nextChannel));
    } else {
      console.error('Unsuccessful file channel create result', result);
    }
  };

export const downloadZip =
  (
    fileData: FileBundleData[],
    bundleName: string,
  ): AppThunkAction<Promise<string>> =>
  async (dispatch) => {
    try {
      const result = await CallApiWithNotification<string>({
        executeFunction: FilesClient.getZippedBundle,
        params: {
          fileData,
          bundleName,
        },
        successMessage: '',
        errorMessage: `Your download failed. Please try again or contact support.`,
        dispatch,
      });

      if (result.status) {
        return result.data;
      }
    } catch (error) {
      console.error(`Failed to create zip file on fileData: ${fileData}`);
    }
    return '';
  };

export const downloadFiles =
  (files: DownloadFileModel[]): AppThunkAction =>
  async (dispatch) => {
    function startDownLoadFile() {
      return {
        type: FilesActionTypes.DOWNLOAD_FILE,
      };
    }

    function downloadFileSuccess() {
      return {
        type: FilesActionTypes.DOWNLOAD_FILE_SUCCESS,
      };
    }

    function downloadFileFailed(error: string) {
      return { type: FilesActionTypes.DOWNLOAD_FILE_ERROR, payload: error };
    }

    dispatch(clearAlertMessage());
    dispatch(startDownLoadFile());

    try {
      await Promise.all(
        files.map(async (fileData) => {
          const { fileUrl, fileKey, fileName } = fileData;
          return FileUtils.downloadFileFromUrl(fileUrl, fileKey, fileName);
        }),
      );

      dispatch(downloadFileSuccess());
    } catch (e) {
      const { message } = ensureError(e);
      dispatch(downloadFileFailed(message));
      dispatch(
        alertSnackbar({
          errorMessage: `The file could not be downloaded.`,
        }),
      );
    }
  };

export const filesUpdatedAction =
  (
    successIds: string[],
    updatedFiles: Array<FileFieldsUpdateSuccessPayload>,
  ): AppThunkAction =>
  async (dispatch, getState) => {
    const { files } = getState();
    const { items } = files;
    const currentURL = new URL(window.location.href);
    const selectedFilePath = currentURL.searchParams.get('selectedFilePath');
    if (selectedFilePath) {
      updatedFiles.forEach((updatedFile) => {
        if (updatedFile.fields.fileKey === FOLDER_FILE_KEY) {
          // if one of the updated files is a folder, we want to check if a user is currently viewing that folder
          const oldFolder = items.find((item) => item.id === updatedFile.id);
          if (oldFolder?.fields.path === selectedFilePath) {
            // if the updated folder's path matches what the user is viewing now, we want to navigate them to the new path to represent that folder
            currentURL.searchParams.set(
              'selectedFilePath',
              updatedFile.fields.path,
            );
            window.location.href = currentURL.toString();
          }
        }
      });
    }

    return dispatch({
      type: FilesActionTypes.UPDATE_FILE_FIELDS_SUCCESS,
      successIds,
      updatedFiles,
    });
  };

export const renameFile =
  (fileData: FilesTableFormData, existingPaths: string[]): AppThunkAction =>
  async (dispatch, getState) => {
    function request() {
      return { type: FilesActionTypes.UPDATE_FILE_FIELDS_REQUEST };
    }

    function failure(error: string) {
      return { type: FilesActionTypes.UPDATE_FILE_FIELDS_FAILURE, error };
    }

    dispatch(request());

    const { files } = getState();
    const { items } = files;
    const originalPath = fileData.path.join('/');
    const updatedPath = initial(fileData.path)
      .concat([fileData.fields.fileName || ''])
      .join('/');
    const updatedPathParts = updatedPath.split('/');
    if (
      fileData.fileKey === FOLDER_FILE_KEY &&
      existingPaths.some(
        (i) => i === updatedPathParts[updatedPathParts.length - 1],
      )
    ) {
      const errorMessage = 'A folder with this name already exists.';
      dispatch(
        alertSnackbar({
          errorMessage,
        }),
      );
      dispatch(failure(errorMessage));
      return;
    }

    const targetFiles = FileUtils.getAllFilesFromPath({
      files: items,
      sourcePath: fileData.filePath.join('/'),
      fileType: fileData.type,
      fileKey: fileData.fileKey,
    });
    const updatedFiles = targetFiles.map((targetFile) => ({
      id: targetFile.id,
      fields: {
        ...targetFile.fields,
        fileName:
          fileData.type === FILE_TYPE.FILE
            ? fileData.fields.fileName || ''
            : targetFile.fields.fileName || '',
        fileKey: targetFile.fields.fileKey,
        starred: false,
        fileUrl: fileData.fileUrl,
        path:
          fileData.fileKey === FOLDER_FILE_KEY
            ? updatedPath +
              (targetFile.fields.path
                ? targetFile.fields.path.slice(originalPath.length)
                : '')
            : targetFile.fields.path,
      },
    }));

    const result = await CallApiWithNotification({
      executeFunction: FilesClient.updateFiles(),
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: updatedFiles,
      successMessage: `File has been renamed.`,
      errorMessage: `This file could not be renamed.`,
      dispatch,
    });

    if (result.status) {
      dispatch(filesUpdatedAction(result.data.succeededIds, updatedFiles));
    } else {
      dispatch(failure(result.data));
    }
  };

/**
 * determine the path to set on a file object during a folder move event
 * @param srcPath points to root path where files are being moved from
 * @param filePath path of the file, which has srcPath as prefix but can be longer
 * @param tgtPath ponts to the directory where files should be moved
 */
const getModifiedTargetPath = (
  srcPath: string,
  filePath: string,
  tgtPath: string,
) => {
  if (pathMatch(srcPath, tgtPath)) {
    // if files are moved to some higher node in the same tree, we need to slice out the "intermediary" portion
    // for example, if we are moving items with path "A/B/C" to "A", we need to cut out "/B" for each of them
    const sourceFolderName: string = last(srcPath.split('/')) || '';
    const deletionPath: string = filePath.slice(
      tgtPath.length,
      srcPath.length - sourceFolderName.length,
    );
    return `${tgtPath ? `${tgtPath}/` : ''}${filePath.slice(
      tgtPath.length + deletionPath.length,
    )}`;
  }
  // there may be an intermediary path to delete on the filepath when moving folders to other folders that are not in the same "tree"
  const deletionPath: string = srcPath.split('/').slice(0, -1).join('/');
  return `${tgtPath}${deletionPath.length > 0 ? '' : '/'}${filePath.slice(
    deletionPath.length,
  )}`
    .replace(/\/$/, '')
    .replace(/^\//, '');
};

export const moveFolders =
  (
    targetPath: string,
    sourcePath: string,
    fileType: string,
    fileKey: string,
    entityName: string,
  ): AppThunkAction =>
  async (dispatch, getState) => {
    function request() {
      return { type: FilesActionTypes.MOVE_FOLDER_REQUEST };
    }

    function success(updatedFiles: FileResponse[]) {
      return {
        type: FilesActionTypes.MOVE_FOLDER_SUCCESS,
        payload: updatedFiles,
      };
    }

    function failure(error: string) {
      return { type: FilesActionTypes.MOVE_FOLDER_FAILURE, error };
    }

    dispatch(request());

    const { files } = getState();
    const { items } = files;

    const movedFiles = FileUtils.getAllFilesFromPath({
      files: items,
      sourcePath,
      fileType,
      fileKey,
    });

    const updatedFiles = movedFiles.map((movedFile) => ({
      ...movedFile,
      fields: {
        ...movedFile.fields,
        path:
          fileType === FILE_TYPE.FILE
            ? targetPath
            : getModifiedTargetPath(
                sourcePath,
                movedFile?.fields?.path || '',
                targetPath,
              ),
      },
    }));

    const result = await CallApiWithNotification({
      executeFunction: FilesClient.updateFiles(true),
      checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
      params: updatedFiles,
      successMessage: `The ${entityName} has been moved.`,
      errorMessage: `This ${entityName} could not be moved.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(updatedFiles));
    } else {
      dispatch(failure(result.data));
    }
  };

export const setFileChannelsAction = (channels: FileChannel[]) => ({
  type: FilesActionTypes.SET_FILE_CHANNELS,
  channels,
});

export const insertFiles =
  (files: FileResponse[]): AppThunkAction =>
  (dispatch, getState) => {
    const { selectedChannel, items: currentFiles } = getState().files;

    if (!selectedChannel) return;
    const { id: selectedChannelId } = selectedChannel;
    const { ownerId: filesOwnerId } = files.at(0) ?? {};

    // todo: use sets once the get files api is updated to use models,
    // currently the list files endpoint returns identityId for the files which
    // is not included in the create file response (which uses models)
    const idsPresent = currentFiles.map((file) => file.id);

    const newFiles = files.filter(
      // check for duplicates and add only the files that are not present
      (file) => idsPresent.indexOf(file.id) === -1,
    );

    // dispatch action to update files if the new files are belong to the currently selected channel
    if (selectedChannelId === filesOwnerId) {
      dispatch({
        type: FilesActionTypes.INSERT_FILES,
        payload: newFiles,
      });
    }
  };

export const updateFileDragging =
  (newState: boolean): AppThunkAction =>
  (dispatch) => {
    dispatch({
      type: FilesActionTypes.UPDATE_FILE_DRAGGING,
      payload: newState,
    });
  };

export const updateHoveredRowNode =
  (rowNode: RowNode | null): AppThunkAction =>
  (dispatch) => {
    dispatch({
      type: FilesActionTypes.UPDATE_HOVERED_ROW_NODE,
      payload: rowNode,
    });
  };

/**
 * Action to set the file menu action state which will open
 * trigger an event in the files table
 * @param newState what is the new file action to perform
 * @returns
 */
export const updateFilesHelperAction =
  (newState: string | null): AppThunkAction =>
  (dispatch) => {
    dispatch({
      type: FilesActionTypes.UPDATE_FILES_HELPER_ACTION,
      payload: newState,
    });
  };

/**
 * Action to update file channel members
 * @param fileChannel
 * @param updatedMemberIds
 * @returns
 */
export const updateFileChannelMembers =
  (fileChannel: FileChannel, updatedMemberIds: string[]): AppThunkAction =>
  async (dispatch) => {
    const memberRecord: Record<string, boolean> = {};
    updatedMemberIds.forEach((id) => {
      memberRecord[id] = true;
    });

    const updatedChannel: FileChannel = {
      ...fileChannel,
      additionalFields: {
        ...fileChannel.additionalFields,
        memberIDs: memberRecord,
      },
    };

    const result = await CallApiWithNotification({
      executeFunction: FilesClient.updateFileChannel,
      params: updatedChannel,
      successMessage: `Group members updated`,
      errorMessage: `Group members could not be updated.`,
      dispatch,
    });

    if (result.status) {
      dispatch(updateFileChannelsAction([result.data]));
    }
  };
