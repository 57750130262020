import React from 'react';
import { Theme, Menu, createStyles, makeStyles } from '@material-ui/core';
import { ColDef } from '@ag-grid-community/core';
import { SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import * as Colors from 'src/theme/colors';
import { SortableTableColumnList } from 'src/components/TableSettings/SortableTableColumnList';
import { TablePropertiesContext } from 'src/context/tableSettingsContext';
import TablePropertiesClient from 'src/clients/TablePropertiesClient';
import { FieldVisibility, TablePropertyFields } from 'src/constants/dataTypes';

interface TableColumnSettingsProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  columnDefs: Array<ColDef>;
  tableEntityType: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverRoot: {
      border: `1px solid ${Colors.DividersAndCardBorders}`,
      width: 282,
      marginTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
    },
  }),
);

export const TableColumnSettings: React.FC<TableColumnSettingsProps> = ({
  open,
  anchorEl,
  onClose,
  columnDefs,
  tableEntityType,
}) => {
  const classes = useStyles();
  const {
    setColumnSettings,
    columnSettingsEntityId,
    setColumnSettingsEntityId,
  } = React.useContext(TablePropertiesContext);

  const handleUpdateTableProperties = async (
    updatedProperties: TablePropertyFields,
  ) => {
    const result = await TablePropertiesClient.updateTableProperties(
      {
        id: columnSettingsEntityId,
        structFields: updatedProperties,
      },
      tableEntityType,
    );
    setColumnSettingsEntityId(result.id);
  };

  /**
   * Determine the correct order number property after sort for each column and trigger dispatch to persist them
   * @param swap SortEnd object containing indices that were changed in sortable container
   * */
  const handleSettingSwap = (swap: SortEnd) => {
    if (swap.newIndex === swap.oldIndex) return;

    // reorder the original column definitions according to the new sort action where indices have changed
    const reorderedColumnSettings = arrayMove(
      [...columnDefs],
      swap.oldIndex,
      swap.newIndex,
    );

    const updatedTableSettings: TablePropertyFields = {
      tablePropertyFields: new Array<FieldVisibility>(columnDefs.length),
    };

    // assign numeric order property based on the column's position in the rearranged array
    reorderedColumnSettings.forEach((col, idx) => {
      if (col.field !== undefined) {
        updatedTableSettings.tablePropertyFields[idx] = {
          disabled: Boolean(col.hide),
          name: col.headerName || '',
          id: col.field,
        };
      }
    });

    // optimistic update of column settings
    setColumnSettings(updatedTableSettings);
    handleUpdateTableProperties(updatedTableSettings);
  };

  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      classes={{
        paper: classes.popoverRoot,
      }}
    >
      <SortableTableColumnList
        columnDefs={columnDefs}
        onSortEnd={handleSettingSwap}
        tableEntityType={tableEntityType}
      />
    </Menu>
  );
};
