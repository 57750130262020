import React from 'react';
import { deleteCompany, updateCompanyAction } from 'src/store/clients/actions';
import { Company, CompanyFormData } from 'src/store/clients/types';
import { useEditCompanyForm } from 'src/components/CrmTable';
import { NoCompanyIcon } from 'src/components/Icons';
import { AlertModal, FormModal } from 'src/components/Modals';
import { SelectDropDown } from 'src/components/Select/SelectDropdown';
import { SelectOption } from 'src/components/Select/types';
import { FormBaseEntity } from 'src/constants/fieldsTypes';
import * as Colors from 'src/theme/colors';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';

interface ClientDetailsCompanySelectionProps {
  onChange: (
    ev: React.ChangeEvent<Record<string, unknown>>,
    newValue: SelectOption | SelectOption[] | null,
  ) => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  currentCompanyId: string;
  hasCompanyAssigned: boolean;
}
interface EditCompanyFields {
  id: string;
  name: string;
  avatarImageURL: string;
  fallbackColor: string;
}
interface EditCompanyModalType {
  open: boolean;
  fields: EditCompanyFields;
}

const REMOVE_COMPANY_OPTION: SelectOption = {
  id: 'no-option-item',
  label: 'Remove company',
};

export const ClientDetailsCompanySelection: React.FC<
  ClientDetailsCompanySelectionProps
> = ({ onChange, anchorEl, onClose, currentCompanyId, hasCompanyAssigned }) => {
  const dispatch = useAppDispatch();
  const [deletingCompany, setDeletingCompany] = React.useState<Company | null>(
    null,
  );
  const companies = useAppSelector((state) => state.clients.activeCompanies);

  const authenticatedUserID = useAppSelector(
    (state) => state.user.data?.userId,
  );
  const [options, setOptions] = React.useState<SelectOption[]>([]);

  const editCompanyModalInitialState = {
    open: false,
    fields: {
      id: '',
      name: '',
      avatarImageURL: '',
      fallbackColor: '',
    },
  };
  const [editCompanyModal, setEditCompanyModal] =
    React.useState<EditCompanyModalType>(editCompanyModalInitialState);
  React.useEffect(() => {
    const filteredCompanies = companies.filter((c) => !c.fields.isPlaceholder);
    const companiesStaged: Array<SelectOption> = filteredCompanies
      .map((company) => {
        const { name, avatarImageURL, fallbackColor } = company.fields;
        return {
          label: name || '',
          avatarUrl: avatarImageURL || '',
          type: 'company',
          id: company.id,
          fallbackColor: fallbackColor || '',
        };
      })
      .sort((a: SelectOption, b: SelectOption) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

    const noCompanyOption = hasCompanyAssigned ? [REMOVE_COMPANY_OPTION] : [];

    setOptions([...noCompanyOption, ...companiesStaged]);
  }, [companies, authenticatedUserID]);

  const handleCompanyDelete = () => {
    if (!deletingCompany) return;
    dispatch(deleteCompany(deletingCompany));
    setDeletingCompany(null);
    // close dropdown after remove company
    onClose();
  };

  const handleEditCompany = (event: React.MouseEvent<HTMLElement>) => {
    const companyId = event.currentTarget.id;
    const selectedCompany = companies.find((c) => c.id === companyId);
    if (selectedCompany) {
      setEditCompanyModal({
        open: true,
        fields: {
          name: selectedCompany.fields.name,
          id: selectedCompany.id,
          avatarImageURL: selectedCompany.fields.avatarImageURL || '',
          fallbackColor: selectedCompany.fields.fallbackColor || '',
        },
      });
    }
  };

  /**
   * When edit company modal save button is clicked
   * this method is called to save the new company data.
   * @param values: edit company form values
   */
  const handleSaveCompany = async (values: FormBaseEntity) => {
    const editCompanyData: EditCompanyFields = values.fields;
    const currentCompany = companies.find((c) => c.id === editCompanyData.id);
    if (!currentCompany) return;
    const data: CompanyFormData = {
      companyName: editCompanyData.name,
      companyId: editCompanyData.id,
      avatarImageURL: editCompanyData.avatarImageURL,
    };
    if (data.companyName && data.companyName !== '') {
      // if a companyName was set, we want to flip the isPlaceholder value on this company since a named company is not a placeholder company
      data.isPlaceholder = false;
    }
    dispatch(
      updateCompanyAction(
        data,
        `Company was updated successfully.`,
        `Company was not updated.`,
        true,
      ),
    );
    // close dropdown after edit company
    onClose();
  };

  /**
   * When delete company menu action is clicked, it takes the
   * target id which represent the option id (and more specifically
   * the company id) and fetch the company to be deleted.
   */
  const handleDeleteCompany = (event: React.MouseEvent<HTMLElement>) => {
    const companyId = event.currentTarget.id;
    const company = companies.find((comp) => comp.id === companyId);
    if (!company) return;
    setDeletingCompany(company);
  };

  const handleCloseEditCompanyModal = () => {
    setEditCompanyModal(editCompanyModalInitialState);
  };

  return (
    <>
      <SelectDropDown
        creatable
        optionActions={[
          { name: 'Edit', onClick: handleEditCompany },
          {
            name: 'Delete',
            isDelete: true,
            onClick: handleDeleteCompany,
          },
        ]}
        value={options.find((o) => o.id === currentCompanyId)}
        isMultiple={false}
        placeholder="Reassign or add a company..."
        onChange={onChange}
        onClose={onClose}
        options={options}
        defaultOptionIcon={
          <NoCompanyIcon style={{ color: Colors.LightGray }} />
        }
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
      />
      <AlertModal
        successButtonLabel="Delete"
        cancelButtonLabel="Cancel"
        title="Are you sure you want to delete this company?"
        onSuccess={handleCompanyDelete}
        noCancelButton={false}
        onClose={() => setDeletingCompany(null)}
        open={Boolean(deletingCompany)}
      >
        All clients that have this company assigned will no longer be associated
        with any company.
      </AlertModal>
      <FormModal
        key="edit-company-modal"
        initialFormValue={editCompanyModal}
        title="Company details"
        actionLabel="Save changes"
        handleSave={handleSaveCompany}
        onClose={handleCloseEditCompanyModal}
        useFormHook={useEditCompanyForm}
        open={editCompanyModal.open}
      />
    </>
  );
};
