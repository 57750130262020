import React, { useContext, useMemo, useState } from 'react';
import {
  Button,
  Theme,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Layout, { PageLayout } from 'src/components/Layout';
import { BaseTabsHeader } from 'src/components/UI/BaseTab';
import { RouteContext } from 'src/context';
import history from 'src/history';
import { TableToolbar } from 'src/components/UI/Pages/TableToolbar';
import { PageToolbar, PageToolbarAction } from 'src/components/UI';
import { ensureUnreachable } from 'src/utils/CommonUtils';
import { SubmissionAndRequestTable } from 'src/components/Contracts/SubmissionAndRequestTable';
import { TableSearch } from 'src/components/SearchInput/Searchbox';
import { useGetContractTemplatesQuery } from 'src/services/api';
import { ShareContractModal } from 'src/components/Contracts/ShareContractModal';
import { CONTRACTS_PAGE, CONTRACT_DETAILS_PAGE } from 'src/constants';
import { BreadCrumbBase } from 'src/components/UI/BreadCrumbBase';
import { ContractBuilderAndSignerForm } from './ContractBuilderAndSignerForm';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { openAuthModal } from 'src/store/ui/actions';
import { AUTH_STATES } from 'src/constants/authConsts';
import { RootState } from 'src/store';
import { NonDesktopActionTooltip } from 'src/components/Tooltip';

export enum ContractDetailsPageView {
  Edit = 'edit',
  SubmissionRequests = 'submissions_requests',
}

export type ContractDetailsPageQueryParams = {
  view: ContractDetailsPageView;
  templateId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumbWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: 130,
    },
  },
}));

export const ContractDetailsPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { query } = useContext(RouteContext);
  const isFormPageChanged = useSelector(
    (state: RootState) => state.ui.isFormPageChanged,
  );

  const { view = ContractDetailsPageView.SubmissionRequests, templateId } =
    query as ContractDetailsPageQueryParams;

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

  const { data: templates } = useGetContractTemplatesQuery();

  const selectedTemplate = templates?.find((t) => t.id === templateId);

  const handleTabChange = (key: ContractDetailsPageView) => {
    // If we make any change to edit contract form
    // we need to confirm the user before navigating away
    if (isFormPageChanged) {
      dispatch(
        openAuthModal(
          AUTH_STATES.CONFIRM_PAGE_CHANGE_MODAL,
          `${CONTRACT_DETAILS_PAGE.path}?templateId=${templateId}&view=${key}`,
        ),
      );
      return;
    }
    history.replace({ search: `templateId=${templateId}&view=${key}` });
  };
  const { pageTitle } = usePageTitle(CONTRACTS_PAGE.label, 'contracts');

  const renderActiveTab = () => {
    switch (view) {
      case ContractDetailsPageView.SubmissionRequests:
        return (
          <SubmissionAndRequestTable selectedTemplate={selectedTemplate} />
        );
      case ContractDetailsPageView.Edit:
        return <ContractBuilderAndSignerForm />;
      default:
        return ensureUnreachable(view);
    }
  };

  const breadcrumbItems = useMemo(() => {
    return [
      {
        label: pageTitle,
        path: CONTRACTS_PAGE.path,
      },
      {
        label: selectedTemplate?.name || '',
      },
    ];
  }, [selectedTemplate]);

  const pageTabHeaders: Array<{
    key: ContractDetailsPageView;
    label: React.ReactNode;
  }> = useMemo(
    () => [
      {
        key: ContractDetailsPageView.SubmissionRequests,
        label: 'Submissions and requests',
      },
      {
        key: ContractDetailsPageView.Edit,
        label: <NonDesktopActionTooltip>Edit template</NonDesktopActionTooltip>,
        disabled: !isDesktopScreen, // we don't allow edit template on small screens
      },
    ],
    [isDesktopScreen],
  );

  return (
    <Layout
      headerComponent={
        <TableToolbar>
          <PageToolbar
            title={
              <div className={classes.breadcrumbWrapper}>
                <BreadCrumbBase items={breadcrumbItems} showOnlyTitleOnMobile />
              </div>
            }
            actionButtons={[
              <PageToolbarAction
                actions={[]}
                createButton={
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsShareModalOpen(true)}
                  >
                    Share contract
                  </Button>
                }
              >
                <TableSearch />
              </PageToolbarAction>,
            ]}
          />
        </TableToolbar>
      }
    >
      <PageLayout routeAccessCheck="allowContractsPage" noSpace>
        <BaseTabsHeader
          tabs={pageTabHeaders}
          activeTabKey={view}
          onTabChange={(_, key) =>
            handleTabChange(key as ContractDetailsPageView)
          }
        />
        {renderActiveTab()}
        <ShareContractModal
          open={isShareModalOpen}
          selectedTemplate={selectedTemplate}
          onClose={() => setIsShareModalOpen(false)}
        />
      </PageLayout>
    </Layout>
  );
};
