import React from 'react';
import { CssBaseline, StylesProvider } from '@material-ui/core';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { SnackbarProvider } from 'notistack';
import { Provider as ReduxProvider } from 'react-redux';
import {
  RouteContext,
  PortalConfigContext,
  SessionData,
  FlagsContext,
  FlagContextType,
} from 'src/context';
import { ConfigureAuth } from 'src/components/Auth/ConfigureAuth';

interface UnauthAppProps {
  context: {
    insertCss: (...styles: any[]) => () => void;
    pathname: string;
    query: Record<string, any>;
    isMobile: boolean;
    portalConfig: any;
    sessionData: SessionData;
    store: any;
    flags: FlagContextType;
  };
  children: React.ReactNode;
}

const UnauthApp: React.FC<UnauthAppProps> = ({ context, children }) => {
  // NOTE: If you need to add or modify header, footer etc. of the app,
  // please do that inside the Layout component.
  const {
    insertCss,
    pathname,
    query,
    isMobile,
    portalConfig,
    sessionData,
    store,
    flags,
  } = context;

  const routeContext = React.useMemo(
    () => ({ pathname, query, isMobile, sessionData }),
    [pathname, query, isMobile, sessionData],
  );

  const styleContextValue = React.useMemo(() => ({ insertCss }), [insertCss]);

  return (
    <FlagsContext.Provider value={flags}>
      <PortalConfigContext.Provider value={portalConfig}>
        <StyleContext.Provider value={styleContextValue}>
          <StylesProvider>
            <ReduxProvider store={store}>
              <RouteContext.Provider value={routeContext}>
                <CssBaseline />
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  maxSnack={2}
                >
                  <ConfigureAuth />
                  {React.Children.only(children)}
                </SnackbarProvider>
              </RouteContext.Provider>
            </ReduxProvider>
          </StylesProvider>
        </StyleContext.Provider>
      </PortalConfigContext.Provider>
    </FlagsContext.Provider>
  );
};

export default UnauthApp;
