import React, { ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import { PopoverOrigin } from '@material-ui/core';
import { sortBy } from 'lodash';
import { RootState } from 'src/store';
import { User, UserRoles } from 'src/constants';
import { AssigneeDropDownOptions, Company } from 'src/store/clients/types';
import { SelectDropDown } from 'src/components/Select/SelectDropdown';

interface AssigneeDropDownProps {
  anchorEl: HTMLElement | null;
  anchorOrigin?: PopoverOrigin;
  assignees?: Array<User | Company>;
  assigneeDropDownId?: string;
  disableCloseOnSelect: boolean;
  isMultiple: boolean;
  lead?: User;
  leadUserId?: string;
  noOptionsText?: string;
  onClose: ComponentProps<typeof SelectDropDown>['onClose'];
  onChange: (
    val: Parameters<ComponentProps<typeof SelectDropDown>['onChange']>[1],
  ) => void;
  open: boolean;
  placeholder: string;
  width?: string | number;
}

export const AssigneeDropDown: React.FC<AssigneeDropDownProps> = ({
  assigneeDropDownId,
  open,
  anchorEl,
  onClose,
  noOptionsText = 'No options',
  placeholder = '',
  isMultiple = false,
  lead,
  assignees,
  leadUserId,
  onChange,
  width: menuWidth,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  ...autocompleteOptions
}) => {
  const internalUsers = useSelector(
    (state: RootState) => state.users.internalUsers,
  );

  const [pendingValue, setPendingValue] = React.useState<any>([]);
  const [options, setOptions] = React.useState<AssigneeDropDownOptions[]>([]);

  const isOptionDisabled = (option: AssigneeDropDownOptions) => {
    if (option.id === leadUserId || option.role === UserRoles.InternalAdmin) {
      return true;
    }

    return false;
  };

  React.useEffect(() => {
    if (internalUsers) {
      const filteredInternals = internalUsers.filter(
        (u) => u.entityStatus !== 'ARCHIVED',
      );
      const internalUsersStaged: Array<AssigneeDropDownOptions> =
        filteredInternals.map((internalUser) => {
          const {
            givenName,
            familyName,
            avatarImageUrl,
            fallbackColor,
            roles,
          } = internalUser.fields;
          return {
            label: `${givenName} ${familyName}`,
            avatarUrl: avatarImageUrl,
            id: internalUser.id,
            fallbackColor,
            role: roles && roles.length > 0 ? roles.at(0) : '',
          };
        });

      const sortedInternalOptions = sortBy(internalUsersStaged, 'label');
      setOptions(sortedInternalOptions);
    }
  }, [internalUsers]);

  React.useEffect(() => {
    if (isMultiple && assignees) {
      const assigneesIds = assignees.map((assignee) => assignee.id);
      const assigneesIdsSet = new Set(assigneesIds);
      const pendingValueStaged = options.filter((option) =>
        assigneesIdsSet.has(option.id),
      );
      setPendingValue(pendingValueStaged);
    } else if (!isMultiple && lead) {
      const pendingValueStaged = options.find((user) => user.id === lead.id);
      setPendingValue(pendingValueStaged);
    }
  }, [isMultiple, lead, assignees, options]);

  return (
    <SelectDropDown
      width={menuWidth}
      isMultiple={isMultiple}
      id={assigneeDropDownId}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      onClose={(event, reason) => {
        event.preventDefault();
        onClose(event, reason);
      }}
      value={isMultiple ? pendingValue : ''}
      onChange={(_, newValue) => {
        if (newValue) {
          onChange(newValue);
          setPendingValue(newValue);
        }
      }}
      getOptionDisabled={(option: AssigneeDropDownOptions) =>
        isMultiple && isOptionDisabled(option)
      }
      noOptionsText={noOptionsText}
      options={options}
      placeholder={placeholder}
      {...autocompleteOptions}
    />
  );
};
