import React from 'react';
import { Button } from '@material-ui/core';
import {
  FiEdit as EditIcon,
  FiNavigation as NavigationIcon,
  FiDownload as DownloadIcon,
  FiCheckCircle as MarkPaidIcon,
  FiCopy as CopyIcon,
} from 'react-icons/fi';
import { TrashIcon } from 'src/components/Icons';
import { InvoiceStatus } from 'src/constants';
import { Action, BaseActionsMenuButton } from 'src/components/Dropdowns';
import { InvoiceTableRowModel } from 'src/store/payments/types';
import { BaseActionRenderProps } from 'src/components/AgGrid/Renderer/types';
import { ensureUnreachable } from 'src/utils/CommonUtils';

export type PaymentActionHandler = (data: InvoiceTableRowModel) => void;

export interface PaymentActionRenderProps extends BaseActionRenderProps {
  data: InvoiceTableRowModel;
  isClient: boolean;
  handleClickEdit: PaymentActionHandler;
  handleClickDuplicateInvoice: PaymentActionHandler;
  handleClickPay: PaymentActionHandler;
  handleClickDownloadInvoice: PaymentActionHandler;
  handleClickDownloadAttachment: PaymentActionHandler;
  handleClickDelete: PaymentActionHandler;
  handleClickVoid: PaymentActionHandler;
  handleClickSend: PaymentActionHandler;
  handleClickMarkPaid: PaymentActionHandler;
  handleClickCopyPaymentLink: PaymentActionHandler;
  handleMarkInvoiceDeleted: PaymentActionHandler;
}

function getActionsForInvoiceStatus(
  status: InvoiceStatus,
  data: InvoiceTableRowModel,
  handleClickSend: PaymentActionHandler,
  handleClickMarkPaid: PaymentActionHandler,
  handleClickEdit: PaymentActionHandler,
  handleClickDelete: PaymentActionHandler,
  handleClickDownloadInvoice: PaymentActionHandler,
  handleClickVoid: PaymentActionHandler,
  handleClickCopyPaymentLink: PaymentActionHandler,
  handleClickDuplicateInvoice: PaymentActionHandler,
  handleClickDownloadAttachment: PaymentActionHandler,
  handleMarkInvoiceDeleted: PaymentActionHandler,
  isClient: boolean,
): Action[] {
  const markPaidAction = {
    name: 'Mark paid',
    onClick: () => handleClickMarkPaid(data),
    icon: <MarkPaidIcon />,
  };

  const downloadAction = {
    name: 'Download invoice',
    onClick: () => handleClickDownloadInvoice(data),
    icon: <DownloadIcon />,
  };

  const duplicateAction: Action = {
    name: 'Duplicate invoice',
    onClick: () => handleClickDuplicateInvoice(data),
    icon: <CopyIcon />,
    isDisabled: data.client !== null && data.client.entityStatus === 'DELETED',
  };

  const deleteInvoiceAction = {
    name: 'Delete invoice',
    onClick: () => handleMarkInvoiceDeleted(data),
    icon: <TrashIcon />,
    isDelete: true,
  };

  const shouldAllowAttachmentDownload =
    data && data.invoiceFullData && data.invoiceFullData.fields.attachment;

  const baseAttachmentDownloadAction: Action[] = shouldAllowAttachmentDownload
    ? [
        {
          name: 'Download attachment',
          onClick: () => handleClickDownloadAttachment(data),
          icon: <DownloadIcon />,
        },
      ]
    : [];

  const shouldAddDuplicateAction =
    !isClient && !data?.invoiceFullData?.additionalFields?.subscription;

  const baseDuplicateAction: Action[] = shouldAddDuplicateAction
    ? [duplicateAction]
    : [];

  const baseActions = [
    ...baseDuplicateAction,
    downloadAction,
    ...baseAttachmentDownloadAction,
  ];

  switch (status) {
    case InvoiceStatus.OPEN: {
      return [
        ...baseDuplicateAction,
        markPaidAction,
        downloadAction,
        {
          name: 'Copy payment link',
          onClick: () => handleClickCopyPaymentLink(data),
          icon: <CopyIcon />,
        },
        ...baseAttachmentDownloadAction,
        {
          name: 'Void invoice',
          onClick: () => handleClickVoid(data),
          icon: <TrashIcon />,
          isDelete: true,
        },
      ];
    }
    case InvoiceStatus.PAID: {
      return baseActions;
    }
    case InvoiceStatus.VOID: {
      return !isClient ? [...baseActions, deleteInvoiceAction] : baseActions;
    }
    case InvoiceStatus.UNCOLLECTIBLE: {
      return baseActions;
    }
    case InvoiceStatus.DRAFT: {
      return isClient
        ? [
            {
              name: 'Send invoice',
              onClick: () => handleClickSend(data),
              icon: <NavigationIcon />,
            },
          ]
        : [
            {
              name: 'Send invoice',
              onClick: () => handleClickSend(data),
              icon: <NavigationIcon />,
            },
            markPaidAction,
            {
              name: 'Edit invoice',
              onClick: () => handleClickEdit(data),
              icon: <EditIcon />,
            },
            duplicateAction,
            {
              name: 'Delete invoice',
              onClick: () => handleClickDelete(data),
              icon: <TrashIcon />,
              isDelete: true,
            },
          ];
    }

    default:
      return ensureUnreachable(status);
  }
}

const PaymentActionRenderer = ({
  data,
  isClient,
  handleClickEdit,
  handleClickPay,
  handleClickDuplicateInvoice,
  handleClickDownloadInvoice,
  handleClickDownloadAttachment,
  handleClickDelete,
  handleClickVoid,
  handleClickSend,
  handleClickMarkPaid,
  handleClickCopyPaymentLink,
  handleMarkInvoiceDeleted,
  onActionMenuClick,
}: PaymentActionRenderProps) => {
  const { status } = data;
  const actions: Action[] = getActionsForInvoiceStatus(
    status,
    data,
    handleClickSend,
    handleClickMarkPaid,
    handleClickEdit,
    handleClickDelete,
    handleClickDownloadInvoice,
    handleClickVoid,
    handleClickCopyPaymentLink,
    handleClickDuplicateInvoice,
    handleClickDownloadAttachment,
    handleMarkInvoiceDeleted,
    isClient,
  );

  return data.status === InvoiceStatus.OPEN && isClient ? (
    <Button
      color="primary"
      variant="contained"
      onClick={() => handleClickPay(data)}
    >
      Pay
    </Button>
  ) : (
    <BaseActionsMenuButton
      actions={actions}
      onActionMenuClick={onActionMenuClick}
    />
  );
};

export default PaymentActionRenderer;
