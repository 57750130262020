import { RowNode, ICellRendererParams } from '@ag-grid-community/core';
import { BaseEntity, FileSettings } from 'src/constants/dataTypes';
import { UserProfile } from 'src/utils/UserUtils';
import { FileNotification } from 'src/store/notifications/types';
import {
  SignaturePageComponent,
  SignaturePageImageStatus,
  SignatureEvent,
} from 'src/store/signaturePage/types';
import { FileBundleData } from 'src/clients/FilesClient';
import { FILE_TYPE } from 'src/constants';

export enum FilesActionTypes {
  GET_FILES = 'GET_FILES',
  GET_FILES_SUCCESS = 'GET_FILES_SUCCESS',
  GET_FILES_ERROR = 'GET_FILES_ERROR',
  SAVE_UPLOADED_FILES = 'SAVE_UPLOADED_FILES',
  SAVE_UPLOADED_FILES_SUCCESS = 'SAVE_UPLOADED_FILES_SUCCESS',
  SAVE_UPLOADED_FILES_ERROR = 'SAVE_UPLOADED_FILES_ERROR',
  SELECT_FILES_CHANNEL = 'SELECT_FILES_CHANNEL',
  DELETE_FILES = 'DELETE_FILES',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR',
  CLEAR_FILES = 'CLEAR_FILES',
  UPDATE_FILE_FIELDS_REQUEST = 'UPDATE_FILE_FIELDS_REQUEST',
  UPDATE_FILE_FIELDS_SUCCESS = 'UPDATE_FILE_FIELDS_SUCCESS',
  UPDATE_FILE_FIELDS_FAILURE = 'UPDATE_FILE_FIELDS_FAILURE',
  MOVE_FOLDER_REQUEST = 'MOVE_FOLDER_REQUEST',
  MOVE_FOLDER_SUCCESS = 'MOVE_FOLDER_SUCCESS',
  MOVE_FOLDER_FAILURE = 'MOVE_FOLDER_FAILURE',
  SET_FILE_CHANNELS = 'SET_FILE_CHANNELS',
  ADD_FILE_CHANNELS = 'ADD_FILE_CHANNELS',
  REMOVE_FILE_CHANNELS = 'REMOVE_FILE_CHANNELS',
  INSERT_FILES = 'INSERT_FILES',
  UPDATE_FILES_CHANNELS_DATA = 'UPDATE_FILES_CHANNELS_DATA',
  UPDATE_FILE_CHANNELS = 'UPDATE_FILE_CHANNELS',
  CANCEL_ESIGNATURE_REQUEST_SUCCESS = 'CANCEL_ESIGNATURE_REQUEST_SUCCESS',
  UPDATE_FILE_DRAGGING = 'UPDATE_FILE_DRAGGING',
  UPDATE_HOVERED_ROW_NODE = 'UPDATE_HOVERED_ROW_NODE',
  UPDATE_FILES_HELPER_ACTION = 'UPDATE_FILES_HELPER_ACTION',
  DELETE_FILE_CHANNEL = 'DELETE_FILE_CHANNEL',
  UNSELECT_CHANNEL = 'UNSELECT_CHANNEL',
}

export interface FileQueryParamProps {
  channelId?: string;
  selectedFilePath?: string;
  clientUserId?: string;
}

export interface FileCellRendererProps extends ICellRendererParams {
  channelId?: string;
  selectedFilePath?: string;
  isClient: boolean;
  fileSettings?: FileSettings;
}

export interface AddFileChannelsActionOptions {
  selectFirstChannel: boolean;
}

export interface UnselectFileChannelOptions {
  navigationPath: string;
  navigate: (path: string) => void;
  openSecondarySidebar: boolean;
}

export enum ClientTypes {
  CLIENT_COMPANY = 'CLIENT_COMPANY',
  CLIENT_USER = 'CLIENT_USER',
  OWNER = 'owner',
}

export interface SelectedClient {
  clientType:
    | ClientTypes.CLIENT_COMPANY
    | ClientTypes.CLIENT_USER
    | ClientTypes.OWNER
    | ''
    | undefined;
  clientId: string;
}

export interface FileChannelFields {
  name?: string;
  userId?: string;
  companyID?: string;
  lastNotification?: FileNotification;
  channelType?: 'multi';
  singleClientId?: string;
  filesCount?: number;
}

export interface FilesTableActionInput {
  actionType: string;
  fileData: FilesTableData;
  fileLinkURLs?: string[];
  fileBundleData?: FileBundleData[];
  bundleName?: string;
}

export interface FileChannelAdditionalFields {
  memberIDs: Record<string, boolean>;
}

export interface FileChannel extends BaseEntity {
  fields: FileChannelFields;
  additionalFields?: FileChannelAdditionalFields;
}

export type SignatureStatusTypes =
  | 'pending'
  | 'open'
  | 'sent'
  | 'signed'
  | 'done';
export interface Fields {
  fileKey: string;
  starred: boolean;
  tags?: Array<string>;
  fileName?: string;
  path?: string;
  fileUrl?: string;
  status?: string;
  pageImageKeys?: Array<string>;
  pageImageStatus: SignaturePageImageStatus;
  esignStatus?: SignatureStatusTypes;
  components?: Array<SignaturePageComponent>;
  signatureEvents?: Array<SignatureEvent>;
  lastEsigModifierIdentityID?: string;
}

export interface FileResponse extends BaseEntity {
  fileUrl: string;
  fields: Fields;
}

export interface CreateFilesResponse {
  createdItems: Array<FileResponse>;
  failedIds: Array<string>;
  succeededIds: Array<string>;
  unauthorizedIds: Array<string>;
}

export interface DownloadFileModel {
  fileUrl: string;
  fileKey: string;
  fileName: string;
}

export interface FileUploadResponse {
  key: string;
}

export interface FileSaveModel {
  key: string;
  path: string;
  url?: string;
}

export interface FileFieldsUpdateSuccessPayload {
  id: string;
  fields: {
    fileName: string;
    fileKey: string;
    starred: boolean;
    path: string;
    esignStatus?: string;
    lastEsigModifierIdentityID?: string;
    components?: Array<SignaturePageComponent>;
  };
}

export interface FilesState {
  items: FileResponse[];
  selectedChannel?: FileChannel;
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
  downloading: boolean;
  error?: string;
  renaming?: boolean;
  moving: boolean;
  channels: FileChannel[];
  channelsLoaded: boolean;
  fileDragging: boolean;
  hoveredRowNode: RowNode | null;
  helperActions: Record<any, string | null>;
}

export interface FilesTableData {
  creator: UserProfile;
  fileId: string;
  channelId?: string;
  fileKey: string;
  fileName: string;
  identityId: string;
  createdDate: string;
  path: string[];
  filePath: string[];
  fileUrl: string;
  type: ValueOf<typeof FILE_TYPE>;
  pageImageKeys: string[] | undefined;
  signatureStatus: SignatureStatusTypes | undefined;
  lastEsigModifierIdentityID: string;
}

export interface FilesTableFormData extends FilesTableData {
  fields: Partial<FilesTableData>;
}

export interface FolderForm {
  name: string;
}

interface GetFiles {
  type: typeof FilesActionTypes.GET_FILES;
}

interface GetFilesSuccess {
  type: typeof FilesActionTypes.GET_FILES_SUCCESS;
  payload: FileResponse[];
}

interface GetFilesError {
  type: typeof FilesActionTypes.GET_FILES_ERROR;
  error: string;
}

interface SendFiles {
  type: typeof FilesActionTypes.SAVE_UPLOADED_FILES;
}

interface SendFilesSuccess {
  type: typeof FilesActionTypes.SAVE_UPLOADED_FILES_SUCCESS;
  payload: Array<FileResponse>;
}

interface SendFilesError {
  type: typeof FilesActionTypes.SAVE_UPLOADED_FILES_ERROR;
  error: string;
}

interface SelectChannel {
  type: typeof FilesActionTypes.SELECT_FILES_CHANNEL;
  payload?: FileChannel;
}

interface DeleteFiles {
  type: typeof FilesActionTypes.DELETE_FILES;
  payload: string[];
}

interface ClearFiles {
  type: typeof FilesActionTypes.CLEAR_FILES;
}

interface DownloadFile {
  type: typeof FilesActionTypes.DOWNLOAD_FILE;
}

interface DownloadFileSuccess {
  type: typeof FilesActionTypes.DOWNLOAD_FILE_SUCCESS;
}

interface DownloadFileError {
  type: typeof FilesActionTypes.DOWNLOAD_FILE_ERROR;
  error: string;
}

interface UpdateFileFields {
  type: typeof FilesActionTypes.UPDATE_FILE_FIELDS_REQUEST;
}

interface UpdateFileFieldsSuccess {
  type: typeof FilesActionTypes.UPDATE_FILE_FIELDS_SUCCESS;
  successIds: string[];
  updatedFiles: Array<FileFieldsUpdateSuccessPayload>;
}

interface UpdateFileFieldsError {
  type: typeof FilesActionTypes.UPDATE_FILE_FIELDS_FAILURE;
  error: string;
}

interface MoveFolderStart {
  type: typeof FilesActionTypes.MOVE_FOLDER_REQUEST;
}

interface MoveFolderSuccess {
  type: typeof FilesActionTypes.MOVE_FOLDER_SUCCESS;
  payload: FileResponse[];
}

interface MoveFolderError {
  type: typeof FilesActionTypes.MOVE_FOLDER_FAILURE;
  error: string;
}

interface SetFileChannelsActionType {
  type: typeof FilesActionTypes.SET_FILE_CHANNELS;
  channels: FileChannel[];
}

export interface AddFileChannelsActionType {
  type: typeof FilesActionTypes.ADD_FILE_CHANNELS;
  channels: FileChannel[];
  addFileChannelOptions?: AddFileChannelsActionOptions;
}

export interface DeleteFileChannelActionType {
  type: typeof FilesActionTypes.DELETE_FILE_CHANNEL;
  channelId: string;
}

export interface UpdateFileChannelsActionType {
  type: typeof FilesActionTypes.UPDATE_FILE_CHANNELS;
  updatedChannels: FileChannel[];
}

export interface RemoveFileChannelsActionType {
  type: typeof FilesActionTypes.REMOVE_FILE_CHANNELS;
  channels: FileChannel[];
}

export interface CancelESignatureRequestSuccess {
  type: typeof FilesActionTypes.CANCEL_ESIGNATURE_REQUEST_SUCCESS;
  payload: FileResponse;
}

export interface UnselectChannel {
  type: typeof FilesActionTypes.UNSELECT_CHANNEL;
}

interface InsertFilesActionType {
  type: typeof FilesActionTypes.INSERT_FILES;
  payload: FileResponse[];
}
interface UpdateFileDraggingActionType {
  type: typeof FilesActionTypes.UPDATE_FILE_DRAGGING;
  payload: boolean;
}
interface UpdateHoveredRowNodeActionType {
  type: typeof FilesActionTypes.UPDATE_HOVERED_ROW_NODE;
  payload: RowNode;
}

interface UpdateFilesHelperAction {
  type: typeof FilesActionTypes.UPDATE_FILES_HELPER_ACTION;
  payload: string | null;
}

export type FilesActions =
  | GetFiles
  | GetFilesSuccess
  | GetFilesError
  | SendFiles
  | SendFilesSuccess
  | SendFilesError
  | SelectChannel
  | DeleteFiles
  | ClearFiles
  | DownloadFile
  | DownloadFileSuccess
  | DownloadFileError
  | UpdateFileFields
  | UpdateFileFieldsSuccess
  | UpdateFileFieldsError
  | MoveFolderStart
  | MoveFolderSuccess
  | MoveFolderError
  | SetFileChannelsActionType
  | AddFileChannelsActionType
  | RemoveFileChannelsActionType
  | InsertFilesActionType
  | UpdateFileChannelsActionType
  | CancelESignatureRequestSuccess
  | UpdateFileDraggingActionType
  | UpdateHoveredRowNodeActionType
  | UpdateFilesHelperAction
  | DeleteFileChannelActionType
  | UnselectChannel;
