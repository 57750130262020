export const baseMarketingDomain = 'copilot.com';

export const supportURL = `https://support.${baseMarketingDomain}`;

export const docsURL = `https://docs.${baseMarketingDomain}`;

export const PORTAL_DOMAINS = {
  copilot: 'https://copilot.com',
};

export const ClientImportHelpCenterLink = `${supportURL}/hc/en-us/articles/11762246509211`;
