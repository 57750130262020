import React, { useContext, useMemo } from 'react';
import { FlagsContext } from 'src/context';
import { useGetInstallsQuery } from 'src/services/api/applicationsApi';
import {
  ContentfulApp,
  ContentfulAppType,
  useGetContentfulAppsQuery,
} from 'src/services/api/contentfulApi';

/**
 * This hook is used to manage the apps return from the Contentful CMS
 * @returns all apps, client facing apps, internal facing apps, core modules
 */
export const useContentfulApps = (
  {
    includeInstalledApps,
  }: {
    includeInstalledApps: boolean;
  } = { includeInstalledApps: false },
) => {
  const { data: apps, isSuccess: areContentfullAppsLoaded } =
    useGetContentfulAppsQuery();
  const { EnableAlphaApps, EnableBetaApps } = useContext(FlagsContext);
  const { data: { extensionsSettings, moduleSettings } = {} } =
    useGetInstallsQuery();
  const installationsSet = useMemo<Set<string>>(
    () =>
      new Set(
        (moduleSettings ?? [])
          .map(({ id }) => {
            const ext = extensionsSettings?.[id];
            return ext?.appId;
          })
          .filter(Boolean),
      ),
    [extensionsSettings],
  );

  const isCoreModule = (a: ContentfulApp) => a.copilotAppId && !a.isPlatformApp;
  let allApps = apps;

  const excludes: ('Dogfood' | 'Launchpad' | undefined)[] = [];
  if (!EnableAlphaApps) excludes.push('Dogfood');
  if (!EnableBetaApps) excludes.push('Launchpad');
  if (excludes.length) {
    allApps = allApps?.filter((app) => !excludes.includes(app.releaseSegment));
  }
  if (!includeInstalledApps) {
    allApps = allApps?.filter((app) => !installationsSet.has(app.copilotAppId));
  }

  // client facing apps except core modules
  const clientApps: Prettify<ContentfulApp>[] = React.useMemo(() => {
    if (!allApps) return [];
    return allApps
      .filter(
        (a) => a.appsType === ContentfulAppType.Client && !isCoreModule(a),
      )
      .sort((a, b) => {
        // Sort by isFeatured key, with true values first
        if (a.isFeatured && !b.isFeatured) return -1;
        if (!a.isFeatured && b.isFeatured) return 1;
        return 0;
      });
  }, [allApps]);

  const internalApps: Prettify<ContentfulApp>[] = React.useMemo(() => {
    if (!allApps) return [];
    return allApps.filter((a) => a.appsType === ContentfulAppType.Internal);
  }, [allApps]);

  // default core modules
  const coreModules = React.useMemo(() => {
    if (!allApps) return [];
    return allApps.filter((a) => isCoreModule(a));
  }, [allApps]);

  /**
   *  Method to get the single app info using app name
   * @param appName title of the app
   */
  const getAppInfo = (appName: string) =>
    clientApps.find((a) => a.name === appName) ||
    internalApps.find((a) => a.name === appName);

  /**
   *  Method to get the single core module info using app id
   * @param appId id of the app
   */
  const getCoreApp = (appId: string) =>
    coreModules.find((a) => a.copilotAppId === appId);

  /**
   *  Method to get single client app info using app id
   * @param appId id of the app
   */
  const getClientApp = (appId: string) => {
    return clientApps.find((a) => a.copilotAppId === appId);
  };

  const getAppBySlug = (slug: string) => {
    return (allApps ?? []).find((a) => a.slug === slug);
  };

  return {
    areContentfullAppsLoaded,
    allApps,
    clientApps,
    coreModules,
    internalApps,
    getAppInfo,
    getCoreApp,
    getClientApp,
    getAppBySlug,
  };
};
