import { Events } from 'src/constants/webSocketConsts/events';
import { handleSignOut } from 'src/utils/UserUtils';
import { PortalConfig } from 'src/context';
import {
  handleDeletedClients,
  handleInsertedClients,
  handleModifiedClients,
} from 'src/store/clients/actions';
import { updateUsers } from 'src/store/users/actions';
import { AppViewMode } from 'src/constants';
import { ensureUnreachable } from 'src/utils/CommonUtils';

const ClientHandler = (
  event: Events,
  items: Array<any>,
  portalConfig: PortalConfig,
  userID: string,
) => {
  switch (event) {
    case Events.Insert:
      return handleInsertedClients(items, true);
    case Events.Modify: {
      // if we are rendering for a client experience
      // then the data to be updated is in the users slice
      if (portalConfig.viewMode === AppViewMode.CLIENT) {
        return updateUsers(items);
      }

      // for internal users, we call the action from the client slice
      return handleModifiedClients(items, true);
    }
    case Events.Remove: {
      // eslint-disable-next-line react/destructuring-assignment
      const filteredItems = items.filter((item) => item.id === userID);
      if (filteredItems && filteredItems.length > 0) {
        handleSignOut(
          portalConfig,
          Boolean(filteredItems[0].fields.isTestUser),
        );
      }
      return handleDeletedClients(items, true);
    }
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default ClientHandler;
