import React, { useContext } from 'react';
import { TrashIcon } from 'src/components/Icons';
import { BaseActionsMenuButton, Action } from 'src/components/Dropdowns';
import { BaseActionRenderProps } from 'src/components/AgGrid/Renderer/types';
import { RouteContext } from 'src/context';
import { useGetPricesByProductIdQuery } from 'src/services/api/productsApi';

export interface ProductPriceTableRowModal {
  amount: number;
  currency: string;
  type: string;
  intervalCount: number;
  interval: string;
  status: string;
  createdAt: string;
}

export interface ProductPriceActionsRendererProps
  extends BaseActionRenderProps {
  data: ProductPriceTableRowModal;
  handleClickRemove: (data: ProductPriceTableRowModal) => void;
  entityName?: string;
}

export const PriceActionRenderer = ({
  data,
  handleClickRemove,
  onActionMenuClick,
}: ProductPriceActionsRendererProps) => {
  const { pathname } = useContext(RouteContext);
  const productId = pathname.split('/').pop();

  const { data: prices = [] } = useGetPricesByProductIdQuery(productId!, {
    skip: !productId,
  });
  const actions: Action[] = [
    {
      name: `Delete  price`,
      onClick: () => handleClickRemove(data),
      icon: <TrashIcon />,
      isDelete: true,
      isDisabled: prices?.length === 1,
    },
  ];

  return (
    <BaseActionsMenuButton
      onActionMenuClick={onActionMenuClick}
      actions={actions}
    />
  );
};
