import React, { ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from 'src/store';
import BaseChannelSidebar from 'src/components/Channels/BaseChannelSidebar';
import { AvatarInfoType } from 'src/components/Channels/ChannelSidebarItem';
import { NotificationGroups } from 'src/store/notifications/types';
import { InboxPageContext } from 'src/context/inboxPageContext';
import { useGetInboxNotificationsQuery } from 'src/services/api/inboxApi';
import { getNotificationReadStatus } from 'src/utils/NotificationsUtils';
import { useChannelNavigationShortcut } from '../Channels/useChannelNavigation';
import { LegacyInboxSidebarItem } from './LegacyInboxSidebarItem';

export enum InboxNotificationStatus {
  Read = 'read',
  Sent = 'sent',
}
export interface InboxSidebarItemData {
  id: string;
  label: string;
  description: string;
  notificationStatus: InboxNotificationStatus;
  avatarInfo: (AvatarInfoType & { email: string }) | null;
  notificationGroup: NotificationGroups;
  channelId: string;
  data: string;
}

export const LegacyInboxSidebar = () => {
  const { data: inboxNotifications } = useGetInboxNotificationsQuery();

  const secondarySidebarHidden = useSelector(
    (state: RootState) => state.ui.secondarySideBar.hidden,
  );
  const searchKey = useSelector((state: RootState) => state.ui.searchValue);
  const { setSelectedNotification, selectedNotification } =
    React.useContext(InboxPageContext);
  const clients = useSelector((state: RootState) => state.clients.clients);
  const internalUsers = useSelector(
    (state: RootState) => state.users.internalUsers,
  );
  const companies = useSelector((state: RootState) => state.clients.companies);

  // all users ( internal + client )
  const allUsers = [...clients, ...internalUsers];

  // on click we want to set secondary sidebar info as notification id
  const onSelectChannel: ComponentProps<
    typeof BaseChannelSidebar
  >['onSelectChannel'] = (sidebarItemOption) => {
    setSelectedNotification(sidebarItemOption);
  };

  React.useEffect(
    () => () => {
      // reset inbox sidebar
      setSelectedNotification(null);
    },
    [],
  );

  const formattedNotificationsList = React.useMemo(() => {
    if (!inboxNotifications) return [];

    const notifications: InboxSidebarItemData[] = inboxNotifications
      .slice() // creating copy of array
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ) // latest notification first
      .map((notification) => {
        const { fields } = notification;

        const notificationGroup =
          fields?.groupingEntityType || fields?.notificationGroup || '';

        const sender = allUsers.find((c) => c.id === fields.senderUserId);

        // notification sender name
        // incase user is deleted show sender name as 'Deleted User'
        const senderName = sender
          ? `${sender.fields.givenName} ${sender.fields.familyName}`
          : 'Deleted User';

        let label = '';

        // generate notification label
        // based on the notification group
        switch (notificationGroup) {
          case 'NOTIFY_FILE_UPLOADED': {
            const companyId = sender?.fields.companyId;
            const senderCompanyName =
              companyId &&
              companies.find((c) => c.id === companyId)?.fields.name;
            // if sender belongs to a company add company name in notification label
            if (senderCompanyName) {
              label = `${senderName} from ${senderCompanyName}`;
            } else {
              label = `${senderName}`;
            }
            // 'file' for count = 1, 'files' for count > 1
            label = `${label} uploaded ${fields.count} file${
              fields.count && fields.count > 1 ? 's' : ''
            }`;
            break;
          }

          case 'esigRequestCompleteNotifications': {
            label = `${
              fields?.itemName || 'File'
            } has been signed by all parties`;
            break;
          }

          default:
            label = '';
        }

        const notificationStatus = getNotificationReadStatus(notification);

        const description = moment(notification.createdAt).fromNow();

        const avatarInfo = {
          name: senderName || '',
          imageUrl: sender?.fields ? sender?.fields.avatarImageUrl : '',
          fallbackColor: sender?.fields ? sender.fields.fallbackColor : '',
          email: sender?.fields ? sender.fields.email : '',
        };

        return {
          id: notification.id,
          label,
          notificationStatus,
          description,
          avatarInfo,
          notificationGroup,
          channelId: fields.channelId,
          data: notification.data,
        };
      });

    return notifications;
  }, [inboxNotifications]);

  const deSelectNotification = () => {
    setSelectedNotification(null);
  };

  const filteredNotificationList = React.useMemo(() => {
    const notificationList = [...formattedNotificationsList];
    // if search value is empty then return all the notifications available in the list
    if (!searchKey) return notificationList;

    const queriedNotifications = notificationList.filter((n) =>
      n.label.toLowerCase().includes(searchKey.toLowerCase()),
    );

    // if the selected notification is not included in the queried result
    // de select the notification
    if (selectedNotification) {
      const selectedNotificationExist = queriedNotifications.find(
        (n) => n.id === selectedNotification.id,
      );
      if (!selectedNotificationExist) {
        deSelectNotification();
      }
    }
    // return only those notifications that meet our search criteria
    return queriedNotifications;
  }, [searchKey, formattedNotificationsList, selectedNotification]);

  // handle channel notification shortcuts for inbox list
  useChannelNavigationShortcut(
    filteredNotificationList,
    filteredNotificationList.findIndex(
      (notification) => notification.id === selectedNotification?.id,
    ),
    onSelectChannel,
  );

  if (secondarySidebarHidden) return null;

  return (
    <BaseChannelSidebar
      title="Files Notifications"
      entity="inbox"
      sidebarItemOptions={filteredNotificationList}
      onSelectChannel={onSelectChannel}
      itemRenderer={LegacyInboxSidebarItem}
      noCreateAction
      shouldSetQueryParam={false}
      disableDefaultSelect
    />
  );
};
