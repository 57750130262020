import * as React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import CopyToClipboard from 'src/components/CopyToClipboard/CopyToClipboard';
import { CopyIcon } from 'src/components/Icons';
import { BlackHeadings } from 'src/theme/colors';

interface StyleProps {
  spacing: string;
}

interface CopyTextProps {
  value: string;
  iconPosition?: 'start' | 'end';
  spacing?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: (props: StyleProps) => props.spacing,
  },
  copyIcon: {
    fontSize: 12,
    color: BlackHeadings,
    '& path': {
      strokeWidth: 1.75,
    },
  },
}));

/**
 * Render prop component that allows for copying value
 * and child component will be rendered adjust to copy icon
 */
export const CopyText: React.FC<CopyTextProps> = ({
  value,
  iconPosition = 'start',
  spacing = 'flex-start',
  children,
}) => {
  const classes = useStyles({ spacing });

  const handleCopyClicked = (
    ev: React.MouseEvent<HTMLButtonElement>,
    copyFunction: (content: any) => void,
  ) => {
    ev.stopPropagation();
    copyFunction(value);
  };
  return (
    <div className={classes.root}>
      {iconPosition === 'end' && children}
      <CopyToClipboard>
        {({ copy }) => (
          <IconButton
            onClick={(ev) => handleCopyClicked(ev, copy)}
            size="small"
          >
            <CopyIcon className={classes.copyIcon} />
          </IconButton>
        )}
      </CopyToClipboard>
      {iconPosition === 'start' && children}
    </div>
  );
};
