import {
  BillingIcon,
  DashboardIcon,
  ChatBubble,
  BookOpenIcon,
  FolderFillIcon,
  FormsFilledIcon,
  IconNameToComponent,
  IconType,
  ContractIcon,
} from 'src/components/Icons';
import { ModuleSettingsItem } from 'src/constants/dataTypes';
import { useGetInstallsQuery } from 'src/services/api/applicationsApi';

type CoreModule =
  | 'payments'
  | 'forms'
  | 'knowledgeBase'
  | 'files'
  | 'messages'
  | 'contracts';
const emptyModules: ModuleSettingsItem[] = [];

export const useModuleIcon = (moduleName: CoreModule) => {
  const { data: settings } = useGetInstallsQuery();
  const modules = settings?.moduleSettings || emptyModules;
  const module = modules.find((m) => m.id === moduleName);

  let Icon = IconNameToComponent[module?.icon as IconType];
  if (!Icon) {
    switch (moduleName) {
      case 'payments':
        Icon = BillingIcon;
        break;
      case 'forms':
        Icon = FormsFilledIcon;
        break;
      case 'knowledgeBase':
        Icon = BookOpenIcon;
        break;
      case 'files':
        Icon = FolderFillIcon;
        break;
      case 'messages':
        Icon = ChatBubble;
        break;
      case 'contracts':
        Icon = ContractIcon;
        break;
      default:
        Icon = DashboardIcon;
    }
  }

  return Icon;
};
