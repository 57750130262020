export const DEFAULT_FILTER_RESULTS_LIMIT = 5;

export const COMPANY_TYPE = 'company';
export const TEAM_TYPE = 'team';
export const CLIENT_TYPE = 'client';

export const GROUPBY_INFO = [
  { type: COMPANY_TYPE, groupLabel: 'Client Companies' },
  { type: TEAM_TYPE, groupLabel: 'Team' },
  { type: CLIENT_TYPE, groupLabel: 'Clients' },
];
export const GROUP_OPTION_MAPPER = {
  company: 'Recent companies',
  client: 'Recent clients',
  title: '',
  'no-option': '',
  text: '',
};
export type GroupType = 'company' | 'team' | 'client';
