import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Popover,
  Checkbox,
  PopoverOrigin,
} from '@material-ui/core';
import Autocomplete, {
  AutocompleteRenderOptionState,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { UserAvatar } from 'src/components/User';
import * as Colors from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';
import { BaseMenuInput } from 'src/components/Dropdowns/BaseMenuInput';
import {
  Action,
  BaseActionsMenuButton,
  BaseMenu,
} from 'src/components/Dropdowns';
import { BaseActionsMenuProps } from 'src/constants';
import { PlusIcon } from 'src/components/Icons';
import {
  SelectOption,
  SelectOptionWithAvatar,
  SelectDropdownValueType,
} from 'src/components/Select/types';
import { DropdownMenuShadow } from 'src/theme/shadows';

const filter = createFilterOptions<SelectOption>();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverRoot: {
      border: `1px solid ${Colors.DividersAndCardBorders}`,
      boxShadow: DropdownMenuShadow,
    },
    paper: {
      margin: 0,
      borderRadius: 0,
      '& > ul': {
        padding: 0,
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    option: {
      minHeight: 'auto',
      alignItems: 'center',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
        1,
      )}px ${theme.spacing(1.5)}px`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: Colors.HoverBackground,
      },
      '&[aria-disabled="true"]': {
        opacity: 1,
        backgroundColor: 'transparent',
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
    iconSelected: {
      fontSize: 12,
      marginRight: theme.spacing(1.5),
      color: theme.palette.primary.main,
    },
    checkbox: {
      padding: `0 ${theme.spacing(1.5)}px 0 0`,
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      },
      '&.Mui-disabled': {
        color: Colors.NonHoverBorder,
      },
    },
    listHeaderTitle: {
      marginLeft: theme.spacing(1.5),
      marginTop: theme.spacing(0.75),
      marginBottom: theme.spacing(0.25),
      color: Colors.LightGray,
    },
    avatarWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 20,
      marginRight: theme.spacing(1.5),
    },
  }),
);

type PopoverCloseReason = 'backdropClick' | 'escapeKeyDown' | 'toggleInput';
interface SelectDropDownProps {
  id?: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: (
    event: React.ChangeEvent<Record<string, unknown>>,
    reason: PopoverCloseReason,
  ) => void;
  noOptionsText?: string;
  placeholder: string;
  isMultiple: boolean;
  onChange: (
    ev: React.ChangeEvent<Record<string, unknown>>,
    newValue: SelectDropdownValueType,
  ) => void;
  width?: string | number;
  anchorOrigin?: PopoverOrigin;
  value?: SelectDropdownValueType;
  options: SelectOption[];
  getOptionDisabled?: (option: SelectOption) => boolean;
  optionActions?: Action[] | null;
  defaultOptionIcon?: JSX.Element | null;
  creatable?: boolean;
  listHeaderTitle?: string;
  // since multi select custom field component has a different
  // way to render option items (tags) inside dropdown, we need
  // to override the autocomplete option renderer function
  // to match the desired ui.
  customOptionRenderer?: (
    option: SelectOption,
    optionState: AutocompleteRenderOptionState,
  ) => void;

  inputRenderer?: (params: any) => React.ReactNode;
  transformOrigin?: PopoverOrigin;
  popoverClassname?: string;
}

export const SelectDropDown: React.FC<SelectDropDownProps> = ({
  id,
  open,
  anchorEl,
  onClose,
  noOptionsText = 'No options',
  placeholder = '',
  isMultiple = false,
  value,
  onChange,
  width: menuWidth,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  getOptionDisabled,
  options,
  optionActions,
  creatable = false,
  defaultOptionIcon,
  listHeaderTitle,
  customOptionRenderer,
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  inputRenderer,
  popoverClassname,
  ...autocompleteOptions
}) => {
  const classes = useStyles();
  const [optionsActionMenuProps, setOptionsActionMenuProps] =
    React.useState<BaseActionsMenuProps | null>(null);

  const handleOpenActionsMenu = (
    menuOptions: BaseActionsMenuProps,
    optionId: string,
  ) => {
    setOptionsActionMenuProps({
      ...menuOptions,
      menuProps: {
        open: true,
        id: optionId,
        ...menuOptions.menuProps,
        onClose: () => setOptionsActionMenuProps(null),
        anchorOrigin: {
          vertical: 30,
          horizontal: 30,
        },
      },
    });
  };

  /**
   * This is the default option renderer
   * which render option items with avatar icons.
   * @param option
   * @param param1
   * @returns
   */
  const defaultOptionRenderer = (
    option: SelectOptionWithAvatar,
    { selected }: AutocompleteRenderOptionState,
  ) =>
    option.id === 'add-new-option-item' ? (
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <PlusIcon style={{ fontSize: 9 }} />
        <BaseTypography style={{ marginLeft: 8 }}>
          Create {option.label}
        </BaseTypography>
      </Box>
    ) : (
      <>
        {isMultiple && (
          <Checkbox
            checked={selected}
            name="selectAssignee"
            color="primary"
            disabled={getOptionDisabled && getOptionDisabled(option)}
            classes={{ root: classes.checkbox }}
          />
        )}
        <div className={classes.avatarWrapper}>
          {option.id !== 'no-option-item' ? (
            <UserAvatar
              avatarStyles={{
                ...(getOptionDisabled && getOptionDisabled(option)
                  ? { opacity: 0.5 }
                  : {}),
              }}
              avatarUrl={option.avatarUrl}
              name={option.label}
              initialLetters={
                option.type === 'company' ? option.label.split(' ')[0] : ''
              }
              shape={option.type === 'company' ? 'rounded' : 'circle'}
              fallbackColor={option.fallbackColor}
              avatarSize="mini"
              type="image"
            />
          ) : (
            defaultOptionIcon
          )}
        </div>
        <Box
          display="flex"
          width={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <BaseTypography
            style={{
              ...(getOptionDisabled && getOptionDisabled(option)
                ? { color: Colors.LightGray }
                : {}),
            }}
          >
            {option.label}
          </BaseTypography>
          {optionActions && option.id !== 'no-option-item' && (
            <BaseActionsMenuButton
              actions={optionActions}
              onActionMenuClick={(menuOptions) =>
                handleOpenActionsMenu(menuOptions, option.id)
              }
            />
          )}
        </Box>
      </>
    );

  return (
    <Popover
      id={id}
      onClose={onClose}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      className={popoverClassname}
      classes={{
        paper: classes.popoverRoot,
      }}
    >
      <Box zIndex="modal">
        <Autocomplete
          {...(creatable
            ? {
                filterOptions: (opts, params) => {
                  const trimmedInputValue = params.inputValue.trim();
                  let filtered = filter(opts, params) as SelectOption[];
                  // use trim to remove extra spaces so that
                  // we don't show create option button when
                  // user enter only spaces.
                  if (trimmedInputValue !== '') {
                    // find option that has the same input value as label
                    const exitingOption = (filtered as SelectOption[]).find(
                      (o) =>
                        o.label.toLowerCase() ===
                        params.inputValue.toLowerCase(),
                    );

                    filtered = [
                      // add create new option item when typed value
                      // is not existing.
                      ...(!exitingOption
                        ? [
                            {
                              id: 'add-new-option-item',
                              label: params.inputValue,
                            },
                          ]
                        : []),
                      ...filtered,
                    ];
                  }

                  return filtered;
                },
              }
            : {})}
          open={open}
          onClose={(ev, reason) => {
            // since we're prevent autocomplete onClose event to be
            // fired when user clicks on menu option menu button.
            // we should only allow escape event.
            if (reason === 'escape') onClose(ev, 'escapeKeyDown');
          }}
          multiple={isMultiple}
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          // don't set value when not multiple because value should not show filled in input area
          value={value}
          onChange={onChange}
          getOptionLabel={(option) => option.label || ''}
          disablePortal
          renderTags={() => null}
          noOptionsText={noOptionsText}
          renderOption={customOptionRenderer || defaultOptionRenderer}
          getOptionDisabled={getOptionDisabled}
          renderInput={(params) => (
            <>
              {inputRenderer ? (
                inputRenderer(params)
              ) : (
                <BaseMenuInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={placeholder}
                  width={menuWidth}
                />
              )}

              {listHeaderTitle && (
                <BaseTypography
                  fontType="10Medium"
                  className={classes.listHeaderTitle}
                >
                  {listHeaderTitle}
                </BaseTypography>
              )}
            </>
          )}
          options={options}
          {...autocompleteOptions}
        />
      </Box>
      {optionsActionMenuProps && <BaseMenu {...optionsActionMenuProps} />}
    </Popover>
  );
};
