import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  ButtonBase,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import * as Colors from 'src/theme/colors';
import { CRM_OPERATIONS, PERM_MOD_CRM, User } from 'src/constants';
import { UserAvatar } from 'src/components/User';
import { AssigneeList } from 'src/components/Assignee/AssigneeList';
import BaseTypography from 'src/components/Text/BaseTypography';
import { AssigneeDropDown } from 'src/components/Assignee/AssigneeDropDown';
import { AssigneeTooltipWrapper } from 'src/components/Assignee/AssigneeTooltipWrapper';
import { AssigneeDropDownOptions } from 'src/store/clients/types';
import { updateCompany } from 'src/store/clients/actions';
import { RootState } from 'src/store';
import * as UserUtils from 'src/utils/UserUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.75),
      borderRadius: 4,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: Colors.HoverNonBorderTableEditableItemBackground,
      },
      '& > *': {
        display: 'grid',
      },
    },
    assigneesBlock: {
      marginRight: 2,
    },
  }),
);

interface AssigneeProps {
  assignees: User[];
  maxShowAssignees: number;
  assigneesWithLead: User[];
  leadUserId: string;
  companyId: string;
  readOnly?: boolean;
}

export const Assignee: React.FC<Partial<AssigneeProps>> = ({
  assignees,
  maxShowAssignees = 2,
  leadUserId,
  companyId,
  readOnly = false,
}) => {
  const userPermissions = useSelector(
    (state: RootState) => state.user.data?.permissions,
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedAssignees, setSelectedAssignees] = React.useState({});

  const handleClickAssigneeDropDown = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    // To prevent click on the control resulting in row click, event bubbling needs to be stopped.
    event.stopPropagation();
    // when readOnly prop is true, then do not show the dropdown on click
    if (readOnly) return;
    const canChangeVisibility = UserUtils.hasOperation(
      PERM_MOD_CRM,
      CRM_OPERATIONS.CHANGE_ASSIGNEE,
      userPermissions,
    );

    if (!canChangeVisibility) return;
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAssigneeDropDown = (
    _: React.ChangeEvent<HTMLInputElement>,
    reason: AutocompleteCloseReason,
  ) => {
    if (reason === 'toggleInput') {
      return;
    }
    setAnchorEl(null);
    const updateCompanyOptions = {
      showSuccessMessage: false,
      isOptimisticChange: true,
    };
    if (!isEmpty(selectedAssignees)) {
      dispatch(updateCompany(selectedAssignees, updateCompanyOptions));
    }
  };

  const handleAssigneeChange = (
    currentAssignees: AssigneeDropDownOptions[],
  ) => {
    const assigneesMap = currentAssignees?.reduce(
      (assignee, currentAssignee) => ({
        ...assignee,
        [currentAssignee.id]: true,
      }),
      {},
    );
    const sendData = {
      companyId,
      additionalFields: {
        assignedUserIDs: assigneesMap,
      },
    };
    setSelectedAssignees(sendData);
  };

  const assigneeDropDownOpen = Boolean(anchorEl);
  const assigneeDropDownId = assigneeDropDownOpen
    ? 'assignee-droprown'
    : undefined;

  const assigneesLength = assignees?.length || 0;
  const assigneesSlice = assignees?.slice(0, maxShowAssignees);

  return (
    <Box>
      <AssigneeTooltipWrapper
        enterDelay={500}
        enterNextDelay={500}
        title={<AssigneeList title="Assignees" options={assignees} group />}
        data-testid="assignee-tooltip"
      >
        <ButtonBase
          aria-describedby={assigneeDropDownId}
          onClick={handleClickAssigneeDropDown}
        >
          <Box className={classes.root} display="flex" alignItems="center">
            {assigneesSlice?.map((assignee) => (
              <Box key={assignee.id} className={`${classes.assigneesBlock}`}>
                <UserAvatar
                  type="image"
                  avatarUrl={assignee?.fields.avatarImageUrl}
                  name={`${assignee?.fields.givenName} ${assignee?.fields.familyName}`}
                  fallbackColor={assignee?.fields.fallbackColor}
                  avatarSize="mini"
                />
              </Box>
            ))}
            {assigneesLength > maxShowAssignees && (
              <Box ml={0.5}>
                <BaseTypography
                  fontType="9Medium"
                  style={{ color: Colors.GraySmall }}
                >
                  +{assigneesLength - maxShowAssignees}
                </BaseTypography>
              </Box>
            )}
          </Box>
        </ButtonBase>
      </AssigneeTooltipWrapper>
      {assigneeDropDownOpen && (
        <AssigneeDropDown
          assigneeDropDownId={assigneeDropDownId}
          open={assigneeDropDownOpen}
          anchorEl={anchorEl}
          onClose={handleCloseAssigneeDropDown}
          placeholder="Change assignees..."
          isMultiple
          assignees={assignees}
          leadUserId={leadUserId}
          onChange={handleAssigneeChange}
          disableCloseOnSelect
        />
      )}
    </Box>
  );
};
