import { Events } from 'src/constants/webSocketConsts/events';
import {
  loadPortalSettingsAction,
  removePortalSettingsAction,
} from 'src/store/settings/actions';
import { PortalSettings } from 'src/constants/dataTypes';
import { ensureUnreachable } from 'src/utils/CommonUtils';
import { handlePortalSettingsChanges } from 'src/services/api/applicationsApi';

const portalSettingsHandler = (event: Events, items: Array<PortalSettings>) => {
  if (items.length === 0) {
    return null;
  }
  switch (event) {
    case Events.Remove:
      removePortalSettingsAction(items);
      return handlePortalSettingsChanges(
        items.at(0)?.fields.moduleSettings || [],
        items.at(0)?.fields.extensionsSettings || {},
      );
    case Events.Insert:
    case Events.Modify:
      loadPortalSettingsAction(items);
      return handlePortalSettingsChanges(
        items.at(0)?.fields.moduleSettings || [],
        items.at(0)?.fields.extensionsSettings || {},
      );
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default portalSettingsHandler;
