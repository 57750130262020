import { Logger } from 'aws-amplify';
import { API } from 'src/utils/AmplifyApiUtils';
import {
  FieldVisibility,
  TableProperties,
  TablePropertyFields,
} from 'src/constants';
import {
  TablePropertiesEntityTypes,
  DefaultCRMColumnSettings,
  DefaultInvoiceColumnSettings,
  DefaultSubscriptionColumnSettings,
  DefaultIntakeFormColumnSettings,
} from 'src/constants/tableColumnConsts';

const logger = new Logger('TablePropertiesClient');

export default class TablePropertiesClient {
  static updateTableProperties = async (
    columnSettings: Partial<TableProperties>,
    entityType: string,
  ) => {
    try {
      const result = await API.post(
        'AppAPI',
        `/table_properties/${entityType}`,
        {
          body: columnSettings,
        },
      );
      return result;
    } catch (ex) {
      // if update fails then we want to retain last known table properties
      logger.debug('Failed to update table properties');
      return columnSettings;
    }
  };

  static getTableProperties = async (entityType: string) => {
    try {
      const result = await API.get(
        'AppAPI',
        `/table_properties/${entityType}`,
        {},
      );
      return result;
    } catch (ex) {
      logger.debug('Failed to load table properties');

      // load default settings if not found
      let defaultColumnSettings = {
        tablePropertyFields: [] as FieldVisibility[],
      } as TablePropertyFields;

      switch (entityType) {
        case TablePropertiesEntityTypes.Invoice:
          defaultColumnSettings = DefaultInvoiceColumnSettings;
          break;
        case TablePropertiesEntityTypes.Subscription:
          defaultColumnSettings = DefaultSubscriptionColumnSettings;
          break;
        case TablePropertiesEntityTypes.CRM:
          defaultColumnSettings = DefaultCRMColumnSettings;
          break;
        case TablePropertiesEntityTypes.Form:
          defaultColumnSettings = DefaultIntakeFormColumnSettings;
          break;
        default:
          break;
      }

      return {
        structFields: defaultColumnSettings,
      };
    }
  };
}
