/* Do not change, this code is generated from Golang structs */

import {
  ContractStatus,
  ContractTemplate,
  FieldType,
  InputType,
} from 'src/services/api';

export type VariableContractField = ContractTemplate['fields'][0] & {
  label: string;
};

export interface OwnerFields {
  picture: string;
  companyAvatarUrl?: string;
  companyId?: string;
  companyName?: string;
  email: string;
  fallbackColor: string;
  familyName: string;
  givenName: string;
}
export interface Owner {
  id: string;
  metadata: OwnerFields;
}
export interface Time {}
export interface SignatureEvent {
  eventAt?: Time;
  type: string;
  email: string;
  sourceIP: string;
}
export interface Bounds {
  x: number;
  y: number;
  width: number;
  height: number;
  pageWidth: number;
  pageHeight: number;
  resizedWidth?: number;
  resizedHeight?: number;
}
export interface ContractField {
  id: string;
  inputType: InputType | undefined;
  type: FieldType;
  isOptional: boolean;
  page: number;
  bounds: Bounds;
  encodedImgValue?: string;
  label?: string;
  value?: string;
  autoFillField?: string;
}

export interface ListContractResponse {
  data: Contract[];
  nextToken?: string;
}

export interface Contract {
  id: string;
  object: string;
  ref?: string;
  data?: string;
  creatorId?: string;
  createdAt: Date;
  updatedAt?: Date;
  status: ContractStatus;
  name: string;
  fileUrl: string;
  pageKeys: string[];
  signedFileUrl: string;
  fields: ContractField[];
  signatureEvents: SignatureEvent[];
  contractTemplateId: string;
  recipientId: string;
  submissionDate?: string;
  shareDate: string;
  identityId: string;
  owner?: Owner;
}

export enum PredefinedAutofillKeys {
  FirstName = 'firstName',
  FullName = 'fullName',
  LastName = 'lastName',
  Email = 'email',
  ShareDate = 'shareDate',
  Company = 'company',
}
