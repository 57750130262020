import { Events } from 'src/constants/webSocketConsts/events';
import { NotificationGroups } from 'src/store/notifications/types';
import {
  insertInvoiceNotifications,
  deleteNotifications,
  updateNotifications,
} from 'src/store/notifications/actions';
import { ensureUnreachable } from 'src/utils/CommonUtils';

const newInvoiceNotificationHandler = (event: Events, items: Array<any>) => {
  switch (event) {
    case Events.Insert:
      return insertInvoiceNotifications(
        items,
        NotificationGroups.NewInvoiceNotifications,
      );
    case Events.Modify:
      return updateNotifications(
        items,
        NotificationGroups.NewInvoiceNotifications,
      );
    case Events.Remove:
      return deleteNotifications(
        items,
        NotificationGroups.NewInvoiceNotifications,
      );
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default newInvoiceNotificationHandler;
