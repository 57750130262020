import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { BlackHeadings } from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';

interface SectionHeaderProps {
  title: string;
  subTitle: string;
  description: string;
  descriptionDetail: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionDescriptionWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1.5, 0),
    },
    sectionTitleWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1.5, 0),
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.5),
    },
  }),
);

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  children,
  title,
  subTitle,
  description,
  descriptionDetail,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.sectionTitleWrapper}>
        <BaseTypography style={{ color: BlackHeadings }} fontType="24Medium">
          {title}
        </BaseTypography>
        <BaseTypography
          data-testid="section-subtitle"
          style={{ color: BlackHeadings }}
          fontType="18Regular"
        >
          {subTitle}
        </BaseTypography>
      </div>
      <div className={classes.sectionDescriptionWrapper}>
        <BaseTypography style={{ color: BlackHeadings }} fontType="18Medium">
          {description}
        </BaseTypography>
        <BaseTypography
          data-testid="section-subtitle"
          style={{ color: BlackHeadings }}
          fontType="18Medium"
        >
          {descriptionDetail}
        </BaseTypography>
      </div>

      <div className={classes.sectionContent}>{children}</div>
    </div>
  );
};
