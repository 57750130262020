import { Box } from '@material-ui/core';
import React from 'react';
import {
  GENERAL_SETTINGS_PAGE,
  PLAN_PAGE,
  PLAN_PAGE_CANCEL_START,
  SettingsPagePathToSettingsMetaDataMap,
} from 'src/constants';
import { PortalConfigContext, RouteContext } from 'src/context';
import { SettingsPageToolbarContext } from 'src/context/settingsPageToolbarContext';
import { useCustomerSubscription } from 'src/hooks/useCustomerSubscription';
import { usePlanStatus } from 'src/hooks/usePlanStatusHook';
import { useAppSelector } from 'src/hooks/useStore';
import { BaseDescription } from 'src/components/CustomizationForm/BaseDescription';
import BaseTypography from 'src/components/Text/BaseTypography';
import CurrentPlan from 'src/components/Settings/PlanPage/CurrentPlan';

export const SettingsPageToolbar: React.FC = () => {
  const { pathname } = React.useContext(RouteContext);
  const { name: portalName } = React.useContext(PortalConfigContext);
  const { toolbarActions } = React.useContext(SettingsPageToolbarContext);
  const { isCancelledPlan } = usePlanStatus();
  const { customerSubscription } = useCustomerSubscription();
  const isClient = useAppSelector((state) => state.user.isClient);

  const [pageTitle, pageDescription] = React.useMemo(() => {
    const title = SettingsPagePathToSettingsMetaDataMap[pathname]?.label;
    const description =
      isClient && pathname === GENERAL_SETTINGS_PAGE.path
        ? `Manage your general ${portalName} settings` // main settings page description text depends on portal name
        : SettingsPagePathToSettingsMetaDataMap[pathname]?.description;

    return [title, description];
  }, [pathname]);

  // plan settings page description should be calculated based on the current plan
  if (pathname === PLAN_PAGE.path || pathname === PLAN_PAGE_CANCEL_START.path) {
    const label =
      pathname === PLAN_PAGE_CANCEL_START.path
        ? PLAN_PAGE_CANCEL_START.label
        : PLAN_PAGE.label;
    const currentPlanSubscription = isCancelledPlan
      ? undefined
      : customerSubscription;
    return (
      <Box>
        <BaseTypography fontType="24Medium">{label}</BaseTypography>
        <CurrentPlan subscription={currentPlanSubscription} />
      </Box>
    );
  }

  // other settings pages have static title and description
  // that can be defined in the settingsPagePathToSettingsMetaDataMap.
  return (
    <Box
      display="flex"
      width={1}
      justifyContent={toolbarActions ? 'space-between' : 'flex-start'}
      alignItems="center"
    >
      <BaseDescription
        titleFontType="24Medium"
        title={pageTitle}
        descriptions={[pageDescription]}
      />
      {toolbarActions}
    </Box>
  );
};
