import React, { useContext, useMemo } from 'react';
import { IconButton, Theme, makeStyles } from '@material-ui/core';
import { useFormResponse } from 'src/components/FormsV2/useFormResponse';
import { LoadingWrapper } from 'src/components/Loading';
import {
  QuestionAnswerCardHeader,
  QuestionAnswerInput,
} from 'src/components/FormsV2/QuestionAnswerCard';
import { RegularCardBox } from 'src/components/Cards/RegularCardBox';
import RowDivider from 'src/components/RowDivider';
import { GraySmall, NonHoverBorder } from 'src/theme/colors';
import {
  FormResponse,
  FormTemplate,
  InputsTypes,
  QuestionAnswerWithIdentityId,
} from 'src/components/FormsV2/formsTypes';
import CopyToClipboard from 'src/components/CopyToClipboard';
import { CopyIcon } from 'src/components/Icons';
import {
  FormBuilderPreviewContext,
  FormTitlePreviewCard,
} from 'src/components/FormsV2/FormBuilderPreview';
import BaseTypography from 'src/components/Text/BaseTypography';
import clsx from 'clsx';

interface StyleProps {
  isFormBuilderPreview: boolean;
  isNotificationPreview?: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: (props: StyleProps) =>
      props.isNotificationPreview ? theme.spacing(2.5) : theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  card: {
    padding: '20px',
    border: `1px solid ${NonHoverBorder}`,
    marginBottom: theme.spacing(2),
    maxWidth: (props: StyleProps) =>
      props.isFormBuilderPreview ? 'inherit' : 688,
    width: '100%',
    boxSizing: 'border-box',
  },
  shadowlessCard: {
    boxShadow: 'none',
    backgroundColor: 'white',
  },
  inputContainer: {
    position: 'relative',
  },
  iconButton: {
    position: 'absolute',
    right: '12px',
    top: '10px',
    padding: 0,
  },
  copyIcon: {
    width: 16,
    height: 16,
  },
  title: {
    color: (props: StyleProps) =>
      props.isFormBuilderPreview ? GraySmall : 'inherit',
  },
  formTitle: {
    maxWidth: (props: StyleProps) =>
      props.isFormBuilderPreview ? 'inherit' : 688,
    width: '100%',
  },
}));

type FormResponsePreview = {
  responses: QuestionAnswerWithIdentityId[];
  formId: string;
  disableShadow?: boolean;
};

export const FormResponsePreview = ({
  responses = [],
  formId,
  disableShadow = false,
}: FormResponsePreview) => {
  const { isFormBuilderPreview } = useContext(FormBuilderPreviewContext);
  const classes = useStyles({
    isFormBuilderPreview,
  });

  if (!responses.length) return null;
  return (
    <>
      {responses.map((questionConfig, index) => {
        const {
          title,
          description,
          type,
          answer: responseValue,
          multipleChoiceOptions,
          questionId,
          identityId,
          isQuestionEdited,
          isRequired,
        } = questionConfig;
        return (
          <RegularCardBox
            key={questionId}
            className={clsx(classes.card, {
              [classes.shadowlessCard]: disableShadow,
            })}
          >
            <QuestionAnswerCardHeader
              title={
                isFormBuilderPreview && !title ? (
                  <BaseTypography fontType="15Medium" className={classes.title}>
                    Question title
                  </BaseTypography>
                ) : (
                  title
                )
              } // if form builder preview mode and no title then show placeholder title
              description={description}
              type={type}
              showRequiredError={false}
              showRequiredAsterisk={isRequired}
              isQuestionEdited={Boolean(isQuestionEdited)}
            />
            {type !== InputsTypes.Title && <RowDivider mt={2.5} noLine />}
            <div className={classes.inputContainer}>
              <QuestionAnswerInput
                questionId={questionId}
                formId={formId}
                type={type}
                value={responseValue}
                name={`questionAnswers[${index}].responseValue`}
                options={multipleChoiceOptions} // used for multi/single choices input types
                identityId={identityId}
                readOnly
              />
              {(type === InputsTypes.ShortAnswer ||
                type === InputsTypes.LongAnswer) &&
              responseValue ? (
                <CopyToClipboard>
                  {({ copy }) => (
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => copy(responseValue)}
                    >
                      <CopyIcon className={classes.copyIcon} />
                    </IconButton>
                  )}
                </CopyToClipboard>
              ) : null}
            </div>
          </RegularCardBox>
        );
      })}
    </>
  );
};

/**
 * This method returns if the response question is edited or not.
 */
export const isResponseQuestionEdited = (
  responseQuestionId: string,
  formOfResponse: FormTemplate | undefined,
) =>
  !formOfResponse?.fields.formFieldIds.find(
    (inputId) => inputId === responseQuestionId,
  );

const getFormattedFormResponses = (
  formTemplate: FormTemplate,
  formResponse: FormResponse,
) => {
  const responses = (formResponse.fields.formFieldIds || []).map((id) => ({
    ...formResponse?.fields.formFields[id],
    questionId: id,
    identityId: formResponse?.identityId,
    isQuestionEdited: isResponseQuestionEdited(id, formTemplate),
  })) as Array<QuestionAnswerWithIdentityId>;

  return responses;
};

export const FormResponseDetail: React.FC<{
  detailOptions?: { formId: string; responseRefId: string };
  isNotificationPreview?: boolean;
}> = ({ detailOptions, isNotificationPreview = false }) => {
  const classes = useStyles({
    isFormBuilderPreview: false,
    isNotificationPreview,
  });

  const { selectedResponse, selectedForm, isResponseLoading } =
    useFormResponse(detailOptions);

  const responses = useMemo(() => {
    if (!selectedForm || !selectedResponse) return [];
    return getFormattedFormResponses(selectedForm, selectedResponse);
  }, [selectedForm, selectedResponse]);

  if (!selectedResponse) return null;

  return (
    <LoadingWrapper isLoading={isResponseLoading} hideContentWhileLoading>
      <div className={classes.root}>
        <div className={classes.formTitle}>
          <FormTitlePreviewCard
            title={selectedResponse?.fields.formName || ''}
            description={selectedResponse?.fields.formDescription || ''}
            disableShadow={isNotificationPreview}
          />
        </div>
        <FormResponsePreview
          responses={responses}
          formId={selectedResponse?.formId || ''}
          disableShadow={isNotificationPreview}
        />
      </div>
    </LoadingWrapper>
  );
};
