import React from 'react';
import { Box } from '@material-ui/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { Assignee } from 'src/components/Assignee';
import { RootState } from 'src/store';

export const AssigneeCellRenderer: React.FC<ICellRendererParams> = React.memo(
  ({ data }) => {
    // this is not immutable when updating client is done so no re-render
    const updatingClientsIds = useSelector(
      (state: RootState) => state.clients.updatingClientsIds,
    );

    // if the updating client id stored in redux store
    // corresponds to the row id (client id) show the skeleton
    if (updatingClientsIds[data.userId]) {
      return (
        <Box
          ml={0.5}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Skeleton variant="rect" width={20} height={20} />

          <Box ml={0.5}>
            <Skeleton variant="rect" width={20} height={20} />
          </Box>
        </Box>
      );
    }

    return (
      <Assignee
        assignees={[...data.assignedUsers]}
        leadUserId={data.leadUser?.id ? data.leadUser.id : ''}
        companyId={data.company?.id}
      />
    );
  },
);
