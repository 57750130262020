import React from 'react';
import { ItemRenderProps } from 'src/components/Channels/BaseChannelSidebar';
import {
  InboxNotificationStatus,
  InboxSidebarItemData,
} from 'src/components/Inbox/InboxSidebar';
import { InboxPageContext } from 'src/context/inboxPageContext';
import { useUpdateInboxNotificationStatusMutation } from 'src/services/api/inboxApi';
import { ChannelListItem } from '../UI/ListItem/ChannelListItem';
import history from 'src/history';

interface InboxSidebarItemProps extends ItemRenderProps<InboxSidebarItemData> {
  style: React.CSSProperties;
}

export const LegacyInboxSidebarItem = (props: InboxSidebarItemProps) => {
  const { selectedNotification, setSelectedNotification } =
    React.useContext(InboxPageContext);
  const { channelOption, onSelect } = props;
  const [markNotificationAsRead] = useUpdateInboxNotificationStatusMutation();

  const {
    avatarInfo,
    label,
    notificationStatus,
    description,
    id: notificationId,
  } = channelOption;

  const isNotificationUnread =
    notificationStatus === InboxNotificationStatus.Sent;

  const handleChannelClicked = () => {
    history.push({
      search: `?channelId=${notificationId}`,
    });

    if (isNotificationUnread && notificationId) {
      markNotificationAsRead({
        notificationId,
        status: '2_READ', // read notification status
      });
      setSelectedNotification({ ...selectedNotification, isRead: true });
    }
    onSelect(channelOption);
  };

  return (
    <ChannelListItem
      title={label}
      subTitle={description}
      selected={selectedNotification?.id === notificationId}
      onSelect={handleChannelClicked}
      unread={isNotificationUnread}
      members={[
        {
          name: avatarInfo?.name || '',
          avatar: avatarInfo?.imageUrl || '',
          fallbackColor: avatarInfo?.fallbackColor || '',
        },
      ]}
    />
  );
};
