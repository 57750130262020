import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme, ButtonBase } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import * as Colors from 'src/theme/colors';
import { UserAvatar } from 'src/components/User';
import { ClientDetailsCompanySelection } from 'src/components/ClientDetailsPage/PropertiesSection/ClientDetailsCompanySelection';
import { updateClientUser } from 'src/store/clients/actions';
import BaseTypography from 'src/components/Text/BaseTypography';
import { NoCompanyIcon } from 'src/components/Icons';
import { SelectOption } from 'src/components/Select/types';
import { CompanyCellData, ClientTableRowModal } from 'src/store/clients/types';
import { RootState } from 'src/store';

interface CompanyCellRendererProps extends ICellRendererParams {
  data: ClientTableRowModal;
  value: CompanyCellData;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.75),
    borderRadius: 4,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Colors.HoverNonBorderTableEditableItemBackground,
    },
    '& > *': {
      display: 'grid',
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  companySelectorName: {
    marginLeft: theme.spacing(1.5),
    color: Colors.BlackSmall,
  },
  loading: {
    marginLeft: theme.spacing(1),
  },
}));

export const CompanyCellRenderer: React.FC<CompanyCellRendererProps> =
  React.memo(({ data: clientData, value: companyData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // this is not immutable when updating client is done so no re-render
    const updatingClientsIds = useSelector(
      (state: RootState) => state.clients.updatingClientsIds,
    );
    const [companyDropdownAnchor, setCompanyDropdownAnchor] =
      React.useState<HTMLElement | null>(null);

    const handleOpenCompanyDropdown = (
      event: React.MouseEvent<HTMLElement>,
    ) => {
      // To prevent click on the control resulting in row click, event bubbling needs to be stopped.
      event.stopPropagation();
      setCompanyDropdownAnchor(event.currentTarget);
    };

    const handleCompanyChange = (
      _: any,
      selectedCompany: SelectOption | SelectOption[] | null,
    ) => {
      if (selectedCompany) {
        setCompanyDropdownAnchor(null);
        const { id: selectedOptionId, label: selectedCompanyOptionName } =
          selectedCompany as SelectOption;
        // Tracking which no-regular options (no-company option | Create {companyName}) is clicked
        const isNoCompanyOptionSelected = selectedOptionId === 'no-option-item';
        const isAddNewCompanyOptionSelected =
          selectedOptionId === 'add-new-option-item';

        // when no company option is selected, client company name
        // should take empty string not selected option label otherwise
        // company is created as No Company
        const stagedCompanyName = isNoCompanyOptionSelected
          ? ''
          : selectedCompanyOptionName;

        // company id should be empty string only when "no company"
        // or 'Create {companyName} option is selected
        const stagedCompanyId =
          isAddNewCompanyOptionSelected || isNoCompanyOptionSelected
            ? ''
            : selectedOptionId;

        dispatch(
          updateClientUser(
            {
              userId: clientData.userId,
              cognitoFirstName: clientData.clientFullData?.fields.givenName,
              cognitoLastName: clientData.clientFullData?.fields.familyName,
              companyId: stagedCompanyId,
              companyName: stagedCompanyName,
              isPlaceholder: isNoCompanyOptionSelected,
              customFields: clientData.clientFullData?.fields.customFields,
            },
            // do optimistic update only when:
            // selected company is not "no company" option
            // and selected option is not "create company" option
            {
              isOptimisticChange:
                !isNoCompanyOptionSelected && !isAddNewCompanyOptionSelected,
            },
          ),
        );
      }
    };

    if (updatingClientsIds[clientData.userId]) {
      return (
        <div className={classes.loading}>
          <Skeleton variant="rect" width={120} height={20} />
        </div>
      );
    }

    return (
      <div>
        <ButtonBase
          className={classes.root}
          onClick={handleOpenCompanyDropdown}
        >
          <div className={classes.flexContainer}>
            {!companyData.isPlaceholder ? (
              <>
                <UserAvatar
                  avatarUrl={companyData.avatarUrl}
                  name={companyData.name}
                  initialLetters={companyData.name.split(' ')[0]}
                  fallbackColor={companyData.fallbackColor}
                  avatarSize="mini"
                  type="image"
                />
                <BaseTypography className={classes.companySelectorName}>
                  {companyData.name}
                </BaseTypography>
              </>
            ) : (
              <NoCompanyIcon
                style={{
                  color: Colors.LightGray,
                  width: 20,
                  height: 20,
                }}
              />
            )}
          </div>
        </ButtonBase>
        {companyDropdownAnchor && (
          <ClientDetailsCompanySelection
            currentCompanyId={companyData.id || ''}
            anchorEl={companyDropdownAnchor}
            onChange={handleCompanyChange}
            onClose={() => setCompanyDropdownAnchor(null)}
            hasCompanyAssigned={!companyData.isPlaceholder}
          />
        )}
      </div>
    );
  });
