import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { listClients } from 'src/store/clients/actions';
import { SetFieldHandler } from 'src/constants/fieldsTypes';
import { ComboBoxOption } from 'src/components/Select/ComboBox';
import { ChannelMemberSelect } from 'src/components/MultiSelect/ChannelMemberSelect';

interface RecipientSelectionProps {
  value: string;
  fieldName: string;
  setFieldValue: SetFieldHandler;
  disabled?: boolean;
  label?: string;
  hideCompanies?: boolean;
  onChange?: (value: string) => void;
}
const RecipientSelection: React.FC<RecipientSelectionProps> = ({
  value,
  fieldName,
  setFieldValue,
  disabled,
  label,
  hideCompanies = false,
  onChange,
}) => {
  const dispatch = useDispatch();
  const {
    activeClients: clients,
    companies,
    loadedClients,
    isLoading: isClientsLoading,
  } = useSelector((state: RootState) => state.clients);

  React.useEffect(() => {
    if (!loadedClients && !isClientsLoading) {
      dispatch(listClients());
    }
  }, [loadedClients, isClientsLoading]);

  React.useEffect(() => {
    if (!value) {
      setFieldValue(fieldName, '');
    }
  }, []);

  const handleRecipientChange = async (_: any, vals: any[] | any) => {
    const newValue = vals as ComboBoxOption;
    if (newValue && newValue.id) {
      // if we don't add "await" here
      // formik errors will include "recipientId" required error
      // even after setting value for "recipientId"
      // For more info :https://github.com/jaredpalmer/formik/issues/2059
      await setFieldValue(fieldName, newValue.id);
      const selectedClient = clients.find(
        (client) => client.id === newValue.id,
      );

      const selectedCompany = companies.find(
        (company) => company.id === newValue.id,
      );

      if (selectedClient) {
        setFieldValue('fields.clientUserId', selectedClient.id);
        setFieldValue('fields.companyId', selectedClient.fields.companyId);
      }

      if (selectedCompany) {
        setFieldValue('fields.clientUserId', '');
        setFieldValue('fields.companyId', selectedCompany.id);
      }
    } else {
      setFieldValue(fieldName, '');
      setFieldValue('fields.clientUserId', '');
      setFieldValue('fields.companyId', '');
    }
    onChange?.(newValue.id);
  };

  return (
    <ChannelMemberSelect
      label={label}
      blurOnSelect
      memberIds={value}
      onChange={handleRecipientChange}
      id="recipient-selection"
      multiple={false}
      disabled={disabled}
      excludeEmptyCompany
      allowMultipleCompanies={false}
      hideCompanies={hideCompanies}
    />
  );
};

export default RecipientSelection;
