import {
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
} from '@ag-grid-community/core';
import {
  SignaturePageComponent,
  SignaturePageImageData,
} from 'src/store/signaturePage/types';
import { toPng } from 'html-to-image';
import { signaturePageContentWidth } from 'src/components/Signature/SignaturePageContent';
import { FieldType, InputType } from 'src/services/api';
import { isSafari } from 'src/hooks/useOSDetector';
import {
  ESIGNATURE_PAGE_MARGIN,
  SignatureComponentType,
  SignatureComponentTypeLabelMap,
} from 'src/constants';
import { ensureUnreachable } from 'src/utils/CommonUtils';
import { ContractField } from 'src/entities/Contract';

/**
 * Load a signature pdf page imageElement from the given url, this url is generated
 * based on the s3 bucket location when the fileEntity is loaded
 */
export const loadPageImage = async (
  pageImageUrl: string,
): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const pageImageElement = new Image();
    pageImageElement.crossOrigin = 'Anonymous';
    pageImageElement.onload = () => resolve(pageImageElement);
    pageImageElement.onerror = reject;
    pageImageElement.src = pageImageUrl;
  });

/**
 * Determines the signature input "componentType" based on the contract input "type".
 * Inputs requiring client input are categorized under REQUEST_ type,
 * while inputs not editable by clients are categorized under OWNER_.
 * @param field Contract input field
 * @returns {SignatureComponentType} Signature input componentType
 */
export const getComponentTypeUsingContractType = (
  field: ContractField,
  isRequestInput: boolean,
): SignatureComponentType => {
  switch (field.type) {
    case FieldType.Date:
      return isRequestInput
        ? SignatureComponentType.REQUEST_DATE
        : SignatureComponentType.OWNER_DATE;
    case FieldType.Signature:
      return isRequestInput
        ? SignatureComponentType.REQUEST_SIGN
        : SignatureComponentType.OWNER_SIGN;
    case FieldType.Initials:
      return isRequestInput
        ? SignatureComponentType.REQUEST_INITIAL
        : SignatureComponentType.OWNER_INITIAL;
    case FieldType.Text:
      return isRequestInput
        ? SignatureComponentType.REQUEST_TEXT
        : SignatureComponentType.OWNER_TEXT;
    default:
      return ensureUnreachable(field.type);
  }
};

/**
 * We use base64 to display the initial and sign component to the user.
 * The base64 is stored in the `value` key of the signature pageComponent object.
 * The actual text inputed by user is saved in "imageTextValue" key
 * @param pageComponent signature component
 * @returns For initials and sign components, value using 'imageTextValue' key. For others, it will be 'value'.
 */

export const getContractInputValueUsingComponentType = (
  pageComponent: SignaturePageComponent,
) => {
  switch (pageComponent.componentType) {
    case SignatureComponentType.OWNER_INITIAL:
    case SignatureComponentType.OWNER_SIGN:
    case SignatureComponentType.REQUEST_INITIAL:
    case SignatureComponentType.REQUEST_SIGN:
      return pageComponent.imageTextValue;
    case SignatureComponentType.OWNER_TEXT:
    case SignatureComponentType.OWNER_DATE:
    case SignatureComponentType.REQUEST_TEXT:
    case SignatureComponentType.REQUEST_DATE:
      return pageComponent.value;
    default:
      return ensureUnreachable(pageComponent.componentType);
  }
};

/* Retrieves the field type of a component based on its component type.
 * @param componentType - The type of the component.
 * @returns The corresponding FieldType for the given componentType.
 */
export const getFieldTypeUsingComponentType = (
  componentType: SignatureComponentType,
): FieldType => {
  switch (componentType) {
    case SignatureComponentType.REQUEST_DATE:
    case SignatureComponentType.OWNER_DATE:
      return FieldType.Date;
    case SignatureComponentType.REQUEST_SIGN:
    case SignatureComponentType.OWNER_SIGN:
      return FieldType.Signature;
    case SignatureComponentType.REQUEST_TEXT:
    case SignatureComponentType.OWNER_TEXT:
      return FieldType.Text;
    case SignatureComponentType.REQUEST_INITIAL:
    case SignatureComponentType.OWNER_INITIAL:
      return FieldType.Initials;
    default:
      return ensureUnreachable(componentType);
  }
};

/**
 * Convert an image to a data string
 * @param imgElement html image with src to load
 * @returns image data
 */
export const getImageData = (imgElement: HTMLImageElement) => {
  const canvas = document.createElement('canvas');
  canvas.width = imgElement.width;
  canvas.height = imgElement.height;
  const ctx = canvas.getContext('2d');
  let imageData = '';
  if (ctx) {
    ctx.drawImage(imgElement, 0, 0);
    imageData = canvas.toDataURL('image/jpeg');
  }
  return imageData;
};

export const processContractTableHeaderCallback = (
  params: ProcessHeaderForExportParams,
) => {
  if (params.column.getColId() === 'recipient') {
    // For the 'recipient' column, we need to display three separate columns in the CSV export.
    // This is because the 'recipient' column contains nested information (first name, last name, email)
    // that we want to present as individual columns in the exported CSV.
    return `"Recipient first name","Recipient last name","Recipient email"`;
  }

  return `"${params.column.getColDef().headerName || ''}"`;
};

export const processContractTableCellCallback = (
  params: ProcessCellForExportParams,
) => {
  const rowData = params.node?.data;
  const colId = params.column.getColId();

  // Customize cell values during CSV export based on column ID.
  switch (colId) {
    case 'recipient':
      return `"${rowData.recipient?.givenName || ''}","${
        rowData.recipient?.familyName || ''
      }","${rowData.recipient?.email || ''}"`;

    case 'createdAt':
    case 'submissionDate':
    case 'latestSubmissionDate':
      return rowData[colId]
        ? `${new Date(Date.parse(rowData[colId])).toLocaleDateString()}`
        : '';

    // status column first letter to uppercase
    case 'status': {
      const status = rowData[colId] || '';
      return status.at(0).toUpperCase() + status.slice(1);
    }
    // requestsCount and submissionsCount columns should be 0 if null or undefined in the rowData object
    case 'requestsCount': {
      const requestsCount = rowData[colId];
      return requestsCount;
    }
    case 'submissionsCount': {
      const submissionsCount = rowData[colId];
      return submissionsCount;
    }
    default:
      return `"${params.value || ''}"`;
  }
};

export function isOwnerSignatureComponent(
  component: SignaturePageComponent,
): component is SignaturePageComponent {
  return (
    component.inputType === InputType.Fixed &&
    (component.fieldType === FieldType.Signature ||
      component.fieldType === FieldType.Initials)
  );
}

/**
 * Converts a html of signature component into a Base64-encoded image.
 * @returns {string} - A Promise that resolves with the Base64-encoded image.
 */
export async function htmlToBase64Image(pageComponent: SignaturePageComponent) {
  // optional components wont have a value, but owner signatures
  // get their value from the webapp generated image
  if (!pageComponent.value && !isOwnerSignatureComponent(pageComponent))
    return '';

  const elementId = pageComponent.key;
  const htmlElement = document.getElementById(elementId);
  if (!htmlElement) return '';

  // The pixel ratio is crucial in determining the size of the generated Base64 image.
  // When the pixel ratio is set to 1, the dimensions of the element and the Base64 image are identical.
  // However, using a pixel ratio of 1 on mobile devices results in an excessively small Base64 image.
  const pixelRatio =
    signaturePageContentWidth /
    Math.min(window.innerWidth, signaturePageContentWidth); // adjust pixel ratio for mobile devices

  const toPngOptions = {
    canvasHeight: htmlElement.offsetHeight,
    pixelRatio: pixelRatio * 3,
  };

  if (isSafari()) {
    // this is the most ridiculous thing I've ever seen
    // but it is a solution to a bug where images are blank
    // on safari and ios devices
    // https://github.com/bubkoo/html-to-image/issues/361
    await toPng(htmlElement, toPngOptions);
    await toPng(htmlElement, toPngOptions);
    await toPng(htmlElement, toPngOptions);
  }

  return toPng(htmlElement, toPngOptions);
}

/**
 * Get the position for a component. It calculated as the overall yposition in the entire doc
 * subtracted by any pageOffset (margins) and sum of previous page heights
 * @param prevPagesHeight
 * @param pageNumber
 * @param yPosition
 * @param offset
 */
export const getYPosition = (
  prevPagesHeight: number,
  pageNumber: number,
  yPosition: number,
  offset: number,
) => {
  const pageOffset = (pageNumber - 1) * ESIGNATURE_PAGE_MARGIN; // sum of margins between previous pages

  // Here we are calculating the y position of the sign component relative to the
  // page it is placed on.
  // Here is an example to understand how it works
  // Consider we have a pdf of 3 pages with uneven heights 100,90,100
  // And a sign component is placed on 3rd page with yPosition = 260
  // Now our "prevPagesHeight" = page 1 height + page 2 height = 100 + 90 = 190
  // And our "pageOffset" i.e. sum of prev page margins = (3 - 1) * ESIGNATURE_PAGE_MARGIN = 30
  // For this example let "offset" be 0. But in order to incorporate text sign components
  // we can pass offset = 15.
  // Now, y = 260 + 0 - (190 + 30) = 40(y position relative to 3rd page)
  // refer to [diagram](./yPositionDiagram.png)
  const y = yPosition + offset - (prevPagesHeight + pageOffset);
  return y;
};

/**
 * Formats the page components to use the y-coordinate
 * relative to the PDF page it is placed on instead of the complete PDF canvas.
 * @param pageImages all the pdf page images
 * @param pageComponents all signature page components
 */
export const getPageComponentsRelativeToPage = async (
  pageImages: SignaturePageImageData[],
  pageComponents: SignaturePageComponent[],
) => {
  const pageImagesWithElement = await Promise.all(
    pageImages.map(async (pageImage) => {
      const pageImageElement = await loadPageImage(pageImage.url);
      const imageData = getImageData(pageImageElement);

      return { ...pageImage, imgElement: pageImageElement, imageData };
    }),
  );

  return pageComponents.map((pageComponent) => {
    // all previous pages
    const allPrevPages = pageImagesWithElement.filter(
      (p) => p.pageNumber < (pageComponent.page || 1),
    );

    const currentPageImageElement = pageImagesWithElement.filter(
      (p) => p.pageNumber === pageComponent.page,
    );

    const { height: pageHeight = 0, width: pageWidth = 0 } =
      currentPageImageElement?.length > 0
        ? currentPageImageElement.at(0)?.imgElement || {}
        : {};

    // sum of heights of all the prev pages
    const prevPagesHeight = allPrevPages.reduce((sum, page) => {
      const { height, width } = page.imgElement;
      const sizeMultiplier = signaturePageContentWidth / width;
      // Calculate page image height with respect to the PDF width.
      return sum + height * sizeMultiplier;
    }, 0);

    return {
      ...pageComponent,
      yPosition: getYPosition(
        prevPagesHeight,
        pageComponent.page || 1,
        pageComponent.yPosition,
        0,
      ),
      // We calculate x,y, width and height relative to the canvas we have created for pdf preview
      // so we also want to save the canvas's width/height of the page on which component is rendered
      // this will help in calculating the scaling factor and render the components on original pdf which
      // doesn't have exact dimensions as the canvas
      pageWidth: signaturePageContentWidth,
      // when rendering the preview we fix width but height we calculate based on actual height of the page image
      // so while sending bounds for the component we need to recalculate that same height
      pageHeight: pageHeight * (signaturePageContentWidth / pageWidth),
    };
  });
};

export const hasSignatureCompnentsWithDuplicateLabels = (
  components: SignaturePageComponent[],
  newComponentLabel: string,
) => components.some((component) => component.label === newComponentLabel);

/**
 * Checks if there are any client component added for the client to sign.
 * @param pageComponents - The array of contract page components.
 * @returns `true` if client components exist, `false` otherwise.
 */
export const hasValidClientContractComponents = (
  pageComponents: SignaturePageComponent[],
) => {
  const clientPageComponents =
    pageComponents?.filter(
      (component) => component.inputType === InputType.Client,
    ) || [];

  return clientPageComponents.length > 0;
};

/**
 * @param field {ContractField}
 * @description `name` property acts as label for placeholder components that are missing values.
 * From client's perspective, all client components are placeholders.
 * `SignatureComponentTypeLabelMap` returns the placeholder component label for a given component type.
 * @example for component type `signature`, the placeholder label is `Signature` based on SignatureComponentTypeLabelMap
 * @returns
 */
export const getComponentNameByComponentType = (field: ContractField) => {
  if (field.inputType !== InputType.Client) return field.value;
  if (field.inputType === InputType.Client && field.type === FieldType.Text)
    return field.value;
  return SignatureComponentTypeLabelMap[field.type];
};

/**
 * Filters the page components based on the contract type.
 * For template contracts, removes variable inputs and non-empty autofill inputs.
 * For direct contracts, removes non-empty autofill inputs.
 * @returns {Array} - The filtered array of signature components.
 */
export const filterSignatureComponentsByCreationMethod = (
  components: SignaturePageComponent[],
  isTemplate: boolean,
) => {
  // We don't need variable inputs since one-off contracts do not support them
  // We also don't need non-empty autofill inputs since they are filled during contract creation for one-off contracts
  if (!isTemplate) {
    return components.filter((component) => {
      if (component.inputType === InputType.AutoFill) {
        return component.value !== '';
      }
      return component.inputType !== InputType.Variable;
    });
  }

  // For contract templates, we don't return autofill inputs with values set since they need to be set dynamically at the time of contract signing
  // depending on the contract recipient
  return components.filter((component) => {
    if (component.inputType === InputType.AutoFill) {
      return component.value === '';
    }
    return true;
  });
};
