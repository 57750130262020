import { Events } from 'src/constants/webSocketConsts/events';
import {
  insertFilesNotifications,
  updateNotifications,
  deleteNotifications,
} from 'src/store/notifications/actions';
import { NotificationGroups } from 'src/store/notifications/types';
import { ensureUnreachable } from 'src/utils/CommonUtils';

export const FileNotificationsHandler = (
  notificationType: NotificationGroups,
  event: Events,
  items: Array<any>,
  allowMarkingNotificationAsRead?: boolean,
) => {
  switch (event) {
    case Events.Insert:
      return insertFilesNotifications(
        items,
        notificationType,
        allowMarkingNotificationAsRead,
      );
    case Events.Modify:
      return updateNotifications(items, notificationType);
    case Events.Remove:
      return deleteNotifications(items, notificationType);

    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};
