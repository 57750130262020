import moment from 'moment';
import { unionBy } from 'lodash';
import {
  ACTIVATE_INVOICE_FAILURE,
  ACTIVATE_INVOICE_REQUEST,
  ACTIVATE_INVOICE_SUCCESS,
  ADD_INVOICE_FAILURE,
  ADD_INVOICE_REQUEST,
  ADD_INVOICE_SUCCESS,
  ADD_SUBSCRIPTION_FAILURE,
  ADD_SUBSCRIPTION_REQUEST,
  ADD_SUBSCRIPTION_SUCCESS,
  BankFormModel,
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CLEAR_INVOICES,
  COPY_PAYMENT_LINK_FAILURE,
  COPY_PAYMENT_LINK_REQUEST,
  COPY_PAYMENT_LINK_SUCCESS,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICES,
  DELETE_PAYMENT_SUCCESS,
  DELETE_SUBSCRIPTIONS,
  InvoiceEntity,
  LOAD_CLIENT_INVOICES_DONE,
  LOAD_CLIENT_INVOICES_FAIL,
  LOAD_CLIENT_INVOICES_REQUEST,
  LOAD_INVOICES_DONE,
  LOAD_INVOICES_FAIL,
  LOAD_INVOICES_REQUEST,
  LOAD_SUBSCRIPTIONS_DONE,
  LOAD_SUBSCRIPTIONS_FAIL,
  LOAD_SUBSCRIPTIONS_REQUEST,
  PAY_INVOICE_FAILURE,
  PAY_INVOICE_REQUEST,
  PAY_INVOICE_SUCCESS,
  PaymentsActionTypes,
  PaymentsState,
  SEND_INVOICE_FAILURE,
  SEND_INVOICE_REQUEST,
  SEND_INVOICE_SUCCESS,
  SET_CAN_PREVIEW_INVOICE,
  SET_INVOICE_PREVIEW_STATUS,
  SubscriptionEntity,
  UPDATE_ACCOUNT_INFO,
  UPDATE_INVOICE_FAILURE,
  UPDATE_INVOICE_REQUEST,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICES,
  UPDATE_PAYMENT_FAIL,
  UPDATE_PAYMENT_START,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTIONS,
  VOID_INVOICE,
} from 'src/store/payments/types';
import { BaseEntity, BILLING_INTERVALS, BILLING_METHODS } from 'src/constants';

export const initialInvoice: InvoiceEntity = {
  id: '',
  entityStatus: '',
  getstreamId: '',
  metadata: {},
  ownerId: '',
  owner: null,
  identityId: '',
  createdDate: '',
  updatedDate: '',
  fields: {
    id: '',
    companyId: '',
    clientUserId: '',
    currency: '',
    collectionMethod: BILLING_METHODS.CHARGE_AUTOMATICALLY,
    dateOfIssue: moment().toISOString(),
    dueDate: moment().add(30, 'days').toISOString(),
    lineItems: [
      {
        id: 0,
        description: '',
        quantity: 1,
        rate: '',
        total: 0,
      },
    ],
    amount: 0,
    memo: '',
    dueDateCount: 30,
    createDraft: false,
    taxPercentage: 0,
    recipientId: '',
  },
  additionalFields: {
    id: '',
    templateName: '',
  },
};

export const initialSubscription: SubscriptionEntity = {
  id: '',
  entityStatus: '',
  ownerId: '',
  owner: null,
  identityId: '',
  createdDate: '',
  updatedDate: '',
  fields: {
    id: '',
    companyId: '',
    clientUserId: '',
    collectionMethod: BILLING_METHODS.CHARGE_AUTOMATICALLY,
    interval: BILLING_INTERVALS.MONTH,
    memo: '',
    taxPercentage: 0,
    daysUntilDue: 30,
    recipientId: '',
    cancelledAt: '',
    scheduleStartDate: moment(new Date()).unix(),
    lineItems: [
      {
        id: 0,
        description: '',
        quantity: 1,
        rate: '',
        total: 0,
      },
    ],
  },
  additionalFields: {
    templateName: '',
  },
};

export const initialBankForm: BankFormModel = {
  accountHolderName: '',
  accountHolderType: 'company',
  routingNumber: '',
  accountNumber: '',
  fromAmount: 0,
  toAmount: 0,
};

export const initialCardElement = {
  element: null,
  error: '',
};

export const initialBankElement = {
  element: initialBankForm,
  error: {},
  stripeError: '',
};

const initialState: PaymentsState = {
  invoices: [],
  clientInvoices: [],
  subscriptions: [],
  paymentInfo: {
    id: '',
    ownerId: '',
    customerId: '',
    accountType: '',
    subscriptionId: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
    createDate: '',
    businessName: '',
    accountId: '',
  },
  updatingPayment: false,
  updatingPaymentError: '',
  isActivating: false,
  isActivated: false,
  isLoaded: false,
  isLoadedForClient: false,
  isLoadingForClient: false,
  isLoading: false,
  isCreating: false,
  isCreated: false,
  isPaying: false,
  isPayed: false,
  isSubscriptionsLoading: false,
  isSubscriptionsLoaded: false,
  isSubscriptionsCanceling: false,
  isSubscriptionsCanceled: false,
  isSubscriptionCreating: false,
  isSubscriptionCreated: false,
  error: '',
  activatingError: '',
  isCopyingPaymentLink: false,
  canPreviewInvoice: false,
  invoicePreviewEnabled: false,
};

const makeOnNewAndUpdateEntityList = (
  ref: BaseEntity[],
  input: BaseEntity[],
) => {
  // create a map of itemId to updated item
  const updateItemIdObj: Record<string, BaseEntity> = input.reduce(
    (map, item) => ({
      ...map,
      [item.id]: item,
    }),
    {},
  );
  // for all existing items in state see if they are part of updated item map
  const updatedItems = ref.map((item) => {
    if (updateItemIdObj[item.id]) {
      // if item is part of updated item map then this should replace the item
      // in the existing state
      const updatedItem = updateItemIdObj[item.id];
      delete updateItemIdObj[item.id]; // remove item to track that it has been updated
      return updatedItem;
    }
    return item;
  });
  // only items remaining in updated map are new ones which can be added directly
  const newItems = Object.entries(updateItemIdObj).map(([, item]) => item);

  return [...updatedItems, ...newItems];
};

const makeOnDeleteEntityList = (ref: BaseEntity[], input: BaseEntity[]) => {
  const deletedIds = input.map(({ id }) => id);
  // remove items in deletedIds
  const updatedItems = ref.filter((item) => !deletedIds.includes(item.id));
  return [...updatedItems];
};

// eslint-disable-next-line default-param-last
const paymentsReducer = (state = initialState, action: PaymentsActionTypes) => {
  switch (action.type) {
    case LOAD_INVOICES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case LOAD_INVOICES_DONE: {
      const { payload } = action;

      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        // combine new invoices with existing ones
        // note: unionBy from the first array in which the value occurs
        invoices: unionBy(payload, state.invoices, 'id'),
        error: '',
      };
    }
    case LOAD_INVOICES_FAIL: {
      const { error } = action;
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error,
      };
    }
    case LOAD_CLIENT_INVOICES_REQUEST:
      return {
        ...state,
        clientInvoices: [],
        isLoadingForClient: true,
        error: '',
      };
    case LOAD_CLIENT_INVOICES_DONE: {
      const { payload } = action;

      return {
        ...state,
        isLoadingForClient: false,
        isLoadedForClient: true,
        invoices: payload,
        isLoaded: false,
        clientInvoices: payload,
        error: '',
      };
    }
    case LOAD_CLIENT_INVOICES_FAIL: {
      const { error } = action;
      return {
        ...state,
        isLoadingForClient: false,
        isLoadedForClient: false,
        error,
      };
    }
    case LOAD_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isSubscriptionsLoading: true,
        error: '',
      };
    case LOAD_SUBSCRIPTIONS_DONE: {
      const { payload } = action;

      return {
        ...state,
        isSubscriptionsLoading: false,
        isSubscriptionsLoaded: true,
        subscriptions: payload,
        error: '',
      };
    }
    case LOAD_SUBSCRIPTIONS_FAIL: {
      const { error } = action;
      return {
        ...state,
        isSubscriptionsLoading: false,
        isSubscriptionsLoaded: false,
        error,
      };
    }
    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isSubscriptionsCanceling: true,
        isSubscriptionsCanceled: false,
        error: '',
      };
    case CANCEL_SUBSCRIPTION_SUCCESS: {
      const { payload } = action;

      const updatedSubscriptions = state.subscriptions.map((subscription) => {
        if (subscription.id === payload.id) {
          return payload;
        }
        return subscription;
      });

      return {
        ...state,
        isSubscriptionsCanceling: false,
        isSubscriptionsCanceled: true,
        subscriptions: updatedSubscriptions,
        error: '',
      };
    }
    case CANCEL_SUBSCRIPTION_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isSubscriptionsCanceling: false,
        isSubscriptionsCanceled: false,
        error,
      };
    }

    case ADD_INVOICE_REQUEST:
      return {
        ...state,
        isCreating: true,
        isCreated: false,
        error: '',
      };
    case ADD_INVOICE_SUCCESS: {
      const { payload } = action;
      const newInvoices = state.invoices
        .filter((invoice) => invoice.id !== payload.id)
        .concat(payload);
      return {
        ...state,
        isCreating: false,
        isCreated: true,
        invoices: newInvoices,
        error: '',
      };
    }
    case ADD_INVOICE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        error,
      };
    }

    case ADD_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isSubscriptionCreating: true,
        isSubscriptionCreated: false,
        error: '',
      };
    case ADD_SUBSCRIPTION_SUCCESS: {
      const { payload } = action;
      const newSubscriptions = state.subscriptions
        .filter((subscription) => subscription.id !== payload.id)
        .concat(payload);
      return {
        ...state,
        isSubscriptionCreating: false,
        isSubscriptionCreated: true,
        subscriptions: newSubscriptions,
        error: '',
      };
    }
    case ADD_SUBSCRIPTION_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isSubscriptionCreating: false,
        isSubscriptionCreated: false,
        error,
      };
    }
    case UPDATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        error: '',
      };
    case UPDATE_SUBSCRIPTION_SUCCESS: {
      const { payload } = action;
      const updatedSubscriptions = state.subscriptions.map((subscription) => {
        if (subscription.id === payload.id) {
          return payload;
        }
        return subscription;
      });
      return {
        ...state,
        subscriptions: updatedSubscriptions,
        error: '',
      };
    }
    case UPDATE_SUBSCRIPTION_FAILURE: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }
    case PAY_INVOICE_REQUEST:
      return {
        ...state,
        isPaying: true,
        isPayed: false,
        error: '',
      };
    case PAY_INVOICE_SUCCESS: {
      const { payload } = action;

      const filteredInvoices = state.invoices.filter(
        (invoice) => invoice.id !== payload.id,
      );

      const currentInvoice = state.invoices.find(
        (invoice) => invoice.id === payload.id,
      );

      return {
        ...state,
        isPaying: false,
        isPayed: true,
        invoices: currentInvoice
          ? filteredInvoices.concat([
              {
                ...currentInvoice,
                additionalFields: payload,
              },
            ])
          : state.invoices,
        error: '',
      };
    }
    case PAY_INVOICE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isPaying: false,
        isPayed: false,
        error,
      };
    }
    case COPY_PAYMENT_LINK_REQUEST:
      return {
        ...state,
        isCopyingPaymentLink: true,
      };
    case COPY_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        isCopyingPaymentLink: false,
      };
    case COPY_PAYMENT_LINK_FAILURE:
      return {
        ...state,
        isCopyingPaymentLink: false,
        error: action.error,
      };
    case UPDATE_PAYMENT_START:
      return {
        ...state,
        updatingPayment: true,
      };
    case UPDATE_PAYMENT_SUCCESS: {
      const paymentInfo = action.data ? action.data : initialState.paymentInfo;
      return {
        ...state,
        updatingPayment: false,
        paymentInfo,
      };
    }
    case UPDATE_ACCOUNT_INFO: {
      return {
        ...state,
        accountInfo: action.payload,
      };
    }
    case DELETE_PAYMENT_SUCCESS: {
      return {
        ...state,
        updatingPayment: false,
      };
    }
    case UPDATE_PAYMENT_FAIL:
      return {
        ...state,
        updatingPayment: false,
        updatingPaymentError: action.error,
      };
    case CLEAR_INVOICES:
      return {
        ...state,
        invoices: [],
        isLoaded: false,
        isLoading: false,
      };
    case VOID_INVOICE: {
      // if delete is a void action then only need to
      const newInvoices = state.invoices.map((obj) =>
        obj.id !== action.payload
          ? obj
          : {
              ...obj,
              additionalFields: {
                ...obj.additionalFields,
                status: 'void',
              },
            },
      );

      return {
        ...state,
        invoices: newInvoices,
      };
    }
    case DELETE_INVOICE_SUCCESS: {
      const newInvoices = state.invoices.filter(
        (obj) => obj.id !== action.payload,
      );

      return {
        ...state,
        invoices: newInvoices,
      };
    }
    case UPDATE_INVOICES:
      return {
        ...state,
        invoices: makeOnNewAndUpdateEntityList(state.invoices, action.payload),
      };
    case UPDATE_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: makeOnNewAndUpdateEntityList(
          state.subscriptions,
          action.payload,
        ),
      };
    case DELETE_INVOICES:
      return {
        ...state,
        invoices: makeOnDeleteEntityList(state.invoices, action.payload),
      };
    case DELETE_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: makeOnDeleteEntityList(
          state.subscriptions,
          action.payload,
        ),
      };
    case UPDATE_INVOICE_REQUEST:
      return {
        ...state,
        updatingInvoice: true,
      };
    case UPDATE_INVOICE_SUCCESS: {
      const updatedInvoices = state.invoices
        .filter((invoice) => invoice.id !== action.invoiceId)
        .concat(action.payload);
      return {
        ...state,
        invoices: updatedInvoices,
        updatingInvoice: false,
      };
    }
    case UPDATE_INVOICE_FAILURE:
      return {
        ...state,
        updatingInvoice: false,
        updatingInvoiceError: action.error,
      };
    case SEND_INVOICE_REQUEST:
      return {
        ...state,
        sendingInvoice: true,
      };
    case SEND_INVOICE_SUCCESS: {
      const updatedInvoices = state.invoices.map((invoice) => {
        if (invoice.id === action.payload.id) {
          return {
            ...invoice,
            fields: {
              ...invoice.fields,
              status: action.payload.status,
            },
            additionalFields: {
              ...invoice.additionalFields,
              ...action.payload,
            },
          };
        }
        return invoice;
      });
      return {
        ...state,
        invoices: updatedInvoices,
        sendingInvoice: false,
      };
    }
    case SEND_INVOICE_FAILURE:
      return {
        ...state,
        sendingInvoice: false,
        sendingInvoiceError: action.error,
      };

    case ACTIVATE_INVOICE_REQUEST:
      return {
        ...state,
        isActivating: true,
        isActivated: false,
        activatingError: '',
      };
    case ACTIVATE_INVOICE_SUCCESS: {
      const { payload, originalInvoiceId } = action;

      // replace the data for the original invoice with the new invoice
      const updatedInvoices = state.invoices.map((invoice) => {
        if (invoice.id === originalInvoiceId) {
          return {
            ...invoice,
            id: payload.id,
            fields: {
              ...invoice.fields,
              id: payload.id,
            },
            additionalFields: payload,
          };
        }
        return invoice;
      });

      return {
        ...state,
        isActivating: false,
        isActivated: true,
        invoices: updatedInvoices,
        activatingError: '',
      };
    }
    case ACTIVATE_INVOICE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isActivating: false,
        isActivated: false,
        activatingError: error,
      };
    }
    case SET_CAN_PREVIEW_INVOICE: {
      const { payload } = action;
      return {
        ...state,
        canPreviewInvoice: payload,
      };
    }
    case SET_INVOICE_PREVIEW_STATUS: {
      const { payload } = action;
      return {
        ...state,
        invoicePreviewEnabled: payload,
      };
    }

    default:
      return state;
  }
};

export default paymentsReducer;
