import { Button } from '@material-ui/core';
import React, { useContext } from 'react';
import { FILES_PAGE, IMAGE_FORMATS } from 'src/constants';
import history from 'src/history';
import { NotificationTableRowData } from 'src/components/Inbox/InboxContent';
import { FlagsContext } from 'src/context';
import { FileUtils, S3Utils, UrlUtils } from 'src/utils';
import { getFileNameFromFileKey } from '../Files/helpers';

interface NotificationTableActionRendererProps {
  data: NotificationTableRowData;
}

const VIEWABLE_FILE_EXTENSIONS = ['pdf', ...IMAGE_FORMATS];
export const LINK_FILE_EXTENSION = 'link';

export const NotificationTableActionRenderer = (
  props: NotificationTableActionRendererProps,
) => {
  const { EnableNotificationFilePreview } = useContext(FlagsContext);
  const {
    data: {
      channelId,
      location,
      isDeleted,
      fileExtension,
      fileKey,
      fileIdentityId,
      fileUrl,
    },
  } = props;

  // hide the 'view' action button
  // when isDeleted is true
  const hideAction = isDeleted;

  const filePathParts = location
    .split('/')
    .filter((part: string) => part !== '');

  const baseUrl = `${FILES_PAGE.path}?channelId=${channelId}`;

  // this indicates whether the file can be previewed in the file preview page
  // e.g pdf, image files can be previewed
  const isViewableFile =
    fileExtension && VIEWABLE_FILE_EXTENSIONS.includes(fileExtension);

  const downloadFile = async () => {
    if (!fileKey) return;
    const downloadUrl = await S3Utils.getFile(fileKey, {
      identityId: fileIdentityId,
      level: 'protected',
    });
    await FileUtils.downloadFileFromUrl(
      downloadUrl,
      fileKey,
      getFileNameFromFileKey(fileKey),
    );
  };

  /**
   * This function handles the row action click event.
   * When it is a viewable file, it will open the file preview page.
   * When it is not a viewable file, it will download the file.
   */
  const handleRowActionClick = async () => {
    // legacy file open handler
    if (!EnableNotificationFilePreview) {
      let url = baseUrl;

      if (filePathParts && filePathParts.length > 0) {
        url = `${baseUrl}&selectedFilePath=${encodeURIComponent(location)}`;
      }

      history.push(url);
      return;
    }
    if (fileExtension == LINK_FILE_EXTENSION) {
      const fullUrl = UrlUtils.GetFullUrl(fileUrl || '');
      window.open(fullUrl, '_blank', 'noopener');
      return;
    }

    // if the file is a pdf, open it file preview page to preview it.
    if (isViewableFile && fileKey && fileIdentityId) {
      history.push(
        `/preview?fileKey=${encodeURIComponent(
          fileKey,
        )}&identityId=${encodeURIComponent(fileIdentityId)}`,
      );
      return;
    }

    await downloadFile();
  };

  /**
   * This function gets the action label based on the file extension.
   * e.g if the file is a pdf/image, the action label will be 'Open'
   * if the file is not a pdf, the action label will be 'Download'
   * @returns the action label based on the file extension
   */
  const getActionLabel = () => {
    if (!EnableNotificationFilePreview) return 'View';
    if (isViewableFile || fileExtension == LINK_FILE_EXTENSION) {
      return 'Open';
    }
    return 'Download';
  };

  if (hideAction) return null;

  return (
    <Button
      variant="contained"
      color="secondary"
      className="notification-table-action"
      onClick={handleRowActionClick}
      data-testid="notification-table-action"
    >
      {getActionLabel()}
    </Button>
  );
};
