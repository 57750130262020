import { useFormikContext } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FileWithPreview } from 'react-dropzone';
import { useDropzone } from 'react-dropzone-latest';
import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useProgressUpload } from 'src/hooks/useProgressUpload';
import { BlackHeadings, GraySmall, NonHoverBorder } from 'src/theme/colors';
import { S3Utils } from 'src/utils';
import Button from 'src/components/Button';
import { DropzoneFilesIcon } from 'src/components/Icons';
import { typography12MediumStyle } from 'src/components/Text';
import { typography13RegularStyle } from 'src/components/Text/BaseTypography';
import { CreateButton } from 'src/components/UI';
import { CreateButtonCombinedProps } from 'src/components/UI/Buttons/CreateButton';
import { FormBuilderPreviewContext } from 'src/components/FormsV2/FormBuilderPreview';
import { getFullTestDataFileURL } from 'src/utils/TestDataUtil';
import {
  FileInputMetaData,
  FileInputUploadItem,
} from 'src/components/UI/FileUploadInputItem';

// File size for example file used in test example response.
// For Forms V1, we've been passing fake file size from API.
// For Forms V2, we're using this constant.
const TEST_FILE_SIZE = 1000000;

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #ccc',
    padding: 26,
    gap: 16,
    borderRadius: 4,
  },
  fileItemsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    flexWrap: 'wrap',
  },
  createIcon: {
    borderColor: NonHoverBorder,
  },
  noFiles: {
    ...typography13RegularStyle,
    color: GraySmall,
    padding: theme.spacing(0, 1.5),
    border: `1px solid ${NonHoverBorder}`,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    height: 36,
  },
}));

/**
 * This component renders the add more file button that show
 * when there are existing input files that have been dropped
 * to the question file input dropzone.
 * @param props: CreateButtonCombinedProps
 * @returns {JSX.Element}: CreateButton
 */
const AddMoreFileButton = (props: CreateButtonCombinedProps) => {
  const classes = useStyles();
  return <CreateButton className={classes.createIcon} {...props} iconOnly />;
};

type QuestionFileDropzoneProps = {
  questionId: string;
  fieldName: string;
  value?: string | string[];
  readOnly?: boolean;
  identityId?: string;
  formId?: string;
};

/**
 * This component is responsible of rendering the file question
 * answer input.
 * @param questionId: string - questionId  will be used to create the file s3 path
 * @param fieldName: string - indicates the question response value field name.
 * @param value: string array - holds the file question response value.
 * @returns {JSX.Element} The file question answer input component
 * @todo: @Monfernape refactor this component to two components, one for file upload and other for list files for better readability
 */
export const QuestionFileDropzone = ({
  questionId,
  fieldName,
  value: selectedFileKeys,
  readOnly = false,
  identityId,
  formId,
}: QuestionFileDropzoneProps) => {
  const classes = useStyles();
  const formikContext = useFormikContext();
  const formBuilderPreviewContext = useContext(FormBuilderPreviewContext);
  const isFormBuilderPreviewMode =
    formBuilderPreviewContext && formBuilderPreviewContext.isFormBuilderPreview;
  const { setFieldValue } = formikContext || {};
  const { setUploadProgress, startUploadFiles, exitUploadFiles, isUploading } =
    useProgressUpload();

  // this stores the input files meta data (file name, size, extension ...)
  const [inputFilesInfo, setFilesInfo] = useState<
    Array<Omit<FileInputMetaData, 'readOnly'>>
  >([]);

  // to get the file input initial value, we need to get the
  const getSelectedFilesInfoFromS3 = async () => {
    let fileKeys = selectedFileKeys || [];
    if (typeof fileKeys == 'string') {
      fileKeys = [fileKeys];
    }
    const isExampleTestData = fileKeys.some((x) =>
      x?.startsWith('public/testdata'),
    );
    if (isExampleTestData) {
      const infos = fileKeys.map((f) => {
        const fileName = f?.split('/').pop() || '';
        const ext = fileName?.split('.').pop() || '';
        return {
          name: fileName,
          size: TEST_FILE_SIZE,
          extension: ext,
          fileKey: f as string,
        };
      });

      setFilesInfo(infos);
    } else {
      const listFilesResponse = await S3Utils.listFiles(
        `forms/${formId}/${questionId}/`,
        {
          identityId,
          level: 'protected',
        },
      );
      const selectedFiles = listFilesResponse.results;
      if (!selectedFiles.length) return;

      const infos = selectedFiles
        .filter((s) => selectedFileKeys?.includes(s?.key as string))
        .map((f) => {
          const fileName = f.key?.split('/').pop() || '';
          const ext = fileName?.split('.').pop() || '';
          return {
            name: fileName,
            size: f.size || 0,
            extension: ext,
            fileKey: f.key,
          };
        });

      setFilesInfo(infos);
    }
  };

  // when selected files value changes, this hook will get their
  // meta data from S3.
  useEffect(() => {
    getSelectedFilesInfoFromS3();
  }, [selectedFileKeys]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const files = await S3Utils.uploadFilesToS3({
        acceptedFiles,
        targetPath: '',
        startUploadFiles,
        exitUploadFiles,
        setUploadProgress,
        filePathToS3: `forms/${formId}/${questionId}`,
      });
      // remove redundant file keys
      const uploadedFileKeys = files.reduce<FileWithPreview[]>((acc, file) => {
        if (!acc.includes(file.key)) {
          acc.push(file.key);
        }
        return acc;
      }, []);

      if (setFieldValue) {
        // save the uploaded file keys to the form state
        setFieldValue(fieldName, [
          ...(selectedFileKeys || []),
          ...uploadedFileKeys,
        ]);
      }
    },
    [selectedFileKeys],
  );

  /**
   *  This function is responsible of handling the file download when the user clicks on the file item.
   * @param fileKey - the file key to be downloaded
   */
  const handleDownloadFile = async (fileKey: string) => {
    const isExampleTestData = fileKey?.startsWith('public/testdata');
    let fileUrlFromS3 = '';
    try {
      if (isExampleTestData) {
        fileUrlFromS3 = getFullTestDataFileURL(fileKey);
      } else {
        fileUrlFromS3 = await S3Utils.getFile(fileKey, {
          identityId,
          level: 'protected',
        });
      }

      if (fileUrlFromS3) {
        window.open(fileUrlFromS3, '_blank', 'noopener');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileRemove = (fileIndex: number) => {
    const newSelectedFiles = [...inputFilesInfo];

    newSelectedFiles.splice(fileIndex, 1);
    const newFileKeys = newSelectedFiles.map((f) => f.fileKey);

    setFieldValue(fieldName, newFileKeys); // update the file question field state
    setFilesInfo(newSelectedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    open: openFilePicker,
  } = useDropzone({
    onDrop,
    noClick: true,
  });

  // if the user is in view response mode, and there are no uploaded files yet, show a message to the user that no files were uploaded
  if (readOnly && !selectedFileKeys?.length && !isFormBuilderPreviewMode)
    return <div className={classes.noFiles}>No files were uploaded</div>;

  return (
    <div
      {...getRootProps()}
      className={clsx({
        // dropzone container styles applies only when there are
        // no uploaded files yet, and it is not view response mode (!readOnly)
        // or when it is form builder preview mode.
        [classes.dropzone]:
          (!inputFilesInfo.length && !readOnly) || isFormBuilderPreviewMode,
      })}
    >
      {inputFilesInfo.length ? (
        <div className={classes.fileItemsWrapper}>
          {inputFilesInfo.map((fileInfo, index) => (
            <FileInputUploadItem
              key={fileInfo.fileKey}
              onClick={() => {
                handleDownloadFile(fileInfo.fileKey || '');
              }}
              onRemove={() => handleFileRemove(index)}
              readOnly={readOnly}
              {...fileInfo}
            />
          ))}
          <input {...getInputProps()} />
          {
            // show the add more file button only when it is not view response mode (!readOnly)
            !readOnly && (
              <AddMoreFileButton
                htmlId="upload-question-files-button"
                color="secondary"
                variant="contained"
                onClick={openFilePicker}
                disabled={isUploading}
              />
            )
          }
        </div>
      ) : (
        // show upload file empty state only when there are no uploaded files yet
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!readOnly || isFormBuilderPreviewMode ? (
            <>
              <input {...getInputProps()} />
              <DropzoneFilesIcon
                style={{
                  fontSize: 60,
                }}
              />
              <Button
                htmlId="upload-question-files-button"
                variant="contained"
                color="secondary"
                onClick={openFilePicker}
                size="medium"
                isLoading={isUploading}
                progressStyles={{
                  color: BlackHeadings,
                }}
                disabled={isFormBuilderPreviewMode} // disable the upload button in form builder preview mode
              >
                Choose a file
              </Button>

              <span
                style={{
                  ...typography12MediumStyle,
                  color: GraySmall,
                }}
              >
                Or drop a file to upload
              </span>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};
