import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getPublicImageURL } from 'src/utils/ImageUtils';
import { Action, BaseMenu } from 'src/components/Dropdowns';
import { LockOpenIcon, PlusIcon, LogoutIcon } from 'src/components/Icons';
import Button from 'src/components/Button';
import history from 'src/history';
import {
  PortalConfigContext,
  SidebarThemeContext,
  SidebarTheme,
} from 'src/context';
import { AuthenticatedPortalDataType } from 'src/components/Onboarding/Login/onboardingLoginTypes';
import { RootState } from 'src/store/reduxTypes';
import { useLogout } from 'src/hooks/useLogout';
import { useAppSelector } from 'src/hooks/useStore';
import UsersClient from 'src/clients/UsersClient';
import { LOGO_SIZE } from 'src/components/Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      width: '100%',
      height: '36px',
      padding: theme.spacing(0.5, 1),
      '&:hover': {
        backgroundColor: (props: SidebarTheme) =>
          props.sidebarItemHoverBackground,
      },
      justifyContent: 'flex-start',
    },
    active: {
      backgroundColor: (props: SidebarTheme) =>
        props.sidebarItemHoverBackground,
    },
    workspacesPadding: {
      paddingLeft: '8px',
    },
  }),
);
export const SidebarLogoMenu: React.FC<{
  workspaces: Record<string, Record<string, AuthenticatedPortalDataType>>;
}> = ({ children, workspaces }) => {
  const portalConfig = React.useContext(PortalConfigContext);
  const sidebarTheme = React.useContext(SidebarThemeContext);
  const classes = useStyles(sidebarTheme);
  const userIsClient = useSelector((state: RootState) => state.user.isClient);
  const isTestUser =
    useAppSelector((state) => state.user.data?.isTestUser) ?? false;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [menuActions, setMenuActions] = React.useState<Action[]>([]);
  const accounts = Object.keys(workspaces).reduce(
    (acc, key) => acc + Object.keys(workspaces[key]).length,
    0,
  );
  const logout = useLogout();
  /**
   * This function gets the list of workspaces from the workspaces object
   * @param wp: workspaces object
   * @returns
   */
  const getListOfWorkspaces = (
    wp: Record<string, Record<string, AuthenticatedPortalDataType>>,
  ) =>
    Object.entries(wp).map(([email, workspacesMap]) =>
      Object.entries(workspacesMap).map(([, workspace]) => ({
        ...workspace,
        email,
      })),
    );

  // getWorkspaceActions sorts all user's workspaces by email to properly divide them in the UI
  const getWorkspaceActions = (
    spaces: {
      name: string;
      onClick: () => void;
      icon: JSX.Element;
      email: string;
    }[],
  ) => {
    const sortedWs: Action[] = [];
    let currentEmail = '';
    spaces.forEach((ws) => {
      if (currentEmail !== ws.email) {
        // add divider to last portal under current email
        if (sortedWs[sortedWs.length - 1]) {
          sortedWs[sortedWs.length - 1].hasDivider = true;
        }
        // add email item
        sortedWs.push({
          name: ws.email,
          isSubtitle: true,
          classes: [classes.workspacesPadding],
        } as Action);
        currentEmail = ws.email;
      }
      sortedWs.push({
        ...ws,
        classes: [classes.workspacesPadding],
      });
    });
    if (sortedWs[sortedWs.length - 1]) {
      // add divider to last item
      sortedWs[sortedWs.length - 1].hasDivider = true;
    }
    return sortedWs;
  };

  const logoutAction = {
    name: accounts > 1 ? 'Log out of all accounts' : 'Log out',
    icon: <LogoutIcon style={{ fontSize: 16 }} />,
    onClick: () =>
      logout(
        isTestUser,
        !userIsClient ? '/login' : portalConfig.SignoutRedirectURL,
        userIsClient,
      ),
    iconStyle: {
      height: 28,
      width: 28,
    },
    classes: [classes.workspacesPadding],
  };

  /**
   * When the sidebar menu mounts, we get the list of workspaces and set the menu actions
   */
  React.useEffect(() => {
    const defaultActions = !userIsClient
      ? [
          {
            name: 'Log in to another workspace',
            icon: <LockOpenIcon style={{ fontSize: 16 }} />,
            onClick: () => {
              // use window location assign to force a navigate and reload
              // this is needed to ensure that the portalConfig context is updated
              // to reflect the workspaces page rather than the current portal
              history.replace('/add-account');
              window.location.reload();
            },
            iconStyle: {
              height: 28,
              width: 28,
            },
            classes: [classes.workspacesPadding],
          },
          {
            name: 'Create a new workspace',
            icon: <PlusIcon style={{ fontSize: 16 }} />,
            onClick: () => {
              history.replace('/workspaces');
              window.location.reload();
            },
            iconStyle: {
              height: 28,
              width: 28,
            },
            classes: [classes.workspacesPadding],
          },
          logoutAction,
        ]
      : [logoutAction];

    const workspacesActions = getListOfWorkspaces(workspaces)
      .flat()
      .filter((workspace) => workspace.id !== portalConfig.portalHeader) // exclude current portal from workspaces list
      .map(({ name, id, iconUrl, email, hostname }) => ({
        name,
        onClick: async () => {
          const result = await UsersClient.setExtraPortalSession(
            id,
            hostname,
            email,
          );
          window.localStorage.setItem(
            `CognitoIdentityServiceProvider.${portalConfig.AWS.Auth.userPoolWebClientId}.LastAuthUser`,
            result.data.userId,
          );
          history.replace(result.data.redirectUrl);
          // reload the page to get the new portal data
          window.location.reload();
        },
        icon: (
          <img
            width={LOGO_SIZE}
            height={LOGO_SIZE}
            style={{ borderRadius: '4px' }}
            src={getPublicImageURL(iconUrl)}
            alt="logo"
          />
        ),
        email,
      }));

    setMenuActions([
      ...getWorkspaceActions(workspacesActions),
      ...defaultActions,
    ]);
  }, [workspaces]);

  return (
    <>
      <Button
        className={clsx(classes.menuButton, { [classes.active]: !!anchorEl })}
        data-testid="sidebar-logo-menu"
        htmlId="sidebar-logo-menu"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {children}
      </Button>
      <BaseMenu
        actions={menuActions}
        menuProps={{
          style: { left: '-8px' },
          id: 'actions-menu',
          'data-testid': 'workspaces-menu',
          anchorEl,
          getContentAnchorEl: null,
          keepMounted: true,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null),
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      />
    </>
  );
};
