import { Events } from 'src/constants/webSocketConsts/events';
import { handleSignOut } from 'src/utils/UserUtils';
import { PortalConfig } from 'src/context';
import {
  addInternalUsers,
  modifyInternalUsers,
  removeInternalUsers,
} from 'src/store/users/actions';
import { ensureUnreachable } from 'src/utils/CommonUtils';

const InternalUserHandler = (
  event: Events,
  items: Array<any>,
  portalConfig: PortalConfig,
  userID: string,
) => {
  switch (event) {
    case Events.Insert:
      return addInternalUsers(items);
    case Events.Modify:
      return modifyInternalUsers(items);
    case Events.Remove:
      if (items.map((item) => item.id).includes(userID)) {
        handleSignOut(portalConfig, false);
      }
      return removeInternalUsers(items);
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default InternalUserHandler;
