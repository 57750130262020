import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SuccessIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <circle cx="8" cy="8" r="8" fill="#1E9A50" />
    <path
      d="M6.61246 9.57257L4.78479 7.67749L4 8.49123L6.61246 11.2L12 5.61379L11.2152 4.80005L6.61246 9.57257Z"
      fill="white"
    />
  </SvgIcon>
);
