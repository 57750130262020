import moment from 'moment';

const FirstPeriodStartDate = 1;
const FirstPeriodEndDate = 15;
const SecondPeriodStartDate = FirstPeriodEndDate + 1;
const golangZeroDateStr = '0001-01-01T00:00:00Z';

const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;
const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
const SECONDS_PER_MONTH = SECONDS_PER_DAY * 30;
const SECONDS_PER_YEAR = SECONDS_PER_MONTH * 12;

export default class DateUtils {
  static getDateRangeString(startDateStr: string, endDateStr: string) {
    const startDate = startDateStr ? new Date(startDateStr) : null;
    const endDate = endDateStr ? new Date(endDateStr) : null;

    if (startDate && endDate) {
      if (this.getMonthDayStr(startDate) === this.getMonthDayStr(endDate)) {
        return this.getMonthDayYearStr(startDate);
      }

      return `${this.getMonthDayStr(startDate)} to ${this.getMonthDayYearStr(
        endDate,
      )}`;
    }

    if (startDate) {
      return this.getMonthDayYearStr(startDate);
    }

    return '';
  }

  static getMonthDayStr(date: Date) {
    return date.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'short',
      timeZone: 'UTC',
    });
  }

  static getMonthDayYearStr(date: Date) {
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      day: 'numeric',
      month: 'short',
      timeZone: 'UTC',
    });
  }

  static getPeriodStrFromDate(date: Date) {
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let periodStartDate = SecondPeriodStartDate;
    let periodEndDate = endOfMonth.getDate();
    if (date.getDate() <= FirstPeriodEndDate) {
      periodStartDate = FirstPeriodStartDate;
      periodEndDate = FirstPeriodEndDate;
    }

    const monthYearStr = date.toLocaleDateString(undefined, {
      month: 'short',
      year: '2-digit',
    });

    return `${periodStartDate}-${periodEndDate} ${monthYearStr}`;
  }

  static dateComparator = (param1: string, param2: string) => {
    const dateA = new Date(param1).getTime();
    const dateB = new Date(param2).getTime();
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  };

  static formatDate(date: string, format: string) {
    return moment(date).format(format);
  }

  static humanizeDate(date: string | undefined, calendarFormat?: any) {
    if (!date) {
      return '';
    }

    const defaultCalendarFormat = {
      sameDay: 'h:mm A',
      nextDay: '[Tomorrow at] h:mm A',
      nextWeek: 'dddd [at] h:mm A',
      lastDay: '[Yesterday] h:mm A',
      lastWeek: 'dddd h:mm A',
      sameElse: 'MMM Do YY',
    };
    return moment(date).calendar(null, {
      ...defaultCalendarFormat,
      ...(calendarFormat || {}),
    });
  }

  /**
   * returns label for last login section according to user's current status and last login value
   * @param lastLoginDate user's last login date
   * @param userStatus users's current status 'Active', 'Not Invited', 'Invited', 'Pending Verification', 'N/A'
   */
  static getLastLoginLabel(
    lastLoginDate: string | undefined,
    userStatus: string,
  ) {
    if (!this.isEmptyDateStr(lastLoginDate) && userStatus === 'Active') {
      return this.humanizeDate(lastLoginDate);
    }
    return 'NA';
  }

  // this function get difference in unit of time between now date and a given date
  static getDiffFromDate(date: Date, unitOfTime: moment.unitOfTime.Diff) {
    const now = moment();

    return now.diff(date, unitOfTime);
  }

  // returns the difference in days between today's date and date passed in
  static getDaysDiffOfDate(date: Date): number {
    return moment(moment.now()).diff(moment(date), 'days');
  }

  /**
   * Checks if date string is empty. Check if date string has value
   * or if it is equal to golang zero date string. This check is
   * needed because golang marshal does not omit empty for zero dates
   * @param dateStr date string to verify if empty
   * @returns boolean if date string is empty
   */
  static isEmptyDateStr(dateStr?: string) {
    return !dateStr || dateStr === golangZeroDateStr;
  }

  /**
   * This method is used to calculate the time since a date
   * @param date
   */
  static getTimeFromNow = (date: Date, now: Date) => {
    if (typeof date !== 'object') {
      // eslint-disable-next-line no-param-reassign
      date = new Date(date);
    }

    const seconds = Math.floor((now.valueOf() - date.valueOf()) / 1000);
    let intervalType;

    let interval = Math.floor(seconds / SECONDS_PER_YEAR);
    if (interval >= 1) {
      intervalType = 'y';
    } else {
      interval = Math.floor(seconds / SECONDS_PER_MONTH);
      if (interval >= 1) {
        intervalType = 'mo';
      } else {
        interval = Math.floor(seconds / SECONDS_PER_DAY);
        if (interval >= 1) {
          intervalType = 'd';
        } else {
          interval = Math.floor(seconds / SECONDS_PER_HOUR);
          if (interval >= 1) {
            intervalType = 'h';
          } else {
            interval = Math.floor(seconds / SECONDS_PER_MINUTE);
            if (interval >= 1) {
              intervalType = 'm';
            } else {
              interval = seconds;
              intervalType = 'now';
            }
          }
        }
      }
    }
    if (seconds < SECONDS_PER_MINUTE) {
      return intervalType;
    }

    return `${interval || ''}${intervalType}`;
  };
}
// TODO: convert this class to a module
export const { getTimeFromNow } = DateUtils;
