import React from 'react';
import { Box, makeStyles, Theme, createStyles } from '@material-ui/core';
import { DRAWER_WIDTH, SETTINGS_PAGE_MAX_WIDTH } from 'src/constants/uiConsts';
import { SettingsPageRow } from '.';
import { SettingsPageToolbar } from 'src/components/Settings/SettingsPageToolbar';
import { SettingsPageToolbarContext } from 'src/context/settingsPageToolbarContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settingsPageContent: {
      height: 'auto',
      width: '100%',
      maxWidth: SETTINGS_PAGE_MAX_WIDTH,
      margin: '0 auto',
      // mobile devices
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(5, 2),
        overflowY: 'auto',
      },
      // tablet devices
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 4.5),
      },
      // desktop devices
      [theme.breakpoints.up('md')]: {
        paddingLeft: `${DRAWER_WIDTH + theme.spacing(10)}px`,
        paddingTop: theme.spacing(5),
      },
    },
  }),
);
export const SettingsPageContent: React.FC = ({ children }) => {
  const classes = useStyles();
  const [toolbarActions, setToolbarActions] =
    React.useState<React.ReactNode>(null);

  const toolbarContextValue = React.useMemo(
    () => ({ toolbarActions, setToolbarActions }),
    [toolbarActions],
  );

  return (
    <Box className={classes.settingsPageContent}>
      <SettingsPageToolbarContext.Provider value={toolbarContextValue}>
        <SettingsPageRow fullHeight description={<SettingsPageToolbar />}>
          {children}
        </SettingsPageRow>
      </SettingsPageToolbarContext.Provider>
    </Box>
  );
};
