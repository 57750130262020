import { Auth, Logger } from 'aws-amplify';
import Stripe from 'stripe';
import { AxiosError } from 'axios';
import { API } from 'src/utils/AmplifyApiUtils';
import { getUserRef, hasPermission } from 'src/utils/UserUtils';
import { PERM_MOD_SETTINGS_PLANS } from 'src/constants/permissionConsts';
import { DefaultCRMColumnSettings } from 'src/constants';
import { AppThunkAction } from 'src/store/reduxTypes';
import PaymentsClient from 'src/clients/PaymentsClient';
import UsersClient, { LoadUserDataResponse } from 'src/clients/UsersClient';
import { setResourcesAction } from 'src/store/data/actions';
import {
  setClientsAction,
  setCompaniesAction,
} from 'src/store/clients/actions';
import * as UserStoreTypes from 'src/store/user/types';
import * as UsersStoreTypes from 'src/store/users/types';
import { setFileChannelsAction } from 'src/store/files/actions';
import { setExtensionItemsAction } from 'src/store/dashboard/actions';
import {
  alertSnackbar,
  showTrialBannerAction,
  clearBannerAction,
} from 'src/store/ui/actions';
import * as PlansUtils from 'src/utils/PlansUtils';
import { CallApiWithNotification } from 'src/clients/ApiService';
import ApiUtils from 'src/utils/ApiUtils';
import { setNotificationsAction } from 'src/store/notifications/actions';
import { isUnAuthUser } from 'src/store/user/reducers';
import { setInternalUsers, SetUsersAction } from 'src/store/users/actions';
import {
  loadPortalSettingsAction,
  setMessageSettingsAction,
} from 'src/store/settings/actions';
import {
  setCrmTablePropertiesFieldsAction,
  setCrmTablePropertiesIdAction,
} from 'src/store/tableProperties/actions';
import { GetCurrentUserFromState } from 'src/store/storeUtils';
import { OnboardingStatus } from 'src/entities/OnboardingStatus';
import { AuthErrors } from 'src/constants/errorConsts/errorCodesConsts';

const logger = new Logger('UserStoreTypes');

export const updateViewMode = (mode: string) => ({
  type: UserStoreTypes.UPDATE_VIEW_MODE,
  mode,
});

export const updateUserId =
  (id: string): AppThunkAction =>
  (dispatch) => {
    if (!id) {
      return Promise.resolve();
    }

    return dispatch({
      type: UserStoreTypes.UPDATE_USER_ID,
      id,
    });
  };

export const clearUser = (): AppThunkAction => async (dispatch) => {
  dispatch({
    type: UserStoreTypes.CLEAR_USER,
  });
};

export const setUserLoadingErrorAction = (error: string) => ({
  type: UserStoreTypes.LOAD_USER_ERROR,
  error,
});

export const updateUserAttributes =
  (
    updatedAttributes: object,
    updateOptions?: {
      disableApiFailureNotification?: boolean;
    },
  ): AppThunkAction =>
  async (dispatch, getState) => {
    const { disableApiFailureNotification } = updateOptions || {};
    const state = getState();
    const currentUser = GetCurrentUserFromState(state);
    const { user } = state;
    if (!user) {
      return;
    }

    function updateComplete(attributes?: object) {
      dispatch({
        type: UserStoreTypes.UPDATED_USER_ATTRIBUTE,
        attributes,
      });
    }

    function updateUserInMembers(payload: any) {
      dispatch({
        type: UsersStoreTypes.UPDATE_MEMBER_SUCCESS,
        payload,
      });
    }

    dispatch({ type: UserStoreTypes.UPDATING_USER_ATTRIBUTE });

    const fields = {
      ...updatedAttributes,
      companyId: currentUser?.fields.companyId,
      companyName: currentUser?.fields.companyName,
    };

    try {
      const result = await CallApiWithNotification({
        executeFunction: UsersClient.updateProfileAttributes,
        checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
        params: {
          userId: currentUser?.id,
          isClient: user.isClient,
          fields,
        },
        successMessage: 'Profile updated.',
        errorMessage: `The changes on the profile could not be updated.`,
        dispatch,
        disableApiFailureNotification,
      });

      if (result.status) {
        if (currentUser) {
          updateUserInMembers({
            ...currentUser,
            fields: {
              ...currentUser.fields,
              ...fields,
            },
          });
        }
        updateComplete(updatedAttributes);
      } else {
        logger.warn('Failed to update user attributes', result.data);
        updateComplete();
      }
    } catch (error) {
      if ((error as Error).message.includes('AliasExistsException')) {
        dispatch(
          alertSnackbar({
            errorMessage: AuthErrors.emailAlreadyInUse,
          }),
        );
      }
    }
  };

export const setTrialState =
  (
    stripeSubscriptions?: Stripe.Subscription[],
    onboardingStatus?: OnboardingStatus,
  ): AppThunkAction =>
  async (dispatch) => {
    if (!stripeSubscriptions) {
      return;
    }

    const trialSubscription =
      PlansUtils.activeTrialSubscription(stripeSubscriptions);

    const showTrialBanner = PlansUtils.hasActiveTrial(stripeSubscriptions);
    if (showTrialBanner) {
      const trialText = PlansUtils.getTrialDaysRemainingText({
        subscription: trialSubscription,
        onboardingTaskStatus: onboardingStatus,
      });
      if (trialText) {
        dispatch(showTrialBannerAction(trialText));
      }
    } else {
      dispatch(clearBannerAction());
    }
  };

export const loadUserData =
  (userId: string, authUser: UserStoreTypes.CognitoUser): AppThunkAction =>
  async (dispatch) => {
    let loadUserResponse: LoadUserDataResponse;
    try {
      loadUserResponse = await UsersClient.loadUserData(userId);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response || err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        dispatch(setUserLoadingErrorAction('Request failed'));
        console.error('failed to load user', err.response);
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(setUserLoadingErrorAction('Connection error'));
        console.error('connection failed loading user', err);
      }
      throw error;
    }

    const {
      companies,
      users,
      resources,
      fileChannels,
      notifications,
      internalUsers,
      messageSettings,
      portalSettings,
      extensionItems,
      crmTableProperties,
      ...data
    } = loadUserResponse.data;

    const isUnAuth = isUnAuthUser(userId);
    // set isClient true for un auth user or if load data response has value. if response value is undefined, set to false
    const isClient = (isUnAuth || data.isClientUser) ?? false;

    if (internalUsers) {
      dispatch(setInternalUsers(internalUsers));
    }
    if (isClient) {
      dispatch(SetUsersAction(users));
    } else {
      dispatch(
        setClientsAction({
          clients: users,
          userId,
          isClient,
        }),
      );
    }

    dispatch(setCompaniesAction(companies));
    dispatch(setFileChannelsAction(fileChannels));
    dispatch(setResourcesAction(resources));
    dispatch(setExtensionItemsAction(extensionItems));
    dispatch(setNotificationsAction(notifications));
    const userHasPlanPermission = hasPermission(
      PERM_MOD_SETTINGS_PLANS,
      data.permissions,
    );
    dispatch(
      setTrialState(
        userHasPlanPermission ? data.stripeSubscriptions : undefined,
      ),
    );
    if (portalSettings) {
      dispatch(loadPortalSettingsAction([portalSettings]));
    }

    if (messageSettings) {
      dispatch(setMessageSettingsAction([messageSettings]));
    }

    if (crmTableProperties && crmTableProperties.structFields) {
      dispatch(
        setCrmTablePropertiesFieldsAction(crmTableProperties.structFields),
      );
      dispatch(setCrmTablePropertiesIdAction(crmTableProperties.id));
    } else {
      dispatch(setCrmTablePropertiesFieldsAction(DefaultCRMColumnSettings));
    }

    const { userId: dbUserId } = data; // get db record userId to look up current user
    dispatch({
      type: UserStoreTypes.UPDATE_USER,
      user: {
        username: userId,
        attributes: authUser.attributes || {}, // fallback to empty attributes for unAuth cognito user
        ref: getUserRef(dbUserId, internalUsers),
      },
      userId,
      data,
    });
  };

export const resetUserLoadedAction = () => ({
  type: UserStoreTypes.CHANGE_USER_LOADED_STATE,
  loaded: false,
});

export const clearData = (): AppThunkAction => async (dispatch) => {
  dispatch(resetUserLoadedAction());
};

export const changeUserPassword =
  (oldPassword: string, newPassword: string): AppThunkAction =>
  async (dispatch) => {
    try {
      const authUser = await UsersClient.getAuthUser({ getCognitoUser: true });
      const res = await Auth.changePassword(authUser, oldPassword, newPassword);
      if (res && res === 'SUCCESS') {
        dispatch(
          alertSnackbar({
            successMessage: 'Your password was updated successfully.',
          }),
        );
      }
    } catch (error) {
      const e = error as AxiosError;
      dispatch(
        alertSnackbar({
          axiosError: e.response,
          errorMessage: 'We were unable to update your password.',
        }),
      );
    }
  };

export const addUserPassword =
  (userId: string, newPassword: string, isClient: boolean): AppThunkAction =>
  async (dispatch) => {
    CallApiWithNotification({
      executeFunction: (params) => UsersClient.addPassword(params, isClient),
      params: { userId, newPassword, isClient },
      successMessage: `Password was added successfully.`,
      errorMessage: `Password was not set.`,
      dispatch,
    });
  };

export const initializeOnboardingTestData = async () => {
  try {
    await API.post('AppAPI', `/onboarding/initialize/testdata`, {
      body: {},
    });
  } catch (err) {
    logger.debug('Failed to initialize onboarding test data');
  }
};

export const updateCustomerSubscriptionAction = (
  subscription: Stripe.Subscription,
) => ({
  type: UserStoreTypes.UPDATE_USER_SUBSCRIPTION_DONE,
  subscription,
});

export const changeBillingPlan =
  (priceId: string): AppThunkAction =>
  async (dispatch) => {
    dispatch({
      type: UserStoreTypes.UPDATING_USER_SUBSCRIPTION,
      updating: true,
    });

    const result = await CallApiWithNotification<Stripe.Subscription>({
      executeFunction: PaymentsClient.changePlan,
      params: priceId,
      successMessage: '',
      errorMessage: `Your plan could not be changed. Please contact support.`,
      dispatch,
    });

    if (result.status) {
      dispatch(clearBannerAction());
      dispatch(updateCustomerSubscriptionAction(result.data));
    } else {
      dispatch({
        type: UserStoreTypes.UPDATING_USER_SUBSCRIPTION,
        updating: false,
      });
    }

    return result;
  };

export const setStripeCustomerAction = (stripeCustomer: Stripe.Customer) => ({
  type: UserStoreTypes.SET_STRIPE_CUSTOMER,
  stripeCustomer,
});

export const loadStripeCustomerAction =
  (customerId: string): AppThunkAction =>
  async (dispatch) => {
    try {
      const result = await UsersClient.getCustomer(customerId);
      dispatch(setStripeCustomerAction(result.data));
    } catch (err) {
      logger.debug('Failed to load client');
    }
  };
