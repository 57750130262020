import { createStyles, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import {
  INBOX_PAGE_EMPTY_STATE_LEARN_MORE,
  NOTIFICATIONS_PAGE_EMPTY_STATE_LEARN_MORE,
} from 'src/constants/modulesEmptyStates';
import { useGetInboxNotificationsQuery } from 'src/services/api/inboxApi';
import { NotificationIcon } from 'src/components/Icons';
import StandardPage from 'src/components/UI/StandardPage';
import { FlagsContext } from 'src/context';
import { useNotificationsCounts } from 'src/hooks/useNotificationsCount';
import { useAppSelector } from 'src/hooks/useStore';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  }),
);

export const InboxEmptyState = () => {
  const classes = useStyles();
  const { data: allInboxNotifications } = useGetInboxNotificationsQuery();
  const { EnableFormsNotificationCenter, EnableInvoicesNotificationCenter } =
    useContext(FlagsContext);

  const clients = useAppSelector((state) => state.clients.clients);
  const ius = useAppSelector((state) => state.users.internalUsers);
  const companyDataMap = useAppSelector(
    (state) => state.clients.companyDataMap,
  );

  const { unreadNotificationCount, readNotificationCount } =
    useNotificationsCounts(
      allInboxNotifications || [],
      [...clients, ...ius],
      companyDataMap,
    );

  // this represents the total number of available notifications that have valid senders

  const allNotificationCount = unreadNotificationCount + readNotificationCount;

  /**
   * generate description of the empty
   * state i.e. when no notification is selected
   */
  const getEmptyStateDescription = () => {
    // there are no notifications
    if (!allNotificationCount) {
      if (EnableFormsNotificationCenter && EnableInvoicesNotificationCenter) {
        return 'When your clients upload files, sign contracts, complete forms, or pay invoices, those notifications will be surfaced on this page.';
      }
      return 'When your clients upload files or sign documents, those notifications will be surfaced on this page.';
    }

    // there are no unread notifications
    if (unreadNotificationCount === 0) {
      return 'No unread notifications';
    }
    return `${unreadNotificationCount} unread notification${
      unreadNotificationCount > 1 ? 's' : '' // 'notification` for count:1, 'notifications' for count > 1
    }`;
  };

  const getPageTitle = () => {
    if (EnableFormsNotificationCenter || EnableInvoicesNotificationCenter) {
      return 'Notifications';
    }
    return 'Files notifications';
  };

  const getEmptyStateTitle = () => {
    if (!allInboxNotifications?.length) {
      if (EnableFormsNotificationCenter || EnableInvoicesNotificationCenter) {
        return 'Notifications will show here';
      }
      return 'Files notifications will show here';
    }
    return getPageTitle();
  };

  const getLearnMoreLink = () => {
    if (EnableFormsNotificationCenter || EnableInvoicesNotificationCenter) {
      return NOTIFICATIONS_PAGE_EMPTY_STATE_LEARN_MORE;
    }
    return INBOX_PAGE_EMPTY_STATE_LEARN_MORE;
  };
  return (
    <div className={classes.root}>
      <StandardPage
        hasNavbar={!Boolean(allNotificationCount)}
        pageTitle={!allNotificationCount ? getPageTitle() : undefined}
        topIcon={<NotificationIcon />}
        title={getEmptyStateTitle()}
        desc={getEmptyStateDescription()}
        // show learn more link
        // only when there are no notifications
        learnMoreLinkUrl={
          !allInboxNotifications?.length ? getLearnMoreLink() : ''
        }
      />
    </div>
  );
};
