export * from './entityTypes';
export * from './portalConfigTypes';
export * from './moduleDataTypes';
export * from './settingsTypes';
export * from './userTypes';

export interface Image {
  id: string;
  url: string;
  filename: string;
  size: number;
  type: string;
  name: string;
  thumbnails?: {
    small: {
      url: string;
      width: number;
      height: number;
    };
    large: {
      url: string;
      width: number;
      height: number;
    };
    full: {
      url: string;
      width: number;
      height: number;
    };
  };
}

export interface Address {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export const ADD_CUSTOM_MODULE_DESCRIPTION =
  'If you don’t see your app listed, you can still connect it. Most modern web apps can be embedded and all can be connected as a link.';

export const BUILD_OWN_APP_DESCRIPTION =
  'Achieve any desired functionality by building your own custom web app and embedding it in the portal.';
