import { Events } from 'src/constants/webSocketConsts/events';
import { upsertTagsToState, removeTagsFromState } from 'src/store/tags/actions';
import { ensureUnreachable } from 'src/utils/CommonUtils';

const TagHandler = (event: Events, items: Array<any>) => {
  switch (event) {
    case Events.Insert:
    case Events.Modify:
      return upsertTagsToState(items);
    case Events.Remove: {
      return removeTagsFromState(items);
    }
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default TagHandler;
