import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import * as Constants from 'src/constants';
import { GENERAL_OPERATIONS } from 'src/constants';
import { FlagsContext, PortalConfigContext } from 'src/context';
import { usePlanStatus } from 'src/hooks/usePlanStatusHook';
import { useGetNotificationsSettingsQuery } from 'src/services/api';
import { useGetInstallsQuery } from 'src/services/api/applicationsApi';
import { RootState } from 'src/store';
import * as UserUtils from 'src/utils/UserUtils';

export const useRouteAccess = () => {
  const portalConfig = useContext(PortalConfigContext);
  const planStatus = usePlanStatus();
  const isUnAuth = useSelector((state: RootState) => state.user.isUnAuth);
  const isClient = useSelector((state: RootState) => state.user.isClient);
  const { EnableMarketplaceSubmissions } = useContext(FlagsContext);

  const { EnableFilesNotificationCenter, EnableBillingImprovements } =
    React.useContext(FlagsContext);
  const permissions = useSelector(
    (state: RootState) => state.user.data?.permissions,
  );
  const { data: { moduleSettings } = {} } = useGetInstallsQuery();
  const fileSettings = useSelector(
    (state: RootState) => state.settings.fileSettings,
  );
  const fileChannels = useSelector((state: RootState) => state.files.channels);

  const { data: notificationSettings } = useGetNotificationsSettingsQuery();

  // we don't want to show 'Inbox' sidebar item when
  // a. all in product notifications are disabled
  // b. upload file notifications are disabled
  // c. signed contract i.e esign complete notifications are disabled
  const isInProductInboxNotificationsDisabled =
    notificationSettings?.disableInProduct ||
    (notificationSettings?.notifyAbout.uploadedFiles.disableInProduct &&
      notificationSettings.notifyAbout.signedContracts.disableInProduct &&
      notificationSettings.notifyAbout.newFormResponses.disableInProduct &&
      notificationSettings.notifyAbout.paidInvoices.disableInProduct);

  /* Since portalConfig object is not updated in real time, we need
   * to get the enable module state from the moduleSettings.
   * This method gets default-module active state from module settings.
   * @param moduleId: default module id
   * @returns
   */
  const getDefaultModuleStateFromModuleSettings = (moduleId: string) => {
    if (!moduleSettings || !moduleSettings.length) return true;
    const moduleItem = moduleSettings.find((mod) => moduleId === mod.id);
    if (!moduleItem) {
      return false;
    }

    if (moduleItem.disabled) {
      return false;
    }
    return true;
  };
  // files count for all channels
  const channelFilesCount = React.useMemo(
    () =>
      fileChannels
        .map((item) => (item.fields.filesCount ? item.fields.filesCount : 0))
        .reduce((prev, curr) => prev + curr, 0),
    [fileChannels],
  );

  // for client don't show files page if visibility settings is off and no file is uploaded in a channel
  const hideFilesModule = () => {
    if (
      isClient &&
      !(fileSettings?.alwaysOnVisibility || channelFilesCount > 0)
    ) {
      return true;
    }

    return false;
  };

  return {
    allowProfilePage: !isUnAuth,
    allowCRMPages: UserUtils.hasPermission(Constants.PERM_MOD_CRM, permissions),
    allowHomePage: !isClient,
    allowMessagesPage:
      UserUtils.hasPermission(Constants.PERM_MOD_MESSAGES, permissions) &&
      getDefaultModuleStateFromModuleSettings('messages'),
    allowFilesPage:
      UserUtils.hasPermission(Constants.PERM_MOD_FILES, permissions) &&
      getDefaultModuleStateFromModuleSettings('files') &&
      !hideFilesModule(),
    allowPaymentsPage:
      getDefaultModuleStateFromModuleSettings('payments') &&
      UserUtils.hasPermission(Constants.PERM_MOD_PAYMENTS, permissions),
    allowKnowledgeBasePage:
      UserUtils.hasPermission(Constants.PERM_MOD_KNOWLEDGE_BASE, permissions) &&
      getDefaultModuleStateFromModuleSettings('knowledgeBase'),
    allowLandingPages:
      UserUtils.hasPermission(
        Constants.PERM_MOD_SETTINGS_LANDING_PAGE,
        permissions,
      ) && portalConfig.MarketingSite.Enabled,
    allowSubscriptionPage:
      !isUnAuth &&
      !isClient &&
      (UserUtils.hasPermission(Constants.PERM_MOD_PAYMENTS, permissions) ||
        getDefaultModuleStateFromModuleSettings('payments')),
    // settings page access configurations
    allowSettingsCustomizationPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_CUSTOMIZATION,
      permissions,
    ),
    // Settings/payments page is only visible for clients
    // when permission is allowed and the payments module is enabled in portalConfig,
    allowSettingsBillingPage:
      isClient &&
      UserUtils.hasPermission(
        Constants.PERM_MOD_SETTINGS_BILLING,
        permissions,
      ) &&
      getDefaultModuleStateFromModuleSettings('payments'),
    allowSettingsModuleManagement: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_MODULE_MANAGEMENT,
      permissions,
    ),
    allowSettingsFiles:
      UserUtils.hasPermission(Constants.PERM_MOD_SETTINGS_FILES, permissions) &&
      getDefaultModuleStateFromModuleSettings('files'),
    allowSettingsModulePayments:
      UserUtils.hasPermission(
        Constants.PERM_MOD_SETTINGS_MODULE_PAYMENTS,
        permissions,
      ) && getDefaultModuleStateFromModuleSettings('payments'),
    allowSettingsMainPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_MAIN_PG,
      permissions,
    ),
    allowSettingsModuleMessaging:
      UserUtils.hasPermission(
        Constants.PERM_MOD_SETTINGS_MESSAGING,
        permissions,
      ) && getDefaultModuleStateFromModuleSettings('messages'),
    allowSettingsMembersPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_MEMBERS,
      permissions,
    ),
    allowSettingsApiPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_API,
      permissions,
    ),
    allowSettingsAppsPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_APPS,
      permissions,
    ),
    allowSettingsExtensionsPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_EXTENSIONS,
      permissions,
    ),
    allowSettingsDomainPage: UserUtils.hasOperation(
      Constants.PERM_MOD_SETTINGS_DOMAINS,
      GENERAL_OPERATIONS.UPDATE,
      permissions,
    ),
    allowIntakeFormsPage:
      getDefaultModuleStateFromModuleSettings('forms') &&
      UserUtils.hasPermission(Constants.PERM_MOD_FORMS, permissions),
    allowIntakeFormsResponsePage:
      getDefaultModuleStateFromModuleSettings('forms') &&
      UserUtils.hasPermission(Constants.PERM_MOD_FORMS_RESPONSE, permissions),
    allowClientDetailsPage: !isClient,
    allowSettingsPlanPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_PLANS,
      permissions,
    ),
    allowSettingsReferralPage: !isClient && !planStatus.isTrialing,
    allowReferralPage: !isClient && !planStatus.isTrialing,
    allowExtensionsPage: true,
    allowNotificationSettings: !isClient,
    allowClientImportPage: !isClient,
    allowInboxPage:
      EnableFilesNotificationCenter &&
      !isClient &&
      !isInProductInboxNotificationsDisabled,
    allowAppSetupPage: UserUtils.hasPermission(
      Constants.PERM_MOD_SETTINGS_MODULE_MANAGEMENT,
      permissions,
    ),
    allowAppSubmitPage: EnableMarketplaceSubmissions,
    allowProductsPage: EnableBillingImprovements,
    allowContractsPage: getDefaultModuleStateFromModuleSettings('contracts'),
    allowInvoices:
      EnableBillingImprovements &&
      getDefaultModuleStateFromModuleSettings('payments') &&
      UserUtils.hasPermission(Constants.PERM_MOD_PAYMENTS, permissions),
  } as const;
};
