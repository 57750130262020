import { unionBy } from 'lodash';
import {
  FileChannel,
  FilesState,
  FilesActions,
  FilesActionTypes,
  FilesTableData,
} from 'src/store/files/types';

export const initialRenameFormValues: FilesTableData = {
  creator: {
    id: '',
    avatarUrl: '',
    email: '',
    fallbackColor: '',
    firstName: '',
    lastName: '',
  },
  fileId: '',
  fileKey: '',
  fileName: '',
  identityId: '',
  createdDate: '',
  path: [],
  filePath: [],
  fileUrl: '',
};

const initialState: FilesState = {
  items: [],
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  downloading: false,
  error: '',
  renaming: false,
  moving: false,
  channels: [],
  fileDragging: false,
  hoveredRowNode: null,
  helperActions: {
    actionName: null,
  },
  channelsLoaded: false,
};

// eslint-disable-next-line default-param-last
const FilesReducer = (state = initialState, action: FilesActions) => {
  switch (action.type) {
    case FilesActionTypes.GET_FILES:
      return {
        ...state,
        loading: true,
        loaded: false,
        items: [], // clear exsiting items when getting new items
      };

    case FilesActionTypes.GET_FILES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case FilesActionTypes.GET_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.payload,
      };
    case FilesActionTypes.SAVE_UPLOADED_FILES:
      return {
        ...state,
        saving: true,
        saved: false,
      };
    case FilesActionTypes.SAVE_UPLOADED_FILES_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: true,
        items: [...state.items, ...action.payload],
      };
    case FilesActionTypes.SAVE_UPLOADED_FILES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case FilesActionTypes.UNSELECT_CHANNEL:
      return {
        ...state,
        items: [],
        selectedChannel: undefined,
        loading: false,
        loaded: false,
      };
    case FilesActionTypes.DELETE_FILES: {
      const files = state.items.filter(
        (file) => !action.payload.includes(file.id),
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        items: files,
      };
    }
    case FilesActionTypes.SELECT_FILES_CHANNEL:
      return {
        ...state,
        loading: false,
        loaded: false,
        selectedChannel: action.payload,
      };
    case FilesActionTypes.CLEAR_FILES:
      return {
        ...initialState,
      };
    case FilesActionTypes.DOWNLOAD_FILE:
      return {
        ...state,
        downloading: true,
      };
    case FilesActionTypes.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloading: false,
      };
    case FilesActionTypes.DOWNLOAD_FILE_ERROR:
      return {
        ...state,
        downloading: false,
        error: action.error,
      };
    case FilesActionTypes.UPDATE_FILE_FIELDS_REQUEST:
      return {
        ...state,
        renaming: true,
      };
    case FilesActionTypes.UPDATE_FILE_FIELDS_SUCCESS: {
      const filesStaged = state.items.map((file) => {
        if (action.successIds.includes(file.id)) {
          const currentUpdatedFile = action.updatedFiles.find(
            (updatedFile) => updatedFile.id === file.id,
          );
          if (currentUpdatedFile) {
            return {
              ...file,
              fields: {
                ...file.fields,
                esignStatus: currentUpdatedFile.fields.esignStatus,
                lastEsigModifierIdentityID:
                  currentUpdatedFile.fields.lastEsigModifierIdentityID,
                components: currentUpdatedFile.fields.components,
                fileName: currentUpdatedFile.fields.fileName,
                starred: currentUpdatedFile.fields.starred,
                path: currentUpdatedFile.fields.path,
                status: currentUpdatedFile.fields.status,
              },
            };
          }
          return file;
        }
        return file;
      });

      return {
        ...state,
        items: filesStaged,
        renaming: false,
      };
    }

    case FilesActionTypes.UPDATE_FILE_FIELDS_FAILURE:
      return {
        ...state,
        renaming: false,
        error: action.error,
      };

    case FilesActionTypes.MOVE_FOLDER_FAILURE:
      return {
        ...state,
        moving: false,
        error: action.error,
      };
    case FilesActionTypes.MOVE_FOLDER_REQUEST:
      return {
        ...state,
        moving: true,
      };
    case FilesActionTypes.MOVE_FOLDER_SUCCESS: {
      const filesStaged = unionBy(action.payload, state.items, 'id');

      return {
        ...state,
        items: filesStaged,
        moving: false,
      };
    }
    case FilesActionTypes.SET_FILE_CHANNELS: {
      return {
        ...state,
        channels: action.channels
          .filter((chan) => chan.entityStatus !== 'ARCHIVED')
          // set channels sorted by created date - this is useful
          // in case we want to select the next selected
          // channel after deleting a channel. We can
          // select the first channel in the list
          // which will be the most recently created
          .sort((a, b) => {
            const aDate = new Date(a.createdDate);
            const bDate = new Date(b.createdDate);
            return bDate.getTime() - aDate.getTime();
          }),
        channelsLoaded: true,
      };
    }
    case FilesActionTypes.ADD_FILE_CHANNELS: {
      const { addFileChannelOptions, channels } = action;
      const { selectFirstChannel } = addFileChannelOptions || {};
      const newChannelIDs = channels
        .filter((chan) => chan.entityStatus !== 'ARCHIVED')
        .map((chan) => chan.id);
      const previousChannels = state.channels.filter(
        (chan) => !newChannelIDs.includes(chan.id),
      );
      return {
        ...state,
        channels: [...previousChannels, ...action.channels],
        ...(selectFirstChannel
          ? {
              selectedChannel: {
                id: action.channels[0].id,
                additionalFields: action.channels[0].additionalFields,
                fields: action.channels[0].fields,
              },
            }
          : {}),
        ...(selectFirstChannel ? { loading: false, loaded: false } : {}),
      };
    }
    case FilesActionTypes.REMOVE_FILE_CHANNELS: {
      const deletedChannelIDs = action.channels.map((chan) => chan.id);
      const filteredChannels = state.channels.filter(
        (chan) => !deletedChannelIDs.includes(chan.id),
      );
      return {
        ...state,
        channels: filteredChannels,
      };
    }
    case FilesActionTypes.INSERT_FILES: {
      return {
        ...state,
        saving: false,
        saved: true,
        items: [...state.items, ...action.payload],
      };
    }
    case FilesActionTypes.UPDATE_FILE_CHANNELS: {
      const { updatedChannels } = action;
      const updatedChannelIds = updatedChannels.map((c) => c.id);
      const { channels: existingChannels } = state;

      const otherChannels = existingChannels.filter(
        (channel: FileChannel) => !updatedChannelIds.includes(channel.id),
      );
      const updatedSelectedChannels = updatedChannels.filter(
        (c) => c.id === state.selectedChannel?.id,
      );

      const newChannels = updatedChannels
        .concat(otherChannels)
        .filter((chan) => chan.entityStatus !== 'ARCHIVED');
      return {
        ...state,
        channels: newChannels,
        selectedChannel:
          updatedSelectedChannels && updatedSelectedChannels.length > 0
            ? updatedSelectedChannels.at(0)
            : state.selectedChannel,
      };
    }
    case FilesActionTypes.CANCEL_ESIGNATURE_REQUEST_SUCCESS: {
      const updatedFileEntity = action.payload;
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === updatedFileEntity.id ? updatedFileEntity : item,
        ),
      };
    }
    case FilesActionTypes.UPDATE_FILE_DRAGGING: {
      return {
        ...state,
        fileDragging: action.payload,
      };
    }
    case FilesActionTypes.UPDATE_HOVERED_ROW_NODE: {
      return {
        ...state,
        hoveredRowNode: action.payload,
      };
    }
    case FilesActionTypes.UPDATE_FILES_HELPER_ACTION: {
      return {
        ...state,
        helperActions: {
          ...state.helperActions,
          actionName: action.payload,
        },
      };
    }
    case FilesActionTypes.DELETE_FILE_CHANNEL: {
      const { channelId } = action;
      const filteredChannels = state.channels.filter(
        (chan) => channelId !== chan.id,
      );

      const selectedChannel =
        state.selectedChannel && state.selectedChannel.id === channelId
          ? undefined
          : state.selectedChannel;

      return {
        ...state,
        channels: filteredChannels,
        selectedChannel,
      };
    }
    default:
      return state;
  }
};

export default FilesReducer;
