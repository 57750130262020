import { FormTemplate } from 'src/components/FormsV2/formsTypes';
import { Events } from 'src/constants/webSocketConsts/events';
import { formsApi } from 'src/services/api/formsApi';
import { ensureUnreachable } from 'src/utils/CommonUtils';

/**
 * This handle the forms v2 websocket events.
 * When a web socket event is received, based
 * on the event type, the forms state is updated.
 * The updateQueryData method is used to apply
 * the changes to the forms templates cache state.
 * @param event - web socket event
 * @param items - web socket items
 * @returns void
 */
const FormsTemplatesV2Handler = (event: Events, items: Array<FormTemplate>) => {
  const [wsFormTemplateItem] = items;
  // call the updateQueryData method to update the forms cache state
  return formsApi.util.updateQueryData(
    'getForms',
    undefined,
    (formTemplatesDraft) => {
      // find the index of the form template object to update/delete/insert.
      const formTemplateIndex = formTemplatesDraft.findIndex(
        (formTemplate) => formTemplate.ref === wsFormTemplateItem.ref,
      );

      switch (event) {
        case Events.Insert:
          // if the form template already exists, do not add it to the forms state
          if (formTemplateIndex !== -1) {
            return;
          }
          // add the form template to the forms state
          formTemplatesDraft.unshift(wsFormTemplateItem);
          break;
        case Events.Remove:
          // if the form template to be removed does not exist, do not remove it from the forms state
          if (!formTemplatesDraft[formTemplateIndex]) {
            return;
          }
          // remove the form template from the forms state
          formTemplatesDraft.splice(formTemplateIndex, 1);
          break;
        case Events.Modify:
          // update the form template in the forms state
          formTemplatesDraft.splice(formTemplateIndex, 1, wsFormTemplateItem);
          break;
        case Events.DomainVerified:
        case Events.Refresh:
          return;
        default:
          ensureUnreachable(event);
      }
    },
  );
};

export default FormsTemplatesV2Handler;
