import { ModuleSettingsItem, VisibilityRule } from 'src/constants';

export function isCustomFieldUsedInAppVisibility(
  moduleSettings: ModuleSettingsItem[],
  deletedOption: string,
  deletionComparison: (
    rule: VisibilityRule,
    deletedCustomField: string,
  ) => boolean,
): [boolean, string] {
  for (const module of moduleSettings) {
    const { visibilityConfig } = module;
    if (
      visibilityConfig &&
      visibilityConfig.length > 0 &&
      visibilityConfig.some((visibilityRule) =>
        deletionComparison(visibilityRule, deletedOption),
      )
    ) {
      return [true, module.id];
    }
  }
  return [false, ''];
}
