import { Events } from 'src/constants/webSocketConsts/events';
import {
  addFileChannelsAction,
  removeFileChannelsAction,
  updateFileChannelsAction,
} from 'src/store/files/actions';
import { ensureUnreachable } from 'src/utils/CommonUtils';

const ChannelHandler = (event: Events, items: Array<any>) => {
  switch (event) {
    case Events.Insert:
      return addFileChannelsAction(items);
    case Events.Modify:
      return updateFileChannelsAction(items);
    case Events.Remove:
      return removeFileChannelsAction(items);
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default ChannelHandler;
