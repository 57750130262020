import React from 'react';
import { useGetInboxNotificationsQuery } from 'src/services/api/inboxApi';
import { NotificationBadge } from 'src/components/UI/Badges';

/**
 * AppNotificationBadge component
 * This component renders the notification badge for an installed app/extension/embed.
 * When the notifications api is used to create a notification with an api key
 * associated with an appId, that notification will be displayed with the associated app.
 */
export const AppNotificationBadge = ({ appId }: { appId: string }) => {
  const { data: notifications } = useGetInboxNotificationsQuery();

  // filter notifications by appId
  const appNotificationCount =
    notifications?.filter(
      (notification) =>
        notification.event === 'customApp.action' &&
        notification.appId === appId &&
        !notification.isRead,
    ).length || 0;

  return (
    <NotificationBadge
      showZero={false}
      isSidebar
      content={appNotificationCount}
    />
  );
};
