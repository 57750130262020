import React from 'react';
import { Theme, createStyles, makeStyles, Box } from '@material-ui/core';
import { ColDef } from '@ag-grid-community/core';
import { SortableContainer } from 'react-sortable-hoc';
import BaseTypography from 'src/components/Text/BaseTypography';
import * as Colors from 'src/theme/colors';
import { SortableTableColumnElement } from 'src/components/TableSettings/SortableTableColumnElement';
import { TablePropertiesContext } from 'src/context/tableSettingsContext';
import TablePropertiesClient from 'src/clients/TablePropertiesClient';
import { FieldVisibility, TablePropertyFields } from 'src/constants/dataTypes';

interface ColumnSettingsProps {
  columnDefs: Array<ColDef>;
  tableEntityType: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settingsTitle: {
      marginLeft: theme.spacing(1.5),
      color: Colors.GraySmall,
    },
  }),
);

const TableColumnList: React.FC<ColumnSettingsProps> = ({
  columnDefs,
  tableEntityType,
}) => {
  const classes = useStyles();
  const {
    columnSettings,
    setColumnSettings,
    columnSettingsEntityId,
    setColumnSettingsEntityId,
    useColumnDefsForSorting,
  } = React.useContext(TablePropertiesContext);

  async function handleUpdateTableProperties(
    updatedProperties: TablePropertyFields,
  ) {
    const result = await TablePropertiesClient.updateTableProperties(
      {
        id: columnSettingsEntityId,
        structFields: updatedProperties,
      },
      tableEntityType,
    );
    setColumnSettingsEntityId(result.id);
  }

  const handleVisibilityChanged = (
    columnField: string | undefined,
    visible: boolean,
  ) => {
    if (!columnField) {
      return;
    }

    let updatedFieldOrder: FieldVisibility[] | undefined = [];
    if (useColumnDefsForSorting) {
      // use column defs for sorting, like for crm table because new custom fields are by default added to column defs
      // but not to the table properties
      updatedFieldOrder = columnDefs.map((cs) => {
        if (cs.field === columnField) {
          return {
            id: cs.field,
            name: cs.headerName || '',
            disabled: !visible,
          };
        }

        return {
          id: cs.field,
          name: cs.headerName || '',
          disabled: cs.hide,
        };
      }) as FieldVisibility[];
    } else {
      updatedFieldOrder = columnSettings?.tablePropertyFields.map((cs) => {
        if (cs.id === columnField) {
          return {
            ...cs,
            disabled: !visible,
          };
        }

        return cs;
      });
    }

    const updatedColumnSettings = {
      ...columnSettings,
      tablePropertyFields: updatedFieldOrder || [],
    };

    // optimistic update of column settings
    setColumnSettings(updatedColumnSettings);
    handleUpdateTableProperties(updatedColumnSettings);
  };

  return (
    <Box>
      <BaseTypography fontType="13Medium" className={classes.settingsTitle}>
        Properties
      </BaseTypography>
      {columnDefs.map((col, idx) => (
        <SortableTableColumnElement
          index={idx}
          key={col.field}
          handleVisibilityChanged={handleVisibilityChanged}
          columnDef={col}
          order={idx}
        />
      ))}
    </Box>
  );
};

export const SortableTableColumnList = SortableContainer(TableColumnList);
