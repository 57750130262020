import React, { ComponentProps } from 'react';
import Cropper from 'react-easy-crop';
import { Box, Slider, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { FileWithPreview } from 'react-dropzone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      right: 0,
      left: 0,
      top: '55px',
      bottom: '100px',
      position: 'absolute',
      '& > .reactEasyCrop_Container': {
        backgroundColor: 'black',
        '& > .reactEasyCrop_CropArea': {
          color: 'rgba(0,0,0,0.6)',
        },
      },
    },
    slider: {
      padding: theme.spacing(4, 0),
      margin: theme.spacing(0, 3.5),
    },
    controls: {
      position: 'absolute',
      bottom: '52px',
      left: '50%',
      width: '100%',
      transform: 'translateX(-50%)',
      height: '55px',
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
interface ImageCropperProps {
  image: FileWithPreview;
  onCropComplete: ComponentProps<typeof Cropper>['onCropComplete'];
  aspect: number;
}

export const ImageCropper: React.FC<ImageCropperProps> = ({
  image,
  onCropComplete,
  aspect,
}) => {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const classes = useStyles();

  return (
    <Box width={1} height={1}>
      <div className={classes.container}>
        <Cropper
          image={image.preview}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={classes.controls}>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(_, value) => typeof value === 'number' && setZoom(value)}
          className={classes.slider}
        />
      </div>
    </Box>
  );
};
