/* eslint-disable import/no-import-module-exports */
import {
  Reducer,
  configureStore as configureStoreToolkit,
} from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';
import localforage from 'localforage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  PersistedState,
  Persistor,
} from 'redux-persist';
import createHelpers, { StoreHelpersConfig } from 'src/store/createHelpers';
import { saveState, saveLocalState } from 'src/utils/StorageUtils';
import { rootReducer } from 'src/store/rootReducer';
import { appAPI, usersApi } from 'src/services/api';
import { RootState } from 'src/store/reduxTypes';
import { UsersAPIName } from 'src/constants';
import * as Sentry from '@sentry/react';

// This array defines the list of reducers that will not be persisted in the local storage
const NOT_PERSISTED_STATES = [
  'appAPI',
  UsersAPIName,
  'messages',
  'payments',
  'ui',
  'settings',
  'files',
  'knowledgeBase',
  'user.data.stripeCustomer',
  'user.data.stripeSubscriptions',
  'user.data.updatingSubscription',
  'user.loadingError',
  'auth',
  'tags',
  'signaturePage',
];

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

/**
 * This function is used to handle the state migration.
 * @param initialState: the initial state of the app
 * @param persistedState: the persisted state from the local storage
 * @returns the rehydrated state
 */
const handleStateMigration = (
  initialState: RootState,
  persistedState: RootState,
) => {
  // when persisted state is undefined, return the initial state to be the rehydrated state
  if (!persistedState) return Promise.resolve(initialState);
  // otherwise the rehydrated state = persisted state
  return Promise.resolve({
    ...persistedState,
    user: {
      ...(persistedState?.user || {}),
      // reset the user loaded state to false
      // so that on refresh we refetch the user data
      loaded: false,
      loading: false,
      isClient: initialState?.user?.isClient, // isClient should be available
      loadingError: null,
    },
    // when crm table mounts it fetches the clients custom fields
    // if they are not loaded yet (it checks for isClientCustomFieldsLoaded
    // to call get custom fields api).
    // We need to reset these loading states to false
    // so that it refetch the custom fields on page refresh
    clients: {
      ...(persistedState?.clients || {}),
      isClientCustomFieldsLoaded: false,
      isClientCustomFieldsLoading: false,
    },
    users: {
      ...(persistedState?.users || {}),
      loadedUsers: false,
    },
    dashboard: {
      ...(persistedState?.dashboard || {}),
      itemsLoaded: false,
    },
  });
};

/**
 * This function is used to configure the redux store.
 * @param initialState: the initial state of the app
 * @param helpersConfig: the config for the helpers
 * @param usePersister: whether to use the redux persister or not
 * @param persisterKeyConfig: the key params that defines the persisted store item in db
 * @returns the configured store
 */
export const configureStore = (
  initialState: any,
  helpersConfig: StoreHelpersConfig,
  usePersister: boolean,
  persisterKeyConfig?: {
    portalId: string;
    userId: string;
  },
) => {
  const { portalId, userId } = persisterKeyConfig || {};
  // the web app should persist the redux store in the following scenarios:
  // 1- it is not SSR - the persistence storage is not available in SSR
  // 2- User is logged in - the userId is used to identify the persisted store, so it should be defined.
  // 3- Active portal session is available
  // 4- It is not the login page (portal id is not "workspace")
  const shouldUsePersister =
    usePersister && userId && portalId && portalId !== 'workspace';
  const appStateReducer = shouldUsePersister
    ? persistReducer(
        {
          version: 1,
          // when user has multiple portals, we need to identify
          // the persisted store by the portal id which is stored in the session data.
          // This will avoid mixing the data between the portals as well as
          key: `${portalId}-${userId}-root`,
          storage: localforage,
          debug: true,
          blacklist: NOT_PERSISTED_STATES,
          migrate: (persistedState: PersistedState) =>
            handleStateMigration(initialState, persistedState as RootState),
        },
        rootReducer,
      )
    : rootReducer;

  const helpers = createHelpers(helpersConfig);
  // https://redux.js.org/docs/api/createStore.html
  // const store = createStore(rootReducer, initialState, enhancer);
  const store = configureStoreToolkit({
    reducer: appStateReducer as Reducer,
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers.concat(sentryReduxEnhancer);
    },
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: helpers,
        },
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        immutableCheck: false, // need to fix this
      })
        .concat(appAPI.middleware)
        .concat(usersApi.middleware),
  });

  const storeWithPersistor: typeof store & {
    persistor: Persistor | undefined;
  } = {
    ...store,
    persistor: shouldUsePersister ? persistStore(store) : undefined,
  };

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept('./rootReducer', () =>
      import('./rootReducer').then((reducerModule) => {
        storeWithPersistor.replaceReducer(reducerModule.rootReducer);
      }),
    );
  }

  storeWithPersistor.subscribe(
    throttle(() => {
      const currentState = storeWithPersistor.getState();
      saveState({
        user: currentState.user,
      });

      saveLocalState({
        clients: currentState.clients,
      });
    }, 1000),
  );

  return storeWithPersistor;
};
type StoreType = ReturnType<typeof configureStore>;

export type AppState = ReturnType<StoreType['getState']>;

export type AppDispatch = StoreType['dispatch'];
