import React, { useContext } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RouteContext, FlagsContext } from 'src/context';
import {
  BILLING_PAGE,
  CUSTOMIZATION_PAGE,
  DOMAIN_PAGE,
  API_SETTINGS_PAGE,
  PAYMENTS_PAGE,
  PLAN_PAGE,
  PROFILE_PAGE,
  SETTINGS_PAGE_ROOT_PATH,
  EXTENSION_ITEM,
  PORTAL_ITEM,
  NOTIFICATIONS_SETTINGS_PAGE,
  SIGNATURE_PAGE,
  APP_SETUP_PAGE,
} from 'src/constants';
import { useRouteAccess } from 'src/routes/routeAccess';
import { RootState } from 'src/store';
import * as Constants from 'src/constants';
import { useNotificationsBadge } from 'src/components/Sidebar/NotificationBadges';
import { IconNameToComponent, IconType } from 'src/components/Icons';
import { NavItem, NavSection } from 'src/components/Sidebar/SidebarNav';
import { UpgradeBadge } from 'src/components/Sidebar/UpgradeBadge';
import { getModuleIcon, getModuleName } from 'src/utils/ModuleSettingsUtils';
import { usePlanStatus } from 'src/hooks/usePlanStatusHook';
import { ExtensionTooltipTitle } from 'src/components/Tooltip';
import { useGetPortalConfigQuery } from 'src/services/api';
import { ContractPageTabs } from 'src/components/Contracts';
import { useUserCanInvite } from 'src/hooks/useUserCanInvite';
import { useUserAccess } from 'src/hooks/useUserAccess';
import { useGetInstallsQuery } from 'src/services/api/applicationsApi';

const appNavItemIdPrefix = 'sidebar-app-';
const moduleNavItemIdPrefix = 'sidebar-';

export enum CoreModuleSortOrder {
  MESSAGES = 0,
  FILES = 1,
  CONTRACTS = 2,
  FORMS = 3,
  PAYMENTS = 4,
  KNOWLEDGE_BASE = 5,
  EXTENSIONS = 6,
}

enum ExtensionItemViewLocation {
  IN_PORTAL = 'in-portal',
  NEW_TAB = 'new-tab',
}

export type SidebarType = 'main' | 'settings';
export const useSidebarItems = (
  sidebarType?: SidebarType,
): Array<NavSection> => {
  const routeAccess = useRouteAccess();
  const { pathname, query } = useContext(RouteContext);
  const {
    EnableFilesNotificationCenter,
    EnableFormsNotificationCenter,
    EnableInvoicesNotificationCenter,
    EnableBillingImprovements,
  } = useContext(FlagsContext);
  const planStatus = usePlanStatus();
  const { isInviteConfigDisabled } = useUserCanInvite();

  // check if loggedin user has permission to adjust internal role
  // and invite config is not disabled
  const hasModifyInternalRolePermission =
    !isInviteConfigDisabled &&
    useUserAccess(
      Constants.PERM_MOD_SETTINGS_MEMBERS,
      Constants.USER_OPERATIONS.AdjustInternalRole,
    );
  const { data: { moduleSettings, extensionsSettings } = {} } =
    useGetInstallsQuery();
  const { id: queryIdParam } = query;
  const { isClient, extensionItems } = useSelector(
    (state: RootState) => ({
      extensionItems: state.dashboard.items,
      isClient: state.user.isClient,
    }),
    shallowEqual,
  );
  const { data: portalConfigurations } = useGetPortalConfigQuery();
  const shouldFilterNonClientItems =
    isClient && portalConfigurations?.structFields?.disableCompanies;

  const setupExtensionConfigSet = extensionItems
    .filter((extensionItem) => extensionItem.fields.status === 'connected')
    .reduce(
      (extensionConfigSet: Record<string, boolean>, extensionItem) => ({
        ...extensionConfigSet,
        [extensionItem.fields.extensionConfigId || 'default']: true,
      }),
      {},
    );

  const meSectionNavItems: Array<NavItem> = [
    {
      htmlId: 'account-setting-page',
      label: PROFILE_PAGE.label,
      path: PROFILE_PAGE.path,
      icon: PROFILE_PAGE.icon,
      canAccess: true,
    },

    {
      htmlId: 'notification-setting-page',
      label: NOTIFICATIONS_SETTINGS_PAGE.label,
      path: NOTIFICATIONS_SETTINGS_PAGE.path,
      icon: NOTIFICATIONS_SETTINGS_PAGE.icon,
      canAccess: routeAccess.allowNotificationSettings,
    },
  ];

  /**
   * This function calculate for each extension sidebar item
   * the external link url and the extension view location (in portal or new tab).
   *
   * @param extensionConfig
   * @param extId
   * @returns
   */
  const getExtensionLinkAccessibilityData = (
    extensionConfig: Constants.ExtensionConfig,
    extId: string,
  ) => {
    const defaultExtensionPath = `${Constants.CLIENT_APPS_PAGE.path}?id=${extId}`;
    let extensionItemAccessibilityData = {
      extensionLink: defaultExtensionPath,
      viewExtensionLocation: ExtensionItemViewLocation.IN_PORTAL,
    };
    if (extensionConfig.type === 'local') {
      // find all local connected extension channels that match this extensionID
      const connectedLocalExtensionItems = extensionItems.filter(
        (extensionItem) =>
          extensionItem.fields.extensionConfigId === extId &&
          extensionItem.fields.status === 'connected',
      );

      const connectedLocalExtensionItem =
        connectedLocalExtensionItems.length > 0
          ? connectedLocalExtensionItems.at(0)
          : null;

      if (connectedLocalExtensionItem) {
        const hasMoreThanOneConnectedEmbed =
          connectedLocalExtensionItems.length > 1;

        // default view local extensions in portal
        let viewExtensionLocation = ExtensionItemViewLocation.IN_PORTAL;
        // when client is viewing extension with embedLink and only has one channel then view in new tab
        if (
          isClient &&
          !hasMoreThanOneConnectedEmbed &&
          connectedLocalExtensionItem.fields.embedLink
        ) {
          viewExtensionLocation = ExtensionItemViewLocation.NEW_TAB;
        }
        extensionItemAccessibilityData = {
          ...extensionItemAccessibilityData,
          extensionLink:
            isClient &&
            connectedLocalExtensionItem.fields.embedLink &&
            !hasMoreThanOneConnectedEmbed
              ? connectedLocalExtensionItem.fields.embedLink
              : defaultExtensionPath,
          viewExtensionLocation,
        };
      }
    } else {
      // global extension
      extensionItemAccessibilityData = {
        ...extensionItemAccessibilityData,
        extensionLink:
          isClient && extensionConfig.embedLink
            ? extensionConfig.embedLink
            : defaultExtensionPath,
        viewExtensionLocation:
          isClient && extensionConfig.embedLink
            ? ExtensionItemViewLocation.NEW_TAB
            : ExtensionItemViewLocation.IN_PORTAL,
      };
    }

    return extensionItemAccessibilityData;
  };

  const extensionNavItems: NavItem[] = routeAccess.allowExtensionsPage
    ? Object.entries(extensionsSettings || {})
        .filter(([extensionID]) =>
          (moduleSettings || []).some((module) => extensionID === module.id),
        )
        .filter(
          ([extensionID, extensionConfig]) =>
            isClient
              ? ['global'].includes(extensionConfig.type) ||
                setupExtensionConfigSet[extensionID]
              : true, // show all extensions for internal users
        )
        .sort(
          ([, extensionConfigA], [, extensionConfigB]) =>
            // first order by extension type so global is before local
            // then by `sort` which is number indicating position in the sidebar
            extensionConfigA.type.localeCompare(extensionConfigB.type) ||
            extensionConfigA.sort - extensionConfigB.sort,
        )
        .map(([extensionId, extensionConfig]) => {
          const { extensionLink: extensionItemPath, viewExtensionLocation } =
            getExtensionLinkAccessibilityData(extensionConfig, extensionId);

          return {
            htmlId: `${appNavItemIdPrefix}${extensionId}`,
            label: extensionConfig.name,
            isCustomApp: true,
            isExternalLink:
              viewExtensionLocation === ExtensionItemViewLocation.NEW_TAB,
            path: extensionItemPath,
            icon: IconNameToComponent[extensionConfig.icon],
            canAccess: true,
            children: useNotificationsBadge('customApp', extensionConfig.appId),
            selected: queryIdParam === extensionId,
            disabled: planStatus.isBasicPlan,
            tooltipTitle: planStatus.isBasicPlan ? (
              <ExtensionTooltipTitle />
            ) : null,
            priority: (moduleSettings || []).findIndex(
              (x) => x.id === extensionId,
            ),
          };
        })
        .sort((a, b) => a.priority - b.priority)
    : [];

  const crmNavItem = {
    htmlId: 'sidebar-crm',
    label: 'Clients',
    path: Constants.CRM_PAGE.path,
    icon: Constants.CRM_PAGE.icon,
    canAccess: routeAccess.allowCRMPages,
    selected:
      pathname.includes(Constants.CRM_PAGE.path) ||
      pathname.includes(Constants.CLIENTS_IMPORT_PAGE.path),
  };

  const inboxNavItem = {
    htmlId: 'sidebar-inbox',
    // when other entities invoices notifications are shown in inbox
    // then the inbox page sidebar item label should show 'Notifications'
    // rather than 'Files notifications'
    label:
      EnableFormsNotificationCenter || EnableInvoicesNotificationCenter
        ? Constants.NOTIFICATIONS_PAGE
        : Constants.INBOX_PAGE.label,
    path: Constants.INBOX_PAGE.path,
    icon: Constants.INBOX_PAGE.icon,
    children: useNotificationsBadge('inbox'),
    canAccess: routeAccess.allowInboxPage,
    selected: pathname.includes(Constants.INBOX_PAGE.path),
  };

  const extensionNavItem = {
    htmlId: 'extensions-item',
    label: EXTENSION_ITEM.label,
    path: extensionNavItems.at(0)?.path || '',
    icon: EXTENSION_ITEM.icon,
    canAccess: !isClient && extensionNavItems.length > 0, // Hide extensions main item if user is client or there are no extension items
    subItems: extensionNavItems,
    shouldCollapse: !!queryIdParam,
    priority: CoreModuleSortOrder.EXTENSIONS,
    disabled: planStatus.isBasicPlan || planStatus.isStarterPlan,
    tooltipTitle: planStatus.isBasicPlan ? <ExtensionTooltipTitle /> : null,
  };

  const homeNavItem = {
    htmlId: 'sidebar-home',
    label: Constants.HOME_PAGE.label,
    path: Constants.HOME_PAGE.path,
    icon: Constants.HOME_PAGE.icon,
    canAccess: routeAccess.allowHomePage,
    // home sidebar item is selected when user routes to home page
    // or trying to access a specific portal e.g. `/portal/${portalId}`
    selected:
      pathname === Constants.HOME_PAGE.path ||
      pathname.startsWith(Constants.PORTAL_PATH),
  };

  const defaultPortalSectionNavItems: NavItem[] = React.useMemo(
    () => [
      {
        htmlId: 'sidebar-messages',
        label: Constants.MESSAGES_PAGE.label,
        path: Constants.MESSAGES_PAGE.path,
        icon: Constants.MESSAGES_PAGE.icon,
        canAccess: routeAccess.allowMessagesPage,
        children: useNotificationsBadge('messaging'),
        priority: CoreModuleSortOrder.MESSAGES,
      },
      {
        htmlId: 'sidebar-files',
        label: Constants.FILES_PAGE.label,
        path: Constants.FILES_PAGE.path,
        icon: Constants.FILES_PAGE.icon,
        canAccess: routeAccess.allowFilesPage,
        children:
          // When files notification center flag is turned on
          // don't show files sidebar items notification count badge( internal user only )
          EnableFilesNotificationCenter && !isClient
            ? null
            : useNotificationsBadge('files'),
        priority: CoreModuleSortOrder.FILES,
        selected: pathname.includes(SIGNATURE_PAGE.path),
      },
      {
        htmlId: 'sidebar-contracts',
        label: Constants.CONTRACTS_PAGE.label,
        path: `${Constants.CONTRACTS_PAGE.path}?view=${ContractPageTabs.Templates}`,
        icon: Constants.CONTRACTS_PAGE.icon,
        canAccess: routeAccess.allowContractsPage,
        priority: CoreModuleSortOrder.CONTRACTS,
        selected: pathname.includes(Constants.CONTRACTS_PAGE.path),
        children: useNotificationsBadge('contracts'),
      },
      {
        htmlId: 'sidebar-forms',
        label: Constants.FORMS_PAGE.label,
        path: Constants.FORMS_PAGE.path,
        icon: Constants.FORMS_PAGE.icon,
        canAccess: routeAccess.allowIntakeFormsPage,
        children:
          EnableFormsNotificationCenter && !isClient
            ? null
            : useNotificationsBadge('forms'),
        priority: CoreModuleSortOrder.FORMS,
        selected: pathname.includes(Constants.FORMS_PAGE.path),
      },
      {
        htmlId: 'sidebar-payments',
        label: 'Billing',
        ...(EnableBillingImprovements && !isClient
          ? {
              path: Constants.PRODUCTS_PAGE.path,
              icon: Constants.PRODUCTS_PAGE.icon,
            }
          : {
              path: Constants.INVOICES_PAGE.path,
              icon: Constants.INVOICES_PAGE.icon,
            }),
        priority: CoreModuleSortOrder.PAYMENTS,
        canAccess:
          routeAccess.allowPaymentsPage && routeAccess.allowSubscriptionPage,
        shouldCollapse:
          pathname === Constants.INVOICES_PAGE.path ||
          pathname === Constants.SUBSCRIPTION_PAGE.path,
        subItems: [
          ...(EnableBillingImprovements && !isClient
            ? [
                {
                  htmlId: 'sidebar-products',
                  label: Constants.PRODUCTS_PAGE.label,
                  path: Constants.PRODUCTS_PAGE.path,
                  canAccess:
                    routeAccess.allowPaymentsPage &&
                    routeAccess.allowSubscriptionPage,
                },
              ]
            : []),
          ...(EnableBillingImprovements
            ? [
                {
                  htmlId: 'sidebar-invoices',
                  label: Constants.INVOICES_PAGE_V2.label,
                  path: Constants.INVOICES_PAGE_V2.path,
                  canAccess:
                    routeAccess.allowInvoices &&
                    routeAccess.allowSubscriptionPage,
                },
              ]
            : [
                {
                  htmlId: 'sidebar-invoices',
                  label: Constants.INVOICES_PAGE.label,
                  path: Constants.INVOICES_PAGE.path,
                  canAccess:
                    routeAccess.allowPaymentsPage &&
                    routeAccess.allowSubscriptionPage,
                },
              ]),
          {
            htmlId: 'sidebar-subscriptions',
            label: Constants.SUBSCRIPTION_PAGE.label,
            path: Constants.SUBSCRIPTION_PAGE.path,
            canAccess:
              routeAccess.allowPaymentsPage &&
              routeAccess.allowSubscriptionPage,
          },
        ],
      },
      {
        htmlId: 'sidebar-payments', // payments for clients
        label: 'Billing',
        path: Constants.INVOICES_PAGE.path,
        icon: Constants.INVOICES_PAGE.icon,
        canAccess:
          routeAccess.allowPaymentsPage && !routeAccess.allowSubscriptionPage,
        children: useNotificationsBadge('billing'),
        priority: CoreModuleSortOrder.PAYMENTS,
      },
      {
        htmlId: 'sidebar-knowledgeBase',
        label: Constants.HELPDESK_PAGE.label,
        path: Constants.HELPDESK_PAGE.path,
        icon: Constants.HELPDESK_PAGE.icon,
        canAccess: routeAccess.allowKnowledgeBasePage,
        priority: CoreModuleSortOrder.KNOWLEDGE_BASE,
        selected: pathname.includes(Constants.HELPDESK_PAGE.path),
      },
    ],
    [routeAccess],
  );

  const getPortalNavItems = () => [
    {
      htmlId: 'sidebar-modules-settings',
      label: APP_SETUP_PAGE.label,
      path: APP_SETUP_PAGE.path,
      canAccess: routeAccess.allowSettingsModuleManagement,
      icon: APP_SETUP_PAGE.icon,
      selected: pathname.includes(APP_SETUP_PAGE.path),
    },
    {
      htmlId: 'sidebar-portal-customization',
      label: CUSTOMIZATION_PAGE.label,
      path: '/customization',
      icon: PORTAL_ITEM.icon,
      canAccess: routeAccess.allowSettingsCustomizationPage,
    },
  ];

  /**
   * This function determines portal section nav items
   * from modules settings.
   * @returns
   */
  const getCoreModulesFromModuleSettings = (): NavItem[] => {
    // when module settings is not defined return the default portal sections
    // navigation items.
    const modules = moduleSettings || [];
    // New portals do not have module settings or extensions.
    if (!modules.length && !extensionsSettings) {
      return defaultPortalSectionNavItems.filter((item) => item?.canAccess);
    }

    // When a new extension is created, it is added to the extensionsSettings and thus to be taken account of in sidebar

    let allNavItems = defaultPortalSectionNavItems;
    if (isClient) {
      allNavItems = allNavItems.concat(extensionNavItems); // Only concat for client. For admin, display as subItems
    }

    const hasOnlyExtensionsModules = modules.every((ms) =>
      ['local', 'global'].includes(ms.type),
    );
    // if moduleSettings has only extensions append extensions
    // below the default modules.
    if (hasOnlyExtensionsModules) {
      const moduleItems = allNavItems.filter((navItem) => {
        if (!navItem?.canAccess) return false;

        const itemId = navItem.htmlId.replace(appNavItemIdPrefix, '');
        const moduleSetting = modules.find((setting) => setting.id === itemId);

        // if itemId is not found in module settings, then it should be included in sidebar
        if (!moduleSetting) return true;

        if (!moduleSetting.disabled) {
          if (isClient) {
            return !extensionsSettings?.[moduleSetting.id]?.appId;
          }
          return true;
        }

        return false;
      });

      return isClient ? moduleItems : [...moduleItems, extensionNavItem];
    }

    const clientNavItems = modules
      .map((module): NavItem | null => {
        if (module.disabled) {
          return null;
        }

        const moduleNavItem = allNavItems.find((item) => {
          const itemId = item.htmlId.includes(appNavItemIdPrefix)
            ? item.htmlId.replace(appNavItemIdPrefix, '')
            : item.htmlId.replace(moduleNavItemIdPrefix, '');
          return itemId === module.id && item.canAccess;
        });

        if (moduleNavItem) {
          const navItemIconName =
            module.icon ||
            getModuleIcon(module.type, module.id, extensionsSettings);
          const navItemLabel =
            module.label ||
            getModuleName(module.type, module.id, extensionsSettings);

          return {
            ...moduleNavItem,
            icon: IconNameToComponent[navItemIconName as IconType],
            label: navItemLabel || '',
          };
        }
        return null;
      })
      .filter(Boolean);

    // include crm and marketing site nav items, they will not be shown
    // for users without permissions based on `canAccess` property.
    const items = [...clientNavItems, extensionNavItem];

    if (!isClient) {
      // For internal admin users, core modules would always remain in same sort order.
      items.sort((a, b) => {
        const aPriority = a.priority || 0;
        const bPriority = b.priority || 0;
        return aPriority - bPriority;
      });
    }
    return items as NavItem[];
  };

  const homeNavItems = React.useMemo(
    () => [
      homeNavItem,
      crmNavItem,
      ...(EnableFilesNotificationCenter ? [inboxNavItem] : []),
    ],
    [EnableFilesNotificationCenter, routeAccess],
  );

  const coreModulesNavItems: Array<NavItem> = React.useMemo(
    () => getCoreModulesFromModuleSettings(),
    [
      moduleSettings,
      extensionNavItem,
      isClient,
      extensionNavItems,
      extensionsSettings,
      planStatus,
    ],
  );

  const portalNavItems = React.useMemo(
    () => getPortalNavItems(),
    [routeAccess, isClient],
  );

  const mainSidebarSections: Array<NavSection> = React.useMemo(
    () => [
      {
        htmlId: 'section-core',
        title: '',
        navItems: homeNavItems,
        canAccess: homeNavItems.some((item) => item.canAccess),
      },
      {
        htmlId: 'section-modules',
        title: 'Apps',
        navItems: coreModulesNavItems,
        canAccess: coreModulesNavItems.some((item) => item.canAccess),
      },
      {
        htmlId: 'section-portal',
        title: 'Preferences',
        navItems: portalNavItems,
        canAccess: portalNavItems.some((x) => x.canAccess),
      },
    ],
    [homeNavItems, coreModulesNavItems, portalNavItems],
  );

  const workspaceSectionNavItems: Array<NavItem> = [
    {
      htmlId: 'main-setting-page',
      label: Constants.GENERAL_SETTINGS_PAGE.label,
      path: Constants.GENERAL_SETTINGS_PAGE.path,
      icon: Constants.GENERAL_SETTINGS_PAGE.icon,
      canAccess: routeAccess.allowSettingsMainPage,
      selected: pathname === SETTINGS_PAGE_ROOT_PATH,
    },
    {
      htmlId: 'domain-setting-page',
      label: DOMAIN_PAGE.label,
      path: DOMAIN_PAGE.path,
      icon: DOMAIN_PAGE.icon,
      canAccess: routeAccess.allowSettingsDomainPage,
      children: (
        <UpgradeBadge
          show={planStatus.isStarterPlan || planStatus.requireProUpgrade}
        />
      ),
    },
    {
      htmlId: 'members-setting-page',
      label: Constants.SETTINGS_TEAM_PAGE.label,
      path: Constants.SETTINGS_TEAM_PAGE.path,
      icon: Constants.SETTINGS_TEAM_PAGE.icon,
      canAccess: routeAccess.allowSettingsMembersPage,
      children: (
        <UpgradeBadge
          show={
            hasModifyInternalRolePermission && planStatus.requireAdvancedUpgrade
          }
        />
      ),
    },

    {
      htmlId: 'payments-setting-page',
      label: PAYMENTS_PAGE.label,
      path: PAYMENTS_PAGE.path,
      icon: PAYMENTS_PAGE.icon,
      canAccess:
        routeAccess.allowSettingsModulePayments &&
        Boolean(
          coreModulesNavItems.find(
            (item) => item.path === Constants.INVOICES_PAGE.path,
          ),
        ),
    },
    {
      htmlId: 'api-settings-page',
      label: API_SETTINGS_PAGE.label,
      path: API_SETTINGS_PAGE.path,
      icon: API_SETTINGS_PAGE.icon,
      canAccess: routeAccess.allowSettingsApiPage,
      children: (
        <UpgradeBadge
          show={planStatus.isStarterPlan || planStatus.isTrialing}
        />
      ),
      selected: pathname.includes(API_SETTINGS_PAGE.path),
    },
    {
      htmlId: 'billing-setting-page',
      label: BILLING_PAGE.label,
      path: BILLING_PAGE.path,
      icon: BILLING_PAGE.icon,
      canAccess: routeAccess.allowSettingsBillingPage,
    },

    {
      htmlId: 'plan-setting-page',
      label: PLAN_PAGE.label,
      path: PLAN_PAGE.path,
      icon: PLAN_PAGE.icon,
      canAccess: routeAccess.allowSettingsPlanPage,
      selected: pathname.includes(PLAN_PAGE.path),
    },
  ];

  const inAccessibleNavItemsForClient: Array<NavItem['label']> = [
    Constants.GENERAL_SETTINGS_PAGE.label,
    Constants.SETTINGS_TEAM_PAGE.label,
  ];

  const clientWorkspaceSectionNavItems = shouldFilterNonClientItems
    ? workspaceSectionNavItems.filter(
        (x) => !inAccessibleNavItemsForClient.includes(x.label),
      )
    : workspaceSectionNavItems;

  const settingsSidebarSections: Array<NavSection> = [
    {
      htmlId: 'section-about-settings',
      title: 'Me',
      navItems: meSectionNavItems,
      canAccess: meSectionNavItems.some((item) => item.canAccess),
    },
    {
      htmlId: 'section-workspace-settings',
      title: 'Workspace',
      navItems: clientWorkspaceSectionNavItems,
      canAccess: clientWorkspaceSectionNavItems.some((item) => item.canAccess),
    },
  ];

  return sidebarType === 'settings'
    ? settingsSidebarSections
    : mainSidebarSections;
};
