import { API } from 'src/utils/AmplifyApiUtils';
import {
  BillingSettings,
  CrmSettings,
  CrmTableSettings,
  EmbedlyData,
  ExtensionsSettings,
  MessageSettings,
  PortalSettings,
  ModuleSettingsItem,
  FileSettings,
  WebhookConfigSet,
  ContractSettings,
} from 'src/constants';

export interface WebhookForm {
  destinationURL: string;
  label: string;
  description: string;
  authKey: string;
  events: string[];
}

export interface UpdateWebhookConfigSetInput {
  entity: WebhookConfigSet;
  events: string[];
}

export class SettingsClient {
  static createMessageSettings(messageSettings: MessageSettings) {
    return API.post('AppAPI', '/entities/MESSAGE_SETTINGS', {
      body: { data: [messageSettings] },
    });
  }

  static updateMessageSettings(messageSettings: MessageSettings) {
    return API.put('AppAPI', '/entities/MESSAGE_SETTINGS', {
      body: { data: [messageSettings] },
    });
  }

  static updateCrmSettings(data: CrmSettings) {
    return API.post('AppAPI', '/entities/CRM_SETTINGS', {
      body: data,
    });
  }

  static updateModuleSettings(data: ModuleSettingsItem[]) {
    return API.post('AppAPI', '/entities/module-settings', {
      body: data,
    });
  }

  static updateFileSettings(data: FileSettings) {
    return API.post('AppAPI', '/entities/file-settings', {
      body: data,
    });
  }

  static updateContractSettings(data: ContractSettings) {
    return API.post('AppAPI', '/entities/contract-settings', {
      body: data,
    });
  }

  static updateCrmTableSettings(data: CrmTableSettings) {
    return API.post('AppAPI', '/entities/crm-table-settings', {
      body: data,
    });
  }

  static updateBillingSettings(data: BillingSettings) {
    return API.post('AppAPI', '/entities/billing-settings', {
      body: data,
    });
  }

  static updateExtensionSettings(data: ExtensionsSettings) {
    return API.post('AppAPI', '/entities/extension-settings', {
      body: data,
    });
  }

  static addWebhookConfigSet(data: WebhookForm): Promise<WebhookConfigSet> {
    return API.post('AppAPI', '/entities/webhook-config', {
      body: data,
    });
  }

  static getWebhookConfigSets(): Promise<WebhookConfigSet[]> {
    return API.get('AppAPI', '/entities/webhook-config', {});
  }

  static deleteWebhookConfigSet(configID: string): Promise<string> {
    return API.del('AppAPI', '/entities/webhook-config', {
      queryStringParameters: { configID },
    });
  }

  static updateWebhookConfigSet(
    input: UpdateWebhookConfigSetInput,
  ): Promise<WebhookConfigSet> {
    return API.put('AppAPI', '/entities/webhook-config', {
      body: input,
    });
  }

  static disconnectApp(appName: string): Promise<PortalSettings> {
    return API.put('AppAPI', '/settings/apps/disconnect', {
      body: { appName },
    });
  }

  static validateExtension(url: string): Promise<EmbedlyData> {
    return API.get('AppAPI', '/settings/extension/validate', {
      queryStringParameters: { url },
    });
  }
}
